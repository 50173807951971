import React, { useMemo, useState, useEffect } from 'react';
import FlexView from 'react-flexview';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { inject } from 'mobx-react';
import Ionicon from 'react-ionicons';
import InfinityMenu from 'react-infinity-menu';
import { withAuth0 } from '@auth0/auth0-react';

import routes from './Main.routes';
import firebase from 'services/firebase';
import msGraphService from 'services/msGraph';
import { PATH } from 'utils/constants';
import Loader from 'global/components/Loader';
import YourAccountInfoScreen from './YourAccount/YourAccountInfo';
import styles from './Main.module.css';
import './Main.css';

function Node(props) {
  const { data, name, onClick } = props;
  return (
    <div className={styles.node} key={data.id} onClick={onClick}>
      <FlexView grow style={{ paddingLeft: 20, fontSize: 12, fontWeight: 'bold' }}>
        {name}
      </FlexView>

      <Ionicon
        style={{ margin: '0 20px' }}
        icon={data.isOpen ? 'ios-arrow-up' : 'ios-arrow-down'}
        fontSize="20px"
        color="#80868B"
      />
    </div>
  );
}

function Leaf({ data, onClick, name, icon }) {
  return (
    <div
      className={`${styles.leaf} ${data.isOpen && styles.isOpen}`}
      key={data.id}
      onClick={onClick}
    >
      <Ionicon style={{ marginRight: 15 }} icon={icon} fontSize="16px" color="white" />
      <span className={styles.text}>{name}</span>
    </div>
  );
}

const CreateOrganization = ({ data, onClick, name, isOpen }) => (
  <FlexView
    vAlignContent="center"
    height={40}
    key={data.id}
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <FlexView grow style={{ paddingLeft: 10, fontSize: 18, color: '#2A79FF' }}>
      {name}
    </FlexView>
    <Ionicon style={{ marginRight: 8 }} icon="md-add" fontSize="20px" color="#2A79FF" />
  </FlexView>
);

function getSelectedLeafPath(pathname) {
  const pathSegments = pathname.split('/');

  if (pathSegments.length < 3) {
    return 'YourAccount';
  }

  return pathSegments[2];
}

function getLeaf(id, name, icon, path, isOpen) {
  return {
    id,
    name,
    icon,
    path,
    isOpen,
    customComponent: 'Leaf',
  };
}

function getOrganizationTreePart(organization, subPath) {
  const passedRoutes = [
    'OrganizationSettings',
    'EditOrganizationSettings',
    'OrganizationOffices',
    'OrganizationEmployees',
  ];
  return {
    name: organization.name,
    id: 3,
    imageUrl: organization.imageUrl,
    isHighlighted: passedRoutes.includes(subPath),
    isOpen: true,
    customComponent: 'Node',
    path: 'OrganizationSettings',
    children: [
      getLeaf(
        4,
        'Employees',
        'ios-briefcase-outline',
        'OrganizationEmployees',
        subPath === 'OrganizationEmployees'
      ),
      getLeaf(
        5,
        'Cards',
        'ios-albums-outline',
        PATH.ORGANIZATION_CARDS,
        subPath === PATH.ORGANIZATION_CARDS || subPath === PATH.ORG_CARD_EDITOR
      ),
      getLeaf(
        6,
        'All Contacts',
        'ios-book-outline',
        'OrganizationContacts',
        subPath === 'OrganizationContacts'
      ),
      getLeaf(
        7,
        'Offices',
        'ios-home-outline',
        'OrganizationOffices',
        subPath === 'OrganizationOffices'
      ),
      getLeaf(
        8,
        'Settings',
        'md-settings',
        'OrganizationSettings',
        subPath === 'OrganizationSettings'
      ),
    ],
  };
}

function promisifyData(prop) {
  return new Promise((resolve, reject) => {
    prop.subscribe(data => {
      resolve(data);
    });
  });
}

const MainScreen = inject(
  'accountStore',
  'yourContactStore',
  'organizationStore'
)(props => {
  const subPath = getSelectedLeafPath(props.location.pathname);

  const [contact, setContact] = useState(null);
  const [isAccountHasName, setIsAccountHasName] = useState(false);
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFullSized, setIsFullSized] = useState(true);

  const isSelfOwner = contact?.hcInfo.ownerId === contact?.id;

  const tree = useMemo(() => {
    const organizationSection = organization && getOrganizationTreePart(organization, subPath);
    const passedRoutes = ['YourAccount', 'YourContacts'];

    const accountSection = {
      name: contact?.fullName || '' /* require */,
      id: 0 /* require */,
      isHighlighted: passedRoutes.includes(subPath),
      isOpen: true /* require */,
      customComponent: 'Node',
      children: [
        getLeaf(1, 'You', 'md-contact', 'YourAccount', subPath === 'YourAccount'),
        getLeaf(2, 'Your Contacts', 'md-contacts', 'YourContacts', subPath === 'YourContacts'),
      ],
    };

    return organizationSection ? [accountSection, organizationSection] : [accountSection];
  }, [contact, subPath, organization]);

  const getIsAccountHasName = contactInfo =>
    Boolean(contactInfo?.firstName) || Boolean(contactInfo?.lastName);

  const loadData = async () => {
    try {
      const contactInfo = await promisifyData(props.yourContactStore);
      setContact(contactInfo);
      setIsAccountHasName(getIsAccountHasName(contactInfo));

      if (contactInfo.organizationId && contactInfo?.hcInfo.role === 'admin') {
        const organizationItem = await promisifyData(props.organizationStore);
        setOrganization(organizationItem);
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    props.accountStore.setCurrentUser(firebase.getUserId());

    loadData();

    props.organizationStore.onOrganizationDelete(() => {
      setOrganization(null);
    });

    return () => {
      props.yourContactStore.done();
    };
  }, []);

  const signOut = async () => {
    try {
      msGraphService.signOut();
      await props.accountStore.logout(props.auth0);
      props.history.push('/entry');
    } catch (e) {
      console.log(e.message);
    }
  };

  const navigateTo = (nodeId, leafId) => {
    const item = tree[nodeId].children.find(child => child.id === leafId);
    if (item) {
      props.history.push(`/main/${item.path}`);
    }
  };

  const onNodeMouseClick = async (event, tree, node, level, keyPath) => {
    if (!isAccountHasName && props.history.location.pathname === '/main/YourAccount/edit') {
      setIsShowMessage(true);
      return;
    }

    if (node.path) {
      props.history.push(`/main/${node.path}`);
      return;
    }

    navigateTo(parseInt(node.keyPath), 1);
  };

  const onLeafMouseClick = (event, leaf) => {
    if (!isAccountHasName && props.history.location.pathname === '/main/YourAccount/edit') {
      setIsShowMessage(true);
      return;
    }
    navigateTo(parseInt(leaf.keyPath.charAt(0)), leaf.id);
  };

  const onCreateOrganizationClick = async () => {
    if (!isAccountHasName && props.history.location.pathname === '/main/YourAccount/edit') {
      setIsShowMessage(true);
      return;
    }
    const newOrg = props.organizationStore.createOrganization();
    if (newOrg) {
      setOrganization(newOrg);
      await firebase.saveContact({ ...contact.hcInfo, organizationId: newOrg.id, role: 'admin' });
    }
    props.history.push('/main/EditOrganizationSettings');
  };

  return (
    <FlexView grow style={{ height: '100%', width: '100%' }}>
      <FlexView
        className={`${styles.sidenav} ${isFullSized ? styles.fullSized : styles.smallSized}`}
        height="100%"
        basis={isFullSized ? 220 : 60}
        column
        style={{ justifyContent: 'space-between', transition: 'flex-basis 0.2s' }}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <FlexView style={{ padding: 20 }}>
            <img src="/cardivore_icon _white.png" alt="logo" />
          </FlexView>

          <InfinityMenu
            className="infinity-menu"
            style={{ backgroundColor: 'rgba(6,16,33)' }}
            disableDefaultHeaderContent={true}
            tree={tree}
            customComponentMappings={{ Node, Leaf, CreateOrganization }}
            onNodeMouseClick={onNodeMouseClick}
            onLeafMouseClick={onLeafMouseClick} /* optional */
            maxLeaves={5} /* optional */
          />
        </div>

        <FlexView
          column
          style={{
            backgroundColor: 'rgba(6,16,33)',
            cursor: 'pointer',
            minHeight: 'auto',
            marginBottom: '10px',
          }}
        >
          {organization || (!isSelfOwner && contact?.hcInfo.role === 'basic') ? null : (
            <div
              className={`${styles.leaf} ${styles.footerLeaf}`}
              onClick={onCreateOrganizationClick}
            >
              <Ionicon style={{ marginRight: 15 }} icon="md-add" fontSize="20px" color="#2A79FF" />
              <span className={styles.text} style={{ color: '#2A79FF' }}>
                New Organization
              </span>
            </div>
          )}

          {/* <div className={`${styles.leaf} ${styles.footerLeaf}`}>
            <Ionicon
              style={{ marginRight: 15 }}
              icon="ios-help-circle-outline"
              fontSize="20px"
              color="#80868B"
            />
            <span className={styles.text}>Help</span>
          </div> */}

          <a href="/logout">
            <div className={`${styles.leaf} ${styles.footerLeaf}`} onClick={signOut}>
              <Ionicon
                style={{ marginRight: 15 }}
                icon="ios-log-out"
                fontSize="20px"
                color="#80868B"
              />
              <span className={styles.text}>Log out</span>
            </div>
          </a>

          <div
            className={`${styles.leaf} ${styles.footerLeaf}`}
            onClick={() => setIsFullSized(!isFullSized)}
          >
            <Ionicon
              style={{ marginRight: 15 }}
              icon={isFullSized ? 'ios-arrow-dropleft' : 'ios-arrow-dropright'}
              fontSize="20px"
              color="#80868B"
            />
          </div>
        </FlexView>
      </FlexView>

      {isLoading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : (
        <Switch>
          <Redirect exact from={props.match.path} to={`${props.match.path}/YourAccount`} />
          {routes.map(({ path, component }, index) => {
            if (path === 'YourAccount') {
              return (
                <Route
                  key={index}
                  path={`${props.match.path}/${path}`}
                  render={props => (
                    <YourAccountInfoScreen {...props} isShowMessage={isShowMessage} />
                  )}
                />
              );
            }
            return <Route key={index} path={`${props.match.path}/${path}`} component={component} />;
          })}
        </Switch>
      )}
    </FlexView>
  );
});

export default withAuth0(withRouter(MainScreen));
