import React, { Component } from 'react';
import FlexView from 'react-flexview';

class YourCardScreen extends Component {
  render() {
    return (
      <FlexView style={{ height: '100vh', width: '100vh' }}>
        <header>
          <p>Your Card</p>
        </header>
      </FlexView>
    );
  }
}

export default YourCardScreen;
