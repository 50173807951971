import React, { useState, useCallback } from 'react';

import Button from '../../Screens/Common/Button';
import Loader from 'global/components/Loader';
import { ORGANIZATION_HUBSPOT_ATTRIBUTES } from 'Models/Organizations.config';
import { getHubspotConfigOptions } from '../../utils/helpers';
import { connectHubspot } from 'services/hubspot';

const getValue = (attributeId, orgData) =>
  getHubspotConfigOptions(attributeId).find(({ id }) => orgData[attributeId] === id)?.name || '-';

const isAuthorizedToHubspot = ({ hubSpot }) => !!hubSpot;

const OrgHubspotViewSettings = ({ orgData }) => {
  const [isAuthorized, setIsAuthorized] = useState(isAuthorizedToHubspot(orgData));
  const [isLoading, setIsLoading] = useState(false);

  const onLoginBtnClick = useCallback(async () => {
    try {
      setIsLoading(true);
      await connectHubspot(orgData.id);
      setIsAuthorized(true);
    } catch (error) {
      alert(error.toString());
    } finally {
      setIsLoading(false);
    }
  }, [orgData]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          type="button"
          style={{ margin: '5px 8px 5px 23px' }}
          caption={isAuthorized ? 'Logged in to HubSpot' : 'Login to HubSpot'}
          title={isAuthorized ? 'Logged in to HubSpot' : 'Login to HubSpot'}
          disabled={isAuthorized}
          onClick={onLoginBtnClick}
        />
        {isLoading && <Loader className="smallGrey" />}
      </div>
      <div className="attributes">
        {ORGANIZATION_HUBSPOT_ATTRIBUTES.map(attribute => (
          <div key={attribute.id} className={`attribute attribute-${attribute.id}`}>
            <div className="value">{getValue(attribute.id, orgData)}</div>
            <div className="type">{attribute.name}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default OrgHubspotViewSettings;
