import React, { useState, useMemo, useEffect, useCallback, memo } from 'react';
import { inject } from 'mobx-react';
import { Snackbar } from 'react-md';

import { useInfiniteFirebaseQuery } from 'global/hooks';
import Loader from 'global/components/Loader';
import { CardType } from '../../../Models/Cards';
import OrgCardDetail from './OrgCardDetail';
import Button from '../../Common/Button';
import OrgCardItem from './OrgCardItem';
import styles from './OrgCards.module.css';
import firebaseService from '../../../services/firebase';

const OrgCards = inject('organizationStore')(({ organizationStore, history }) => {
  const [toasts, setToasts] = useState([]);
  const [cardMode, setCardMode] = useState(null);
  const [activeCard, setActiveCard] = useState(null);
  const { data: cards, refetch, isFetching, error: cardsError } = useInfiniteFirebaseQuery(
    'cards',
    {
      adapter: card => new CardType().fromFirestoreDoc(card),
      queryAdapter: query => query.where('organizationId', '==', organizationStore.organization.id),
    }
  );
  const cardsList = useMemo(() => cards.sort((a, b) => a.name.localeCompare(b.name)), [cards]);

  useEffect(() => {
    if (cardsError) {
      history.push('/main');
    }
  }, []);

  useEffect(() => {
    if (history.location?.hash && cards.length) {
      const card = cards.find(({ id }) => id === history.location.hash.slice(1));

      if (card) {
        setActiveCard(card);
      }
    }
  }, [history.location?.hash, cards?.length]);

  const handleAddToastText = useCallback(
    message => setToasts(prevToasts => [{ ...prevToasts, text: message }]),
    []
  );

  const handleToastsDismiss = useCallback(() => {
    const [, ...remain] = toasts;
    setToasts(remain);
  }, [toasts]);

  const handleAddNewCard = useCallback(() => {
    setCardMode('create');
    setActiveCard(CardType.getCardTemplate());
  }, []);

  const handleCardClick = useCallback(card => {
    setActiveCard(card);
    history.push({ hash: card.id });
  }, []);

  const handleCardDelete = useCallback(async () => {
    try {
      const card = new CardType();
      card.saveCard(organizationStore.organization.id, activeCard);

      if (organizationStore.organization.defaultCardId === activeCard.id) {
        await firebaseService.updateOrganization(organizationStore.organization.id, {
          defaultCardId: null,
        });
      }

      await card.delete();
      refetch();
      setActiveCard(null);
    } catch (e) {
      console.error(e);
    }
  }, [organizationStore, activeCard]);

  const handleCardSave = useCallback(
    async values => {
      const card = new CardType();
      card.saveCard(organizationStore.organization.id, {
        ...activeCard,
        ...values,
        id: activeCard?.id || null,
      });
      await card.save();
      if (
        organizationStore.organization.defaultCardId &&
        organizationStore.organization.defaultCardId !== values.id
      ) {
        // disable previous selected default card
        await firebaseService.updateCard(organizationStore.organization.defaultCardId, {
          isDefaultCard: 'false',
        });
      }
      if (values.isDefaultCard === 'true') {
        await firebaseService.updateOrganization(organizationStore.organization.id, {
          defaultCardId: activeCard?.id || null,
        });
      }
      if (
        organizationStore.organization.defaultCardId === values.id &&
        values.isDefaultCard === 'false'
      ) {
        await firebaseService.updateOrganization(organizationStore.organization.id, {
          defaultCardId: null,
        });
      }
      await refetch(card.data.id);
      setActiveCard(card.data);
      setCardMode(null);
      history.push({ hash: card.data.id });
    },
    [organizationStore, activeCard]
  );

  const handleCloseCardDetails = useCallback(() => {
    setActiveCard(null);
    setCardMode(null);
  }, []);

  return (
    <div className={styles.root}>
      <div style={{ width: activeCard ? '60%' : '100%' }} className={styles.mainWrapper}>
        <header className={styles.header}>
          <h2 className={styles.headerTitle}>Organization cards</h2>
          <Button icon="md-add" caption="Add" onClick={handleAddNewCard} />
        </header>

        {cardsList.length > 0 && (
          <div className={styles.cardsContainer}>
            {cardsList.map(card => {
              return (
                <OrgCardItem
                  key={card.id}
                  card={card}
                  activeCard={activeCard}
                  onCardClick={handleCardClick}
                />
              );
            })}
          </div>
        )}

        {!cardsList.length && (
          <div className={styles.noCardsContainer}>
            <p>You don&rsquo;t have cards here yet. Let&rsquo;s add some</p>
            <Button icon="md-add" caption="Add new card" onClick={handleAddNewCard} />
          </div>
        )}
      </div>

      {(activeCard || cardMode === 'create') && (
        <aside className={styles.detailsAside}>
          <OrgCardDetail
            activeTab={cardMode === 'create' ? 'name' : null}
            selectedCard={activeCard}
            onClose={handleCloseCardDetails}
            onDeleteCard={handleCardDelete}
            onSaveCard={handleCardSave}
            onAddToastText={handleAddToastText}
          />
        </aside>
      )}

      {isFetching && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}

      <div className={styles.snackBar}>
        <Snackbar
          style={{ padding: 20, color: 'white', backgroundColor: 'rgb(6, 16, 33)', fontSize: 16 }}
          toasts={toasts}
          autoFocusAction
          autohide={true}
          onDismiss={handleToastsDismiss}
        />
      </div>
    </div>
  );
});

export default memo(OrgCards);
