import React, { useMemo } from 'react';

import Card from 'global/components/Card';
import { CARD_SIDE, CARD_CONTAINER_SIZE_LABELS } from 'utils/constants';
import styles from './TableCellFullName.module.css';

const CARD_CONTAINER = {
  [CARD_CONTAINER_SIZE_LABELS.DEFAULT]: { width: 50, height: 30 },
  [CARD_CONTAINER_SIZE_LABELS.NON_STANDARD]: { width: 30, height: 30 },
};

const TableCellOfficeCard = ({ cell: { value }, cardsList }) => {
  const activeCardVersion = useMemo(() => {
    const card = value ? cardsList.find(({ id }) => id === value) : null;

    return card?.versions?.find(({ id }) => id === card?.activeVersionId);
  }, [value, cardsList]);

  return (
    <div className={styles.root}>
      <Card
        card={activeCardVersion}
        side={CARD_SIDE.FRONT}
        url={activeCardVersion?.frontUrl}
        containerSize={CARD_CONTAINER}
        className={styles.card}
        placeholder="No Card"
      />
    </div>
  );
};

export default TableCellOfficeCard;
