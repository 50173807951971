import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import FlexView from 'react-flexview';
import { inject } from 'mobx-react';
import { useInfiniteFirebaseQuery, useSharedContactUpdate } from 'global/hooks';
import { Snackbar } from 'react-md';

import { BAR_MODE } from '../../utils/constants';
import { isConfirmLooseChanges, processAlreadyCreatedContacts } from '../../utils/helpers';
import { Table, TableCellFullName } from '../../global/components/Table';
import DebounceInput from '../../global/components/DebounceInput';
import Button from '../Common/Button';
import ImportCSVScreen from '../Common/ImportCSV';
import ContactDetail from '../YourAccount/ContactDetail';

import { ContactType } from '../../Models/Contacts';
import { CardType } from '../../Models/Cards';
import OfficeType from 'Models/OfficeType';
import ContactStore from 'Models/Contact.store';
import firebaseService from 'services/firebase';

import { parseContactsCsv } from 'Models/Contacts.utils';

import '../Common/table.css';
import styles from '../Common/TablePage.module.css';

const convertOrderBy = orderBy => {
  switch (orderBy.field) {
    case 'hcInfo':
      return [
        { field: 'firstName', direction: orderBy.direction },
        { field: 'lastName', direction: orderBy.direction },
      ];
    default:
      return [orderBy];
  }
};

const OrgContactsScreen = inject(
  'organizationStore',
  'accountStore'
)(props => {
  const [toasts, setToasts] = useState([]);
  const [search, setSearch] = useState('');

  const [orderBy, setOrderBy] = useState({ field: 'hcInfo', direction: 'asc' });
  const [showImportContacts, setShowImportContacts] = useState(false);
  const [selectedContact, setContact] = useState(null);
  const [isImportSuccess, setIsImportSuccess] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  // There are three 'modes' in the barMode: edit, view, new
  const [barMode, setBarMode] = useState(null);

  const {
    data: contactsData,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    refetch,
    error: contactsDataError,
  } = useInfiniteFirebaseQuery('contacts', {
    adapter: contact => new ContactType().fromFirestoreDoc(contact),
    queryAdapter: query =>
      query.where('sharedWithOrganization', '==', props.organizationStore.organization.id),
    orderBy: convertOrderBy(orderBy),
    search,
    isAdditionalFields: true,
  });

  const { data: officesList, error: officesListError } = useInfiniteFirebaseQuery('offices', {
    queryAdapter: query =>
      query.where('organizationId', '==', props.organizationStore.organization.id),
    adapter: doc => new OfficeType().fromFirestoreDoc(doc),
  });
  const { data: cardsList, error: cardsListError } = useInfiniteFirebaseQuery('cards', {
    adapter: card => new CardType().fromFirestoreDoc(card),
    queryAdapter: query =>
      query.where('organizationId', '==', props.organizationStore.organization.id),
  });

  const officesObj = useMemo(() => {
    return (
      officesList.reduce((acc, office) => {
        return { ...acc, [office.data.id]: office.data };
      }, {}) || {}
    );
  }, [officesList]);

  const isEditing = useMemo(() => barMode === BAR_MODE.NEW || barMode === BAR_MODE.EDIT, [barMode]);

  const getContact = useCallback(async id => {
    if (id) {
      const employee = await firebaseService.getContact(id);

      return new ContactType().fromFirestoreDoc(employee);
    }
  }, []);

  const debounceInputRef = useRef();

  useSharedContactUpdate(selectedContact);

  useEffect(() => {
    if (contactsDataError || officesListError || cardsListError) {
      props.history.push('/main');
    }
  }, []);

  useEffect(() => {
    debounceInputRef.current?.focus();
  }, [debounceInputRef]);

  useEffect(() => {
    getContact(selectedContact?.id)
      .then(contact => {
        setContact(contact);
      })
      .catch(error => {
        alert(error.toString());
      });
  }, [contactsData]);

  const columns = [
    {
      Header: 'Name',
      accessor: 'hcInfo',
      Cell: cellProps =>
        TableCellFullName({
          ...cellProps,
          cardsList,
          officesData: officesObj,
          organization: props.organizationStore.organization,
        }),
    },
    { Header: 'Title', accessor: 'jobTitle' },
    { Header: 'Contact Owner', accessor: 'ownerName' },
    { Header: 'Contact Collector', accessor: 'collectorName' },
  ];

  const dismissToast = () => {
    const [, ...remain] = toasts;
    setToasts(remain);
  };

  const setToast = message => {
    setToasts([...toasts, { text: message }]);
  };

  const checkIfLooseChanges = () =>
    isConfirmLooseChanges({ isDirty, isEditing, onApprove: () => setIsDirty(false) });

  const clickImport = () => {
    if (checkIfLooseChanges()) {
      return;
    }
    setShowImportContacts(true);
  };

  const clickAdd = event => {
    if (checkIfLooseChanges()) {
      return;
    }

    const contact = new ContactType();
    contact.createManualContact(props.accountStore.id);

    setContact(contact);
    setBarMode(BAR_MODE.NEW);
  };

  const onSaveHandler = async () => {
    setToast('Contact was saved');

    if (barMode === 'edit') {
      // refetch only "selectedContact" if it is "Editing Mode"
      await refetch(selectedContact);
    } else {
      // Otherwise it is a new contact
      // refetch all
      await refetch();
    }

    setIsDirty(false);
    setBarMode(BAR_MODE.VIEW);
  };

  const onRowClick = (index, { id }) => {
    if (checkIfLooseChanges()) {
      return;
    }

    setContact(contactsData[index]);
    setIsDirty(false);
    setBarMode(BAR_MODE.VIEW);
  };

  const onEditClick = id => {
    if (checkIfLooseChanges()) {
      return;
    }

    getContact(id)
      .then(contact => {
        setContact(contact);
        setBarMode(BAR_MODE.EDIT);
      })
      .catch(error => {
        alert(error.toString());
      });
  };

  const onCloseHandler = () => {
    setIsDirty(false);
    setBarMode(null);
    setContact(null);
  };

  const onDeleteClick = id => {
    if (checkIfLooseChanges()) {
      return;
    }
    const contact = contactsData.find(x => x.id === id);
    const isHasOwnContacts = contactsData.some(
      item =>
        item.hcInfo.ownerId === contact.hcInfo.id || item.hcInfo.collectorId === contact.hcInfo.id
    );

    if (isHasOwnContacts) {
      setToast(
        `The ${contact.firstName} ${contact.lastName} can not be deleted. Please remove assigned contacts`
      );
      return;
    }

    if (window.confirm(`Delete ${contact.firstName} ${contact.lastName} contact?`)) {
      contact.delete().then(() => {
        setBarMode(null);
        setContact(null);
        setToast('Contact was deleted');
        refetch();
      });
    }
  };

  const onImportComplete = data => {
    if (data) {
      const { id, name } = props.organizationStore.organization;
      props.accountStore
        .importCSVContacts({
          data,
          organizationId: id,
          organizationName: name,
          existedContacts: contactsData,
          collectorId: props.accountStore.id,
        })
        .then(result => {
          setIsImportSuccess(true);
          processAlreadyCreatedContacts(
            props.organizationStore,
            props.accountStore,
            result.alreadyCreatedContacts
          ).then(isAlreadyCreatedContactsChanged => {
            const addedContactsCount = isAlreadyCreatedContactsChanged
              ? [...result.contacts, ...result.alreadyCreatedContacts].length
              : result.contacts.length;
            const alreadyExistContactsCount = !isAlreadyCreatedContactsChanged
              ? [...result.contactsDuplicate, ...result.alreadyCreatedContacts].length
              : result.contactsDuplicate.length;
            refetch();
            setToast(`${addedContactsCount} contacts created.
            ${alreadyExistContactsCount} contacts skipped.`);
          });
        })
        .catch(error => {
          alert(error.toString());
        });
    }

    setShowImportContacts(false);
  };

  const actions = ({ cell: { value } }) => {
    return [
      {
        caption: 'Edit',
        onClick: onEditClick,
        icon: 'ios-create-outline',
      },

      {
        caption: 'Delete',
        onClick: onDeleteClick,
        icon: 'ios-trash-outline',
        primary: true,
      },
    ];
  };

  const isEmpty = !isFetching && !isFetchingMore && !contactsData.length && !search;

  const NoContacts = (
    <div className={styles.noItemsContainer}>
      <p>You don&rsquo;t have contacts here yet. Let&rsquo;s add some</p>
      <Button onClick={clickAdd} icon="md-add" caption="Add an contact manually" />
    </div>
  );

  return (
    <FlexView row="true" grow style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
      <FlexView column style={{ width: selectedContact ? '55%' : '100%', height: '100%' }}>
        <FlexView
          height={60}
          row="true"
          vAlignContent="center"
          style={{ paddingRight: 20, paddingLeft: 20, fontWeight: 'bold', minHeight: 60 }}
        >
          <FlexView>Company Contacts</FlexView>
          <FlexView grow row="true" width={220} hAlignContent="right">
            <DebounceInput
              onChange={setSearch}
              value={search}
              ref={debounceInputRef}
              debounce={250}
              placeholder="Search"
              icon="ios-search"
              style={{ marginRight: 8, maxWidth: 400 }}
            />
            <Button onClick={clickAdd} icon="md-add" caption="Add" style={{ marginRight: 8 }} />
            <Button onClick={clickImport} icon="md-cloud-upload" caption="Import" />
          </FlexView>
        </FlexView>

        <FlexView column style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
          <Table
            columns={columns}
            onRowClick={onRowClick}
            data={contactsData}
            actions={actions}
            onFetchMore={fetchMore}
            canFetchMore={canFetchMore}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            orderBy={orderBy}
            onOrderByClick={setOrderBy}
            highlight={search}
            isSidebarOpen={barMode}
            isImportSuccess={isImportSuccess}
            resetImportSuccess={() => setIsImportSuccess(false)}
            isEmpty={isEmpty}
            emptyMessage={NoContacts}
            activeId={selectedContact?.id}
            columnsToUpdateDependency={{ isDirty, barMode, officesObj }}
          />
        </FlexView>
      </FlexView>

      {selectedContact && (
        <div style={{ width: '45%' }} className={styles.detailsPanel}>
          <ContactDetail
            titles={{ new: 'New contact', view: 'Contact:' }}
            contactInfo={selectedContact || {}}
            store={new ContactStore(selectedContact)}
            theme="detail"
            onClose={onCloseHandler}
            organization={props.organizationStore.organization.orgData}
            isEditing={isEditing}
            onSave={isSaved => (isSaved ? onSaveHandler() : onCloseHandler())}
            onEdit={onEditClick}
            createLeadHandler={async () => {
              await refetch();
            }}
            type="orgContact"
            mode={barMode}
            onDirtyChanged={setIsDirty}
            offices={{ list: officesList, data: officesObj }}
          />
        </div>
      )}
      {showImportContacts && (
        <ImportCSVScreen
          title="Import Contacts"
          onComplete={onImportComplete}
          csvHeader="first_name, last_name, job_title, organization, website, phone_type_1, phone_1, email_type_1, email_1, physical_address_type_1, physical_address_1, social_media_type_1, social_media_1"
          note="To add more phone numbers, physical addresses, emails, websites, or social handles, just increment the numbers"
          csvParser={parseContactsCsv}
        />
      )}
      <FlexView
        grow
        hAlignContent="center"
        row="true"
        style={{ position: 'absolute', bottom: 0, left: 220, right: 0 }}
      >
        <Snackbar
          style={{ padding: 20, color: 'white', backgroundColor: 'rgb(6, 16, 33)', fontSize: 16 }}
          toasts={toasts}
          autoFocusAction
          autohide={true}
          onDismiss={dismissToast}
        />
      </FlexView>
    </FlexView>
  );
});

export default OrgContactsScreen;
