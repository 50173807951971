import React, { useState, useMemo } from 'react';
import { useLocation, Link, Route, Switch, Prompt, useParams, Redirect } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import { toJS } from 'mobx';
import Ionicon from 'react-ionicons';
import { MuiThemeProvider } from '@material-ui/core';

import firebaseService from 'services/firebase';

import { useInfiniteFirebaseQuery } from 'global/hooks';
import { List } from 'global/components/List';
import Loader from '../../global/components/Loader';

import { getListItemsIdsByKeys, getObjectFromArrayByKey } from 'utils/helpers';

import ContactStore from 'Models/Contact.store';
import { OFFICE_ATTRIBUTES } from 'Models/Office.config';
import validationSchema from './EditOfficeSettings.validation';
import ValidableTextField from '../YourAccount/ValidableTextField';
import ViewContactDetail from '../YourAccount/ViewContactDetail';

import { Tabs, Tab } from '../Common/Tabs';
import Button from '../Common/Button';
import Checkmark from '../Common/Checkmark';
import HCTextSelector from '../Common/HCTextSelector';
import themes from '../Common/themes';
import OptionsModal from './OptionsModal';

import diskette from 'assets/icons/diskette.svg';

import classes from './ViewOfficeDetail.module.css';
import tableStyles from '../Common/TablePage.module.css';
import { ContactType } from '../../Models/Contacts';

const ViewOfficeDetail = ({
  office,
  onEdit,
  onClose,
  organizationStore,
  setToast,
  setEmployeeSidebar,
  history,
  employeesList,
  officeEmployeesList,
  refetch,
  onSave,
  onSearchEmployee,
  searchValue,
}) => {
  const [showEmployeesModal, setShowEmployeesModal] = useState(false);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [isAll, setAll] = useState(false);
  const [selectedEmployee, setEmployee] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const location = useLocation();

  const { officeId, mode, tab } = useParams();
  const basePath = `/main/OrganizationOffices/${mode}/${officeId}`;
  const employeesPath = `${basePath}/employees`;
  const adminPath = `${basePath}/admin`;

  const isEditing = mode === 'edit';
  const isCreating = mode === 'new';
  const isEmployeesTab = tab === 'employees';

  const { data: cards } = useInfiniteFirebaseQuery('cards', {
    adapter: card => ({ id: card.id, name: card.name }),
    queryAdapter: query =>
      query.where('organizationId', '==', organizationStore.organization.id || ''),
  });
  const cardsData = useMemo(() => getObjectFromArrayByKey(cards, 'id'), [cards]);

  const officeEmployeeIds = useMemo(
    () => getListItemsIdsByKeys(employeesList, ['officeId'], office.id),
    [employeesList, office.id]
  );
  const availableEmployees = employeesList.filter(el => !officeEmployeeIds.includes(el.id));
  const employeesData = useMemo(() => getObjectFromArrayByKey(employeesList, 'id'), [
    employeesList,
  ]);

  const getEmployeesFromOtherOffices = async list => {
    const nonOfficeEmployeesId = list
      .filter(id => !officeEmployeeIds.includes(id))
      .map(id => ({ id }));
    try {
      const nonOfficeEmployees = await firebaseService.withInSearch(
        nonOfficeEmployeesId,
        'contacts',
        'id'
      );

      return nonOfficeEmployees
        .filter(({ data: { officeId } }) => !!officeId)
        .map(({ data, id }) => ({
          id,
          data: new ContactType().fromFirestoreDoc(data),
        }));
    } catch (error) {
      alert(error.toString());
    }
  };

  const updateEmployees = employees =>
    Promise.all(employees.map(id => firebaseService.updateContact(id, { officeId: office.id })));

  const onSaveEmployees = async ({ employees, deletedId }) => {
    try {
      if (deletedId) {
        await firebaseService.updateContact(deletedId, { officeId: null });
        setToast('Employee was deleted successfully');
      } else if (employees.length > 0) {
        const employeesFromOtherOffices = await getEmployeesFromOtherOffices(employees);

        if (employeesFromOtherOffices.length === 0) {
          await updateEmployees(employees);
          setToast('Employees were assigned successfully');
        } else {
          const employeesNames = employeesFromOtherOffices.map(({ data }) => ` ${data?.fullName}`);
          const message = `${employeesNames} are already assigned to other offices. Would you like to remove them from those offices and put them in the "${office.name}"?`;

          if (window.confirm(message)) {
            await updateEmployees(employees);
            setToast('Employees were assigned successfully');
          } else {
            return;
          }
        }
      }
      refetch();
      onCloseEmployessModal();
      setEmployee(null);
    } catch (error) {
      console.error(error);
    }
  };

  const onClickEmployee = employeeId => {
    if (isAll) return;
    const newList = employeeIds.includes(employeeId)
      ? employeeIds.filter(id => id !== employeeId)
      : [...employeeIds, employeeId];
    setEmployeeIds(newList);
    // in case if autochecking all point will be needed
    // if (newList.length === availableEmployees.length) {
    //   setAll(true);
    // }
  };

  const handleOfficeSubmit = async values => {
    setIsSaving(true);

    Object.assign(office.data, values);
    await office.save();
    onSave();

    setIsSaving(false);
  };

  const onCloseEmployessModal = () => {
    setEmployeeIds([]);
    setAll(false);
    setShowEmployeesModal(false);
    onSearchEmployee('');
  };

  const onSaveChanged = () =>
    onSaveEmployees({ employees: [...officeEmployeeIds, ...employeeIds] });

  const employeesModal = (
    <OptionsModal
      onClose={onCloseEmployessModal}
      onSave={onSaveChanged}
      onSearch={onSearchEmployee}
      searchValue={searchValue}
      title="Add Employees"
    >
      {searchValue.length || availableEmployees.length === 0 ? null : (
        <div
          onClick={() => {
            if (isAll) {
              setEmployeeIds([]);
            } else {
              setEmployeeIds(availableEmployees.map(employee => employee.id));
            }
            setAll(!isAll);
          }}
          className={classes.employeeItem}
        >
          <Checkmark style={{ marginRight: 10 }} checked={isAll} />
          <div>All</div>
        </div>
      )}
      {availableEmployees.map(employee => (
        <div
          onClick={() => onClickEmployee(employee.id)}
          className={classes.employeeItem}
          key={employee.id}
        >
          <Checkmark
            style={{ marginRight: 10, fill: isAll ? 'grey' : 'black' }}
            checked={employeeIds.includes(employee.id)}
          />
          <div>{employee.fullName}</div>
        </div>
      ))}
    </OptionsModal>
  );

  const EmployeesButtons = () => (
    <div className={classes.addBtn}>
      <Button caption="Add" onClick={() => setShowEmployeesModal(true)} icon="md-add" />
    </div>
  );

  const AdminButtons = () => {
    const { submitForm } = useFormikContext();

    return isEditing || isCreating ? (
      isSaving ? (
        <Loader className="smallGrey" />
      ) : (
        <Button
          caption="Save"
          onClick={submitForm}
          customIcon={
            <img
              src={diskette}
              style={{ marginRight: '10px' }}
              width="12px"
              height="12px"
              alt="diskette"
            />
          }
          style={{ backgroundColor: '#2DD955', color: '#fff' }}
        />
      )
    ) : (
      <Button caption="Edit" onClick={onEdit} icon="md-create" />
    );
  };

  const headerButtons = (
    <div className="header-buttons">
      {isEmployeesTab ? <EmployeesButtons /> : <AdminButtons />}
      <Ionicon onClick={onClose} icon="ios-close" className="close-button" />
    </div>
  );

  const onDeleteEmployee = ({ id }) => {
    if (
      window.confirm(
        `Remove "${employeesData[id]?.fullName || ''}" from the office "${office.name}"?`
      )
    ) {
      setEmployeeIds(officeEmployeeIds.filter(addedId => addedId !== id));
      onSaveEmployees({
        employees: officeEmployeeIds.filter(addedId => addedId !== id),
        deletedId: id,
      });
    }
  };

  const onClickEmployeeList = ({ id }) => {
    setEmployee(employeesData[id]);
    setEmployeeSidebar(true);
  };

  const EmployeesTab = () => {
    const emptyMessage = (
      <div className={tableStyles.noItemsContainer}>
        <p style={{ fontSize: 16 }}>There are no employees here yet.</p>
      </div>
    );

    return (
      <List
        data={officeEmployeesList}
        actions={[
          {
            primary: true,
            caption: 'Remove',
            icon: 'ios-trash-outline',
            onClick: onDeleteEmployee,
          },
        ]}
        onClick={onClickEmployeeList}
        activeId={selectedEmployee?.id}
        renderItem={({ fullName }) => <p>{fullName || ''}</p>}
        emptyMessage={emptyMessage}
      />
    );
  };

  const OfficeDetailsTab = () => {
    const { submitForm, dirty, values, setFieldValue } = useFormikContext();

    if (values && (isEditing || isCreating)) {
      return (
        <form onSubmit={submitForm} className="panel details details-edit">
          <Prompt
            when={dirty}
            message={() => 'All unsaved changes will be lost. Are you sure you want to continue?'}
          />
          <div className="attributes">
            <MuiThemeProvider theme={themes.black}>
              {OFFICE_ATTRIBUTES.map((attribute, index) => (
                <div key={index} className={`attribute attribute-${attribute.id}`}>
                  {attribute.id === 'cardId' ? (
                    <HCTextSelector
                      style={{ flex: 1, marginTop: 10 }}
                      label={attribute.name}
                      options={cards}
                      value={values[attribute.id]}
                      onChange={id => setFieldValue('cardId', id)}
                      margin="none"
                      disabled={cards.length === 0}
                    />
                  ) : (
                    <ValidableTextField
                      label={attribute.name}
                      errorMessage={`Please enter valid ${attribute.name.toLowerCase()}`}
                      name={attribute.id}
                    />
                  )}
                </div>
              ))}
            </MuiThemeProvider>
          </div>
        </form>
      );
    }

    return (
      <div className="attributes">
        {OFFICE_ATTRIBUTES.map((attribute, index) => {
          const cardName =
            attribute.id === 'cardId' ? cardsData[office?.data?.[attribute.id]]?.name : '';
          const value = cardName || office?.data?.[attribute.id] || '-';
          return (
            <div key={attribute.id} className={`attribute attribute-${attribute.id}`}>
              <div className="value">{value}</div>
              <div className="type">{attribute.name}</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnChange={false}
      initialValues={toJS(office.data)}
      onSubmit={handleOfficeSubmit}
      enableReinitialize
    >
      <div style={{ display: 'flex', height: '100%' }}>
        <div className="panel details details-view">
          <div className="header">
            <p>Office: {office.name || ''}</p>
            {headerButtons}
            {showEmployeesModal && employeesModal}
          </div>
          <Tabs value={location.pathname}>
            <Tab label="Employees" component={Link} to={employeesPath} value={employeesPath} />
            <Tab label="Admin" component={Link} to={adminPath} value={adminPath} />
          </Tabs>

          <Switch>
            <Route exact path={`/main/OrganizationOffices/new/${officeId}`}>
              <Redirect to={adminPath} />
            </Route>
            <Route path={employeesPath} component={EmployeesTab} />
            <Route path={adminPath} component={OfficeDetailsTab} />
            <Redirect to={employeesPath} />
          </Switch>
        </div>

        {!selectedEmployee ? null : (
          <div style={{ width: '50%' }}>
            <ViewContactDetail
              title={`Employee ${selectedEmployee.firstName} ${selectedEmployee.lastName}`}
              contact={selectedEmployee}
              theme="detail"
              onClose={() => {
                setEmployeeSidebar(false);
                setEmployee(null);
              }}
              onSaveNotes={new ContactStore(selectedEmployee).saveNotes}
              onEditClicked={activeSectionIndex => {
                history.push('/main/OrganizationEmployees', { employeeId: selectedEmployee.id });
              }}
              offices={{ list: [office], data: { [office.id]: office.data } }}
              type="employee"
              isHubspotBtnVisible={true}
              organization={organizationStore.organization.orgData}
            />
          </div>
        )}
      </div>
    </Formik>
  );
};

export default ViewOfficeDetail;
