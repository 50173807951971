import { observable, computed, action } from 'mobx';
import firebase from 'firebase/app';
import firebaseService from 'services/firebase';
import OfficeType from './OfficeType';
import { appendFont } from 'utils/helpers';

function convertCsvOffices(rows, organizationId) {
  const offices = [];
  const errors = [];

  rows.forEach(row => {
    const office = new OfficeType();

    try {
      office.createCsvOffice(organizationId, row);
      offices.push(office);
    } catch (error) {
      errors.push(error);
    }
  });

  return { offices, errors };
}

class OrganizationType {
  @observable orgData = {};
  @observable fonts = [];

  get id() {
    return this.orgData.id;
  }

  get name() {
    return this.orgData.name;
  }

  set name(value) {
    this.orgData.name = value ? value.trim() : '';
  }

  officesUsingCard(card) {
    return card ? this.officesList.filter(x => x.defaultCard && x.defaultCard.id === card.id) : [];
  }

  getOfficeById(officeId) {
    if (!this.officesList) {
      return null;
    }
    const office = this.officesList.find(y => y.dataId === officeId);
    return office;
  }

  getOfficeByName(name) {
    if (!this.officesList) {
      return null;
    }
    const office = this.officesList.find(y => y.name === name);
    return office;
  }

  getOfficeNames() {
    return ['None'].concat(this.officesList.map(x => x.name));
  }

  @computed get imageUrl() {
    if (this.orgData.imageUrl) {
      return { uri: this.orgData.imageUrl };
    }
    // let defaultImge = require(`../assets/default-user-image.png`)
    return null;
  }

  set imageUrl(value) {
    this.orgData.imageUrl = value;
  }

  @computed get businessCards() {
    return this.orgData.businessCards;
  }

  set businessCards(value) {
    this.orgData.businessCards = value;
  }

  get nextOfficeId() {
    const keys = this.officesList.map(x => x.id);
    for (var i = 0; i < keys.length + 1; i++) {
      if (!keys.includes(i)) {
        return i;
      }
    }
    return null;
  }

  @computed get defaultCardId() {
    return this.orgData.defaultCardId;
  }

  createNew(ownerId) {
    this.orgData = {
      id: firebase.firestore().collection('organizations').doc().id,
      ownerId: ownerId,
      name: 'New Organization',
      imageUrl: { uri: '' },
      businessCards: [],
      billing: {},
      viewableBy: {},
      faxNumber: '',
      leadStatus: 'empty',
      lifecycleStage: 'empty',
      defaultCardId: null,
    };
    this.orgData.viewableBy[ownerId] = true;
    return this;
  }

  async uploadLogo(blob) {
    try {
      const currDate = new Date();
      const fileName = this.id + '-logo-' + currDate.toISOString();
      await firebase.storage().ref('companyLogos').child(fileName).put(blob);
      const url = await firebase
        .storage()
        .ref('companyLogos/' + fileName)
        .getDownloadURL();
      // delete old image
      if (this.imageUrl && this.imageUrl.uri.startsWith('https://firebase')) {
        await firebase.storage().refFromURL(this.imageUrl.uri).delete();
      }
      return url;
    } catch (e) {
      console.log(e);
    }
  }

  async importCSVOffices(rows, organizationId) {
    const { offices, errors } = convertCsvOffices(rows, organizationId);

    await this.saveOfficesToDb(offices);

    return { offices, errors };
  }

  async saveContactsToDb(tempArr) {
    const batchSize = 250;
    // save contacts to db
    while (tempArr.length > 0) {
      const batch = firebase.firestore().batch();
      this.saveContacts = function (x) {
        batch.set(firebase.firestore().collection('contacts').doc(x.id), x.firestoreDoc, {
          merge: true,
        });
      };
      const slice = tempArr.slice(0, batchSize - 1);
      slice.forEach(x => this.saveContacts(x));
      await batch
        .commit()
        .then(() => {
          console.log('Successfully executed batch.');
        })
        .catch(function (error) {
          console.error('Error updating: ', error.message);
        });
      const shorten = tempArr.slice(batchSize);
      tempArr = shorten;
    }
  }

  async saveOfficesToDb(tempArr) {
    const batchSize = 250;
    // save contacts to db
    while (tempArr.length > 0) {
      const batch = firebase.firestore().batch();
      this.saveObject = function (x) {
        batch.set(firebase.firestore().collection('offices').doc(x.id), x.firestoreDoc, {
          merge: true,
        });
      };
      const slice = tempArr.slice(0, batchSize - 1);
      slice.forEach(x => this.saveObject(x));
      await batch
        .commit()
        .then(() => {
          console.log('Successfully executed batch.');
        })
        .catch(function (error) {
          console.error('Error updating: ', error.message);
        });
      const shorten = tempArr.slice(batchSize);
      tempArr = shorten;
    }
  }

  async processWorkingCard() {
    try {
      if (!this.workingCard.publishDate) {
        this.workingCard.publishDate = new Date();
      }

      this.workingCard.lastUpdated = new Date();

      if (this.workingCard.frontUrl) {
        this.workingCard.frontUrl = await this.uploadCard(this.workingCard.frontUrl);
      } else {
        this.workingCard.frontUrl = '';
      }

      if (this.workingCard.backUrl) {
        this.workingCard.backUrl = await this.uploadCard(this.workingCard.backUrl);
      } else {
        this.workingCard.backUrl = '';
      }
    } catch (e) {
      console.log(e);
    }
  }

  async saveWorkingToNewVersion(index, versionIndex) {
    try {
      if (versionIndex >= 0 && versionIndex < this.orgData.businessCards[index].versions.length) {
        await this.processWorkingCard();
        if (this.workingCard.id === this.orgData.businessCards[index].activeVersion.id) {
          this.orgData.businessCards[index].activeVersion = this.workingCard;
        }
        this.orgData.businessCards[index].versions[versionIndex] = this.workingCard;
      } else {
        this.workingCard.id = firebase.firestore().collection('organizations').doc().id;
        await this.processWorkingCard();
        this.orgData.businessCards[index].versions.push(this.workingCard);
      }

      await this.saveBusinessCards();
    } catch (e) {
      console.log(e);
    }
  }

  async saveWorkingToNewCard() {
    try {
      this.workingCard.id = firebase.firestore().collection('organizations').doc().id;
      await this.processWorkingCard();

      if (!this.orgData.businessCards) {
        this.orgData.businessCards = [];
      }

      const newCard = {
        name: 'New Card',
        versions: [this.workingCard],
        activeVersion: this.workingCard,
      };
      this.orgData.businessCards.push(newCard);

      await this.saveBusinessCards();
    } catch (e) {
      console.log(e);
    }
  }

  async saveBusinessCards() {
    try {
      await firebase
        .firestore()
        .collection('organizations')
        .doc(this.id)
        .update({ businessCards: this.orgData.businessCards });
      this.workingCard = {};
    } catch (e) {
      console.log(e);
    }
  }

  async uploadCard(blob) {
    try {
      const currDate = new Date();
      const fileName = this.id + '-card-' + currDate.toISOString();
      await firebase.storage().ref('companyCards').child(fileName).put(blob);
      const url = await firebase
        .storage()
        .ref('companyCards/' + fileName)
        .getDownloadURL();
      return url;
    } catch (e) {
      console.log(e);
    }
  }

  fromFirestoreDoc(doc) {
    this.orgData = Object.assign(this.orgData, doc);
    return this.orgData;
  }

  save() {
    const { id, orgData } = this;
    firebaseService.saveOrganization(id, orgData);
  }

  @action setOrgFonts(fonts) {
    this.fonts = fonts;
  }

  @action setOrgFont(font) {
    this.fonts.push(font);
  }

  @action getOrgFonts(organizationId) {
    firebaseService.getOrgFonts(organizationId).then(orgFonts => {
      this.setOrgFonts(orgFonts);

      orgFonts.forEach(({ name, url }) => appendFont({ fontName: name, fontUrl: url }));
    });
  }

  @action uploadOrgFont({ font, organizationId }) {
    return firebaseService.uploadOrgFont({ font, organizationId }).then(uploadedFont => {
      if (uploadedFont) {
        this.setOrgFont(uploadedFont);

        appendFont({ fontName: uploadedFont.name, fontUrl: uploadedFont.url });

        return uploadedFont;
      }
      return null;
    });
  }
}

class AllOrganizations {
  constructor(account) {
    this.account = account;
  }

  @observable organizationList = [];
  @observable selectedOrg = {};

  getCurrentOfficeName(id) {
    const office = this.selectedOrg.officesList.find(y => y.dataId === id);
    return office ? office.name : '';
  }

  @computed get selectedOffices() {
    return this.selectedOrg ? this.selectedOrg.officesList : [];
  }

  @computed get selectedEmployees() {
    return this.selectedOrg ? this.selectedOrg.employeesList : [];
  }

  @computed get selectedOfficeEmployees() {
    return this.selectedOrg.employeesList.filter(
      x => x.officeId === this.selectedOrg.selectedOffice.dataId
    );
  }

  removeEmployeeFromOffice(employee) {
    employee.officeId = null;
    employee.save();
  }

  @action addOrganizations(tempArr) {
    const orgIds = this.organizationList.map(x => x.id);
    const filtered = tempArr.filter(x => !orgIds.includes(x.id));
    this.organizationList.push.apply(this.organizationList, filtered.slice());
  }

  @action async fetchOrganizations() {
    try {
      const whereClause = 'viewableBy.' + this.account.id;
      const snapshot = await firebase
        .firestore()
        .collection('organizations')
        .where(whereClause, '==', true)
        .get();

      const snap = snapshot.docs.map(x => x.data());
      var tempList = [];
      snap.forEach(x => {
        var organization = new OrganizationType();
        organization.fromFirestoreDoc(x);
        tempList.push(organization);
      });
      this.organizationList = [];
      this.addOrganizations(tempList);
    } catch (e) {
      console.error('Error fetchOrganizations: ', e.message);
      throw e;
    }
  }
}

export { OrganizationType, AllOrganizations };
