import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import firebase from 'firebase/app';
import FlexView from 'react-flexview/lib';

@inject('accountStore')
@observer
class CreateAccountLinkedInScreen extends Component {
  constructor(props) {
    super(props);

    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      vars[key] = value;
    });
    if (vars.code) {
      this.handleLogin(vars.code);
    }
  }

  componentDidMount() {
    this._unsubscribe = firebase.auth().onAuthStateChanged(user => this.userChanged(user));
  }

  componentWillUnmount() {
    this._unsubscribe();
  }

  async handleLogin(code) {
    try {
      await this.props.accountStore.createLinkedInAccount(
        code,
        `${process.env.REACT_APP_LINKEDIN_REDIRECT_URI_CREATE}`
      );
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <FlexView width="100%" height="100%" hAlignContent="center" vAlignContent="center">
        <FlexView>Loading...</FlexView>
      </FlexView>
    );
  }
}

export default CreateAccountLinkedInScreen;
