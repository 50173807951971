import { secondaryValidationSchema } from '../Screens/YourAccount/EditContactDetail.validation';
import {
  ATTRIBUTE_CSV_HEADERS,
  SECTIONS,
  PRIMARY_ATTRIBUTES,
  NAME_ATTRIBUTES_CSV_HEADERS,
} from './Contacts.config';
import parseCsv from 'global/parseCsv';

export const parseContactsCsv = (csv, callback) => {
  return parseCsv(csv, callback, {
    ATTRIBUTE_CSV_HEADERS,
    SECTIONS,
    PRIMARY_ATTRIBUTES,
    NAME_ATTRIBUTES_CSV_HEADERS,
  });
};

export function getInvalidAttributesPathes(contact) {
  try {
    secondaryValidationSchema.validateSync(contact, { abortEarly: false });
  } catch (error) {
    return error.inner.map(e => {
      const [section, index] = e.path.split(/[[\].]+/);
      return [section, +index];
    });
  }

  return [];
}

export function filterInvalidAttributes(contact) {
  const pathes = getInvalidAttributesPathes(contact);
  return pathes.reduce(
    (acc, [section, index]) => ({
      ...acc,
      [section]: acc[section].filter((_, i) => i !== index),
    }),
    contact
  );
}
