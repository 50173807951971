import firebase from '../firebase';
import { OrganizationType } from '../Models/Organization';
import { getFontFormatAndName } from 'utils/helpers';
import { toJS } from 'mobx';

const firestore = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();

let accountJustCreated = false;
let waitingAuthState = true;

const FUNCTIONS_GROUP_NAME = 'webApp';

const appleAuthProvider = new firebase.auth.OAuthProvider('apple.com');

appleAuthProvider.addScope('name');

const withBatch = async (docs = [], cb) => {
  let batch = firestore.batch();
  const BATCH_LIMIT = 250;

  try {
    for (let contactRefIndex = 0; contactRefIndex < docs.length; contactRefIndex++) {
      const counter = contactRefIndex || 1;
      if (counter % BATCH_LIMIT) {
        await batch.commit();
        batch = firestore.batch();
      }
      cb(batch, docs[contactRefIndex]);
    }

    await batch.commit();
  } catch (error) {
    throw error;
  }
};

const firebaseService = {
  async isEmailInUse(email) {
    const methods = await auth.fetchSignInMethodsForEmail(email);
    return methods.length > 0;
  },

  async signInWithEmailAndPassword(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  },

  isWatingAuthState: () => waitingAuthState,

  isAuthenticated: () => !!auth.currentUser,

  getUserId() {
    return auth.currentUser ? auth.currentUser.uid : null;
  },

  nextId: collectionName => firestore.collection(collectionName).doc().id,

  nextContactId: () => firebaseService.nextId('contacts'),

  nextOfficeId: () => firebaseService.nextId('offices'),

  nextCardId: () => firebaseService.nextId('cards'),

  async signOut() {
    return auth.signOut();
  },

  async createUserWithEmailAndPassword(email, password) {
    accountJustCreated = true;
    return auth.createUserWithEmailAndPassword(email, password);
  },

  async resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  },

  addAuthStateListener(callback) {
    return auth.onAuthStateChanged(result => {
      callback(result, accountJustCreated);
      waitingAuthState = false;
      accountJustCreated = false;
    });
  },

  subscribeOnUser(callback) {
    return firestore
      .collection('users')
      .doc(auth.currentUser.uid)
      .onSnapshot(snapshot => {
        callback(snapshot.data());
      });
  },

  async saveUser(user) {
    return await firestore.collection('users').doc(firebaseService.getUserId()).set(user);
  },

  async fetchContact(id) {
    const contact = await firestore.collection('contacts').doc(id).get();
    return contact.data();
  },

  saveContact(hcInfo) {
    return firestore.collection('contacts').doc(hcInfo.id).set(hcInfo);
  },

  updateContact(id, changes) {
    return firestore.collection('contacts').doc(id).update(changes);
  },

  updateOrganizationNameInContacts({ organizationId, organizationName }) {
    const contactsCollection = firestore.collection('contacts');

    contactsCollection
      .where('organizationId', '==', organizationId)
      .get()
      .then(contacts => {
        if (contacts.size > 0) {
          const batch = firestore.batch();

          contacts.forEach(({ id: contactId }) => {
            const contactRef = contactsCollection.doc(contactId);
            batch.update(contactRef, { organization: organizationName });
          });

          batch.commit().catch(e => console.error('updateOrganizationNameInContacts -', e));
        }
      });
  },

  async updateContactsOrganization(updatePayload, contacts = []) {
    const contactsRef = contacts.map(({ id }) => firestore.collection('contacts').doc(id));

    await withBatch(contactsRef, (batch, contactRef) => {
      batch.update(contactRef, updatePayload);
    });
  },

  saveOrganization(id, orgData) {
    return firestore.collection('organizations').doc(id).set(orgData);
  },

  updateOrganization(id, orgData) {
    return firestore.collection('organizations').doc(id).update(orgData);
  },

  saveOffice(id, data) {
    return firestore.collection('offices').doc(id).set(data);
  },

  updateOffice(id, changes) {
    return firestore.collection('offices').doc(id).update(changes);
  },

  saveCard(id, data) {
    return firestore.collection('cards').doc(id).set(data);
  },

  updateCard(id, data) {
    return firestore.collection('cards').doc(id).update(data);
  },

  getCard(id) {
    return firebase
      .firestore()
      .collection('cards')
      .doc(id)
      .get()
      .then(doc => doc?.data());
  },

  async deleteFromStorage(url) {
    try {
      await storage.refFromURL(url).delete();
    } catch (e) {
      console.log(e);
    }
  },

  async uploadImage(id, blob, imageType = 'cardImages', oldUrl) {
    const fileName = `${id}-${new Date().toISOString()}${
      imageType !== 'profileImage' ? '-' + imageType : ''
    }`;

    const folder = imageType === 'profileImage' ? 'userImages' : 'cardImages';

    await storage.ref(folder).child(fileName).put(blob);

    const url = await storage.ref(`${folder}/${fileName}`).getDownloadURL();

    if (oldUrl) {
      firebaseService.deleteFromStorage(oldUrl);
    }

    return url;
  },

  getOrgFonts(id) {
    return firebase
      .firestore()
      .collection('organizations')
      .doc(id)
      .collection('fonts')
      .get()
      .then(querySnapshot => {
        const fonts = [];

        querySnapshot.forEach(doc => {
          const font = doc?.data();
          if (font) {
            fonts.push(font);
          }
        });

        return fonts;
      });
  },

  async uploadOrgFont({ font, organizationId }) {
    const { format, name } = getFontFormatAndName(font);
    const fontsQuery = firebase
      .firestore()
      .collection('organizations')
      .doc(organizationId)
      .collection('fonts');
    const isUniqueFont = await fontsQuery
      .where('name', '==', name)
      .get()
      .then(querySnapshot => querySnapshot.empty);

    if (isUniqueFont) {
      const storageFontName = `${Date.now()}-${name}`; // ToDo try to check with QA team upload fonts with the same name
      await storage.ref('fonts').child(storageFontName).put(font);
      const fontUrl = await storage.ref(`fonts/${storageFontName}`).getDownloadURL();
      const newFont = {
        name,
        format,
        url: fontUrl,
        publishDate: new Date().toISOString(),
        addedByUserId: this.getUserId(),
      };

      await fontsQuery.add(newFont);
      return newFont;
    }

    return null;
  },

  subscribeOnContact(id, callback) {
    return firestore
      .collection('contacts')
      .doc(id)
      .onSnapshot(async result => {
        const profile = result.data() || { receivedCard: {} };
        const receivedCardsIds = Object.keys(profile.receivedCard || {});

        const receivedCards = await Promise.all(
          receivedCardsIds.map(async id => await this.fetchContact(id))
        );

        callback({ ...profile, receivedCards });
      });
  },

  getOrganization(id) {
    return firebase
      .firestore()
      .collection('organizations')
      .doc(id)
      .get()
      .then(doc => {
        const data = doc?.data();
        return new OrganizationType().fromFirestoreDoc(data);
      });
  },

  getOrganizationByName(name) {
    return firebase
      .firestore()
      .collection('organizations')
      .where('name', '==', name)
      .get()
      .then(({ docs }) => {
        const data = docs[0]?.data();
        return new OrganizationType().fromFirestoreDoc(data);
      });
  },

  getOffice(id) {
    return firestore
      .collection('offices')
      .doc(id)
      .get()
      .then(doc => doc?.data())
      .catch(e => console.error('getOffice - ', e));
  },

  subscribeOnOrganization(ownerId, callback) {
    return firestore
      .collection('contacts')
      .doc(ownerId)
      .get()
      .then(contactDoc => {
        const contact = contactDoc.data();

        return firestore
          .collection('organizations')
          .doc(contact.organizationId)
          .onSnapshot(doc => {
            if (doc) {
              callback(doc.data());
            }
          });
      });
  },

  deleteOrganization(id) {
    return firestore.collection('organizations').doc(id).delete();
  },

  deleteOffice(id) {
    return firestore.collection('offices').doc(id).delete();
  },

  async callFunction(fnName, ...args) {
    return functions.httpsCallable(FUNCTIONS_GROUP_NAME + '-' + fnName)(...args);
  },

  async sendWelcomeEmail(email) {
    return firebaseService.callFunction('sendWelcomeEmail', email);
  },

  signInWithApple() {
    return auth.signInWithPopup(appleAuthProvider);
  },

  async signInWithCustomToken(authId) {
    const {
      data: { customToken },
    } = await this.createCustomToken(authId);

    await auth.signInWithCustomToken(customToken).catch(error => {
      console.log('signInWithCustomToken ', error);
      throw error;
    });
  },

  createCustomToken(id) {
    return firebaseService.callFunction('createCustomToken', id);
  },

  async getParentOfSharedContact(sharedContactId) {
    if (!sharedContactId) {
      return;
    }

    const sharedContactRef = firebase.firestore().collection('contacts').doc(sharedContactId);

    const sharedContact = await sharedContactRef.get();
    if (sharedContact.exists) {
      return sharedContact.data();
    }
  },

  async handleDeclineUpdatePatch(contact) {
    return firebaseService.callFunction('changeUpdatePatchLastUpdateTime', contact);
  },

  handleDeleteContact(contact) {
    return firebaseService.callFunction('deleteContact', toJS(contact));
  },

  async deleteContacts(contacts = []) {
    const contactsRef = contacts.map(({ id }) => firestore.collection('contacts').doc(id));

    await withBatch(contactsRef, (batch, contactRef) => {
      batch.delete(contactRef);
    });
  },

  async withInSearch(contacts = [], collection, field, systemField) {
    const contactsHcInfo = contacts.map(contact => contact.hcInfo || contact);
    const searchStep = 9; // https://firebase.google.com/docs/firestore/query-data/queries?authuser=1#in_not-in_and_array-contains-any
    const searchedContacts = [];

    while (contactsHcInfo.length) {
      const fieldValues = contactsHcInfo.splice(0, searchStep).map(contact => contact[field]);
      const searchedContactsDocs = await firebase
        .firestore()
        .collection(collection)
        .where(systemField || field, 'in', fieldValues)
        .get();

      searchedContacts.push(
        ...searchedContactsDocs.docs.map(searchedContactsDoc => ({
          data: searchedContactsDoc.data(),
          id: searchedContactsDoc.id,
        }))
      );
    }

    return searchedContacts;
  },

  async getContact(id) {
    const contact = await firestore.collection('contacts').doc(id).get();

    return contact.data();
  },
};

export default firebaseService;
