import React, { useCallback } from 'react';
import Ionicon from 'react-ionicons';
import styles from './DebounceInput.module.css';

import { debounce } from '../../utils/helpers';

const Input = (
  { onChange, debounce: time = 500, icon, className, value, style, ...props },
  ref
) => {
  const onChangeHandler = debounce(onChange, time);

  const onKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        onChangeHandler(e.target.value);
      }
    },
    [onChangeHandler]
  );

  return (
    <div className={`${styles.inputWrapper} ${className}`} style={style}>
      <Ionicon className={styles.icon} icon={icon} fontSize="14px" />
      <input
        {...props}
        ref={ref}
        onChange={e => {
          onChangeHandler(e.target.value);
        }}
        onKeyPress={onKeyPress}
      />
      {value && (
        <Ionicon
          onClick={() => {
            ref.current.value = '';
            onChangeHandler('');
          }}
          icon="ios-close"
          className={`${styles.icon} ${styles.closeIcon}`}
        />
      )}
    </div>
  );
};

const DebounceInput = React.forwardRef(Input);

export default DebounceInput;
