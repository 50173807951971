import React from 'react';

import ModalDialog from './ModalDialog';

import classes from './ModalDialog.module.css';

const OptionsModalDialog = ({ footer, onClose, title, children }) => (
  <ModalDialog
    className={classes.modalContainer}
    title={title}
    onClose={onClose}
    footer={<div className={classes.btnContainer}>{footer}</div>}
  >
    {children}
  </ModalDialog>
);

export default OptionsModalDialog;
