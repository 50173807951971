import { createMuiTheme } from '@material-ui/core/styles';

const themes = {
  white: {
    palette: {
      primary: { main: '#fff' },
      secondary: { main: 'rgba(255,255,255,0.3)' },
    },
  },

  blue: {
    palette: {
      primary: { main: '#468BFF' },
      secondary: { main: 'rgba(0,0,0,0.3)' },
    },
  },

  black: {
    palette: {
      primary: { main: '#000' },
      secondary: { main: 'rgba(0,0,0,0.3)' },
    },
  },
};

export default Object.keys(themes).reduce(
  (adaptedThemes, themeName) => ({
    ...adaptedThemes,
    [themeName]: createMuiTheme({
      ...themes[themeName],
      typography: {
        useNextVariants: true,
      },
    }),
  }),
  {}
);
