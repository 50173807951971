import React, { Component } from 'react';

import FlexView from 'react-flexview/lib';

import classNames from 'classnames';
import Dropzone from 'react-dropzone';

const errorMessages = {
  'file-invalid-type': 'Invalid file type',
};

const fileTypes = {
  image: 'image/jpeg, image/png',
  csv: '.csv',
  font: '.otf, .ttf, .woff',
};

class DropFileUpload extends Component {
  onDrop = ([file]) => {
    this.props.filename(file);
  };

  onDropRejected = ([file]) => {
    const [error] = file.errors;
    alert(errorMessages[error.code] || error.message);
  };

  render() {
    return (
      <FlexView
        column
        grow
        width="100%"
        height="100%"
        style={{ backgroundColor: 'white', cursor: 'pointer' }}
      >
        <Dropzone
          onDropAccepted={this.onDrop}
          onDropRejected={this.onDropRejected}
          multiple={false}
          accept={fileTypes[this.props.fileType]}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <FlexView
                grow
                height={'100%'}
                width={'100%'}
                hAlignContent={'center'}
                vAlignContent={'center'}
                style={{
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderStyle: 'dashed',
                  borderColor: '#707070',
                  borderRadius: 5,
                  overflowY: 'auto',
                }}
                {...getRootProps()}
                className={classNames('dropzone', { 'dropzone--isActive': true })}
              >
                <input {...getInputProps()} />
                {this.props.text}
              </FlexView>
            );
          }}
        </Dropzone>
      </FlexView>
    );
  }
}

export default DropFileUpload;
