import React, { useEffect, useRef, useState, memo } from 'react';
import Cropper from 'cropperjs';
import cn from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

import { CARD_SIDE } from 'utils/constants';
import Button from '../../Common/Button';
import HCTextEditBlack from '../../../Screens/Common/HCTextEditBlack';
import styles from './ImageCropper.module.css';

const ImageCropper = ({
  imageFile,
  cardAspectRatio,
  cardSide,
  cardBorderRadius,
  cardBorderRadiusProportion,
  onSaveCroppedImage,
  onCancelUploadImage,
}) => {
  const [cropper, setCropper] = useState(null);
  const [cornerRadius, setCornerRadius] = useState(cardBorderRadius);
  const [cornerRadiusProportion, setCornerRadiusProportion] = useState(cardBorderRadiusProportion);
  const [maxCornerRadius, setMaxCornerRadius] = useState(0);
  const imageRef = useRef(null);
  const imageBorderRadiusStyles = `.cropper-crop-box, .cropper-view-box { border-radius: ${cornerRadiusProportion.x}%/${cornerRadiusProportion.y}%; }`; // change global cropperjs library styles
  useEffect(() => {
    const image = imageRef.current;

    if (imageFile) {
      image.src = URL.createObjectURL(imageFile);
      setCropper(
        new Cropper(image, {
          zoomable: true,
          initialAspectRatio: cardAspectRatio,
          aspectRatio: cardSide === CARD_SIDE.FRONT ? undefined : cardAspectRatio,
        })
      );
    }

    return () => {
      if (image) {
        URL.revokeObjectURL(image.src);
      }
    };
  }, [imageFile]);

  useEffect(
    () => () => {
      cropper?.destroy();
    },
    [cropper]
  );

  useEffect(() => {
    const { height = 0, width = 0 } = cropper?.getCropBoxData() || {};
    const maxCornerRadius = Math.ceil(height > width ? width / 2 : height / 2) || cornerRadius;

    if (maxCornerRadius > cornerRadius) {
      setCornerRadiusProportion({
        x: (cornerRadius / width) * 100,
        y: (cornerRadius / height) * 100,
      });
    }
    setMaxCornerRadius(maxCornerRadius);
  }, [cornerRadius, cropper]);

  const handleSaveCroppedImage = () => {
    if (cropper) {
      const { height, width } = cropper.getCropBoxData();

      cropper
        .getCroppedCanvas({
          fillColor: 'white',
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high',
        })
        .toBlob(blob => {
          if (blob) {
            blob.name = 'newFile.jpeg';
            const url = URL.createObjectURL(blob);

            onSaveCroppedImage({
              imageObj: { blob, url },
              borderRadius: cornerRadius > maxCornerRadius ? maxCornerRadius : cornerRadius,
              borderRadiusProportion: cornerRadiusProportion,
              aspectRatio: width / height,
            });
            URL.revokeObjectURL(blob);
          }
        }, 'image/jpeg');

      cropper.destroy();
    }
  };

  const handleChangeCornerRadius = (_, { target: { value } }) => {
    setCornerRadius(+value > 0 ? +value : '');
  };

  const handleBlurCornerRadius = ({ target: { value } }) => {
    setCornerRadius(+value > 0 ? +value : 0);
  };

  return (
    <div className={styles.root}>
      <div className={styles.btnBlock}>
        <div className={styles.leftBlock}>
          {cardSide === CARD_SIDE.FRONT && (
            <Tooltip
              title={`${
                cornerRadius > maxCornerRadius ? `Maximum value is ${maxCornerRadius}px` : ''
              }`}
              placement="top"
            >
              <div>
                <HCTextEditBlack
                  label="Corner radius"
                  type="number"
                  name="cornerRadius"
                  value={cornerRadius}
                  className={styles.input}
                  onChange={handleChangeCornerRadius}
                  onBlur={handleBlurCornerRadius}
                />
              </div>
            </Tooltip>
          )}
        </div>

        <div className={styles.rightBlock}>
          <Button caption="Crop & Save" className="button green" onClick={handleSaveCroppedImage} />
          <Button
            caption="Cancel"
            className={cn('button white', styles.cancelBtn)}
            onClick={onCancelUploadImage}
          />
        </div>
      </div>

      <div className={styles.imageWrapper}>
        <style dangerouslySetInnerHTML={{ __html: imageBorderRadiusStyles }} />
        <img ref={imageRef} src="" alt="" className={styles.image} />
      </div>
    </div>
  );
};

export default memo(ImageCropper);
