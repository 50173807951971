import React, { useState, useEffect } from 'react';
import Button from '../Common/Button';
import Ionicon from 'react-ionicons';
import HCTextEdit from '../Common/HCTextEdit';
import Loader from 'global/components/Loader';
import { noop } from 'global/utils';

export default function ContactNotes({ onSave, notes, onNotesChanged = noop, isEdit = false }) {
  const [isLoading, setIsLoading] = useState(false);
  const [_notes, setNotes] = useState(notes);
  const [_isEdit, setIsEdit] = useState(isEdit);

  useEffect(() => {
    setNotes(notes);
  }, [notes]);

  const handleClose = e => {
    e.stopPropagation();
    setIsEdit(false);
    setNotes(notes);
  };

  const handleSave = async () => {
    setIsLoading(true);
    await onSave(_notes);
    setIsLoading(false);
    setIsEdit(false);
  };

  const handleOnChange = (value, event) => {
    onNotesChanged(event);
    setNotes(value);
  };

  return (
    <section className={`notes notes--edit-${_isEdit}`} onClick={() => setIsEdit(true)}>
      <header>
        <h2>Notes</h2>
        {_isEdit && (
          <div className="header-buttons">
            {isLoading ? (
              <Loader className="smallGrey" />
            ) : (
              <>
                {onSave && (
                  <Button type="submit" caption="Save" icon="md-checkmark" onClick={handleSave} />
                )}
                <Ionicon onClick={handleClose} icon="ios-close" className="close-button" />
              </>
            )}
          </div>
        )}
      </header>
      <div>
        {_isEdit ? (
          <HCTextEdit
            name="notes"
            style={{ flex: 1 }}
            label="Notes"
            value={_notes}
            onChange={handleOnChange}
            multiline
          />
        ) : (
          <pre>{_notes}</pre>
        )}
      </div>
    </section>
  );
}
