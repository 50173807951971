import React, { Fragment } from 'react';

// Wrap every matched entry with JSX tag <mark>
const HighlightedJSX = ({ value, matcher = '' }) =>
  matcher === ''
    ? value
    : (value || '')
        .split(matcher)
        .map((part, index) => (
          <Fragment key={index}>{part.match(matcher) ? <mark>{part}</mark> : part}</Fragment>
        ));

export default HighlightedJSX;
