import React from 'react';
import { Field } from 'formik';

import HCTextEdit from '../Common/HCTextEdit';

// eslint-disable-next-line react/display-name
const ValidableTextField = React.forwardRef(
  ({ name, errorMessage, label, required, disabled, ...rest }, ref) => (
    <Field name={name}>
      {({ field, form: { handleChange, handleBlur }, meta }) => (
        <HCTextEdit
          ref={ref}
          error={meta.error}
          label={label}
          name={name}
          value={field.value}
          onChange={(text, event) => {
            handleChange(event);
          }}
          onBlur={handleBlur}
          helperText={meta.error ? errorMessage : null}
          required={required}
          disabled={disabled}
          {...rest}
        />
      )}
    </Field>
  )
);

export default ValidableTextField;
