import React, { useRef, useEffect, useState } from 'react';
import { useTable, useFlexLayout, useResizeColumns } from 'react-table';
import Ionicon from 'react-ionicons';
import TableCellActions from './TableCellActions';
import styles from './Table.module.css';
import { useInfiniteScroll, usePolyfillScroll } from '../../hooks';
import Loader from 'global/components/Loader';
import HighlightedJSX from '../HighlightedJSX';

const getActionsColumn = (actions, isSidebarOpen) =>
  actions
    ? {
        Header: 'Actions',
        accessor: 'id',
        Cell: props => TableCellActions(actions(props), props, isSidebarOpen),
      }
    : null;

const SortIcon = ({ direction }) =>
  direction === 'asc' ? (
    <Ionicon className={styles.sortIcon} icon="md-arrow-round-up" fontSize="11px" />
  ) : (
    <Ionicon className={styles.sortIcon} icon="md-arrow-round-down" fontSize="11px" />
  );

const invertSortDirection = direction => (direction === 'asc' ? 'desc' : 'asc');

const defaultColumn = {
  minWidth: 100,
};

const makeHighlighted = (column, { highlight, isFetching }) => ({
  ...column,
  Cell: props => {
    if (isFetching || highlight === '') {
      return typeof column.Cell === 'function' ? column.Cell(props) : props.value || '';
    }

    const matcher = new RegExp(`(${highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');

    if (typeof column.Cell === 'function') {
      return column.Cell({ ...props, matcher });
    }

    return HighlightedJSX({
      value: props.value,
      matcher,
    });
  },
});

const useResetScroll = ref => {
  const [showResetScroll, setShowResetScroll] = useState(false);

  const resetScroll = () => ref.current.scrollTo({ top: 0, behavior: 'smooth' });

  const checkScroll = e => {
    if (!showResetScroll && e.target.scrollTop > 100) {
      setShowResetScroll(true);
    } else if (showResetScroll && e.target.scrollTop <= 100) {
      setShowResetScroll(false);
    }
  };

  ref.current?.addEventListener('scroll', checkScroll);

  return {
    showResetScroll,
    resetScroll,
  };
};

const ReactTable = ({
  columns = [],
  data = [],
  selected,
  onRowClick,
  onOrderByClick,
  orderBy,
  actions,
  isFetching,
  isFetchingMore,
  canFetchMore,
  onFetchMore,
  highlight,
  columnsToUpdateDependency,
  isSidebarOpen,
  isImportSuccess,
  resetImportSuccess,
  isEmpty,
  emptyMessage = '',
  activeId,
}) => {
  const highlightedColumns = React.useMemo(
    () =>
      columns.map(column =>
        makeHighlighted(column, { highlight, isFetching: isFetching || isFetchingMore })
      ),
    [highlight, columnsToUpdateDependency]
  );

  const highlightedColumnsWithActions = React.useMemo(
    () =>
      actions
        ? [...highlightedColumns, getActionsColumn(actions, isSidebarOpen)]
        : highlightedColumns,
    [data, columnsToUpdateDependency, isSidebarOpen]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns: highlightedColumnsWithActions, data, defaultColumn },
    useFlexLayout,
    useResizeColumns
  );

  const tableRef = useRef(null);
  useInfiniteScroll({
    ref: tableRef,
    fetchMore: onFetchMore,
    canFetchMore,
    isFetching: isFetching || isFetchingMore,
  });

  const { showResetScroll, resetScroll } = useResetScroll(tableRef);

  usePolyfillScroll();

  useEffect(() => {
    if (isImportSuccess && tableRef) {
      tableRef.current.scrollTo(0, 0);
      resetImportSuccess();
    }
  }, [isImportSuccess]);

  const onClickColumnHeader = ({ Header, id }) => {
    // Do nothing for 'Actions' column
    if (Header === 'Actions') {
      return;
    }

    // Scroll to the Table top
    tableRef.current.scrollTo(0, 0);

    // Set inverted sort direction if this is the same column
    // Or use 'asc' by default
    const direction = orderBy?.field === id ? invertSortDirection(orderBy.direction) : 'asc';

    onOrderByClick({
      field: id,
      direction,
    });
  };

  return (
    <div className={`${styles.tableWrapper}${isFetching ? styles.fetching : ''}`} ref={tableRef}>
      <>
        <Ionicon
          className={`${styles.resetScroll} ${showResetScroll && styles.resetScrollShow}`}
          icon="ios-arrow-up"
          onClick={resetScroll}
          color="#fff"
        />
        {isFetching ? (
          <div className={styles.tableLoader}>
            <Loader />
          </div>
        ) : null}
        <div className={styles.table} {...getTableProps()}>
          <div className={styles.thead}>
            {headerGroups.map(headerGroup => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className={styles.tr}
                key={headerGroup.getHeaderGroupProps().key}
              >
                {headerGroup.headers.map(column => {
                  const { render, getHeaderProps, isResizing, getResizerProps } = column;

                  return (
                    <div
                      {...getHeaderProps()}
                      key={getHeaderProps().key}
                      className={styles.th}
                      onClick={() => onClickColumnHeader(column)}
                    >
                      <div>{render('Header')}</div>
                      {orderBy?.field === column.id ? (
                        <SortIcon direction={orderBy.direction} />
                      ) : null}
                      <div
                        {...getResizerProps()}
                        className={`${styles.resizer} ${isResizing ? styles.isResizing : ''}`}
                      />
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);

              return (
                <div
                  {...row.getRowProps()}
                  key={row.getRowProps().key}
                  onClick={
                    onRowClick ? () => onRowClick(row.id, row.values) : row.getRowProps().onClick
                  }
                  className={`
                    ${styles.tr}
                    ${onRowClick && styles.rowClickable}
                    ${row.values.id === activeId && styles.rowActive}`}
                >
                  {row.cells.map(cell => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={styles.td}
                        key={cell.getCellProps().key}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.tableFooter}>
          {isFetchingMore ? (
            <Loader className="smallGrey" />
          ) : (
            <>
              {canFetchMore ? (
                <span className={styles.fetchMore} onClick={onFetchMore}>
                  Fetch more...
                </span>
              ) : isEmpty ? (
                emptyMessage
              ) : (
                <span>No more data</span>
              )}
            </>
          )}
        </div>
      </>
    </div>
  );
};

export default ReactTable;
