import { CARD_SIDE } from 'utils/constants';

export const DEFAULT_CARD_RESIZIBLE_FIELD_MIN_WIDTH = 50;
export const CARD_FIELD_MIN_FONT_SIZE = 12;
export const CARD_FIELD_MAX_FONT_SIZE = 72;
export const DEFAULT_FONT = 'Nunito';
export const DEFAULT_COLOR = '#000000';
export const DEFAULT_FONT_STYLE = 'Regular';
export const DEFAULT_ALIGNMENT = 'Left';

export const CARD_EDITOR_STEP = { CROP_IMAGE: 'cropImage', ADD_FIELDS: 'addFields' };
export const INITIAL_CARD_FIELD_SIZE = { WIDTH: 245, HEIGHT: 30 };
export const INITIAL_CARD_CROPPED_IMAGES = { [CARD_SIDE.FONT]: null, [CARD_SIDE.BACK]: null };
export const OMIT_VERSION_FIELDS = ['fontBlob', 'publishDate', 'lastUpdated', 'cardSize'];

export const INITIAL_FIELD_STYLES = {
  fontSize: CARD_FIELD_MIN_FONT_SIZE,
  color: DEFAULT_COLOR,
  font: DEFAULT_FONT,
  fontStyle: DEFAULT_FONT_STYLE,
  alignment: DEFAULT_ALIGNMENT,
};

export const CARD_FIELD_STYLE_NAME = {
  X: 'x',
  Y: 'y',
  FONT: 'font',
  COLOR: 'color',
  FONT_SIZE: 'fontSize',
  FONT_STYLE: 'fontStyle',
  ALIGNMENT: 'alignment',
};

export const DEFAULT_CARD_RESIZIBLE_FIELD_ROUTE = {
  top: false,
  right: true,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  topLeft: false,
  bottomLeft: false,
};
