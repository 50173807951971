import React from 'react';

import classes from './components.module.css';

export default function Loader({ className }) {
  return (
    <div className={`${classes.loader} ${classes[className] || ''}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
