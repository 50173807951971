import React, { memo } from 'react';
import cn from 'classnames';

import { CARD_SIDE, CARD_CONTAINER_SIZE_LABELS } from 'utils/constants';
import Card from 'global/components/Card';
import styles from './OrgCardItem.module.css';

const FRONT_CARD_CONTAINER = {
  [CARD_CONTAINER_SIZE_LABELS.NON_STANDARD]: { width: 320, height: 320 },
  [CARD_CONTAINER_SIZE_LABELS.DEFAULT]: { width: 320, height: 320 },
};
const BACK_CARD_CONTAINER = {
  [CARD_CONTAINER_SIZE_LABELS.NON_STANDARD]: { width: 80, height: 80 },
  [CARD_CONTAINER_SIZE_LABELS.DEFAULT]: { width: 80, height: 80 },
};

const OrgCardItem = ({ card = {}, activeCard, onCardClick }) => {
  const { versions, activeVersionId, id, name } = card;
  const activeVersion = versions?.find(version => version.id === activeVersionId);
  const { frontUrl, backUrl } = activeVersion || {};

  const handleCardClick = () => {
    onCardClick(card);
  };

  return (
    <div
      className={cn(styles.root, { [styles.active]: id === activeCard?.id })}
      onClick={handleCardClick}
    >
      <Card
        card={activeVersion}
        side={CARD_SIDE.FRONT}
        url={frontUrl}
        className={styles.frontSide}
        containerSize={FRONT_CARD_CONTAINER}
        placeholder="No Card Front"
        backSideCard={
          <Card
            card={activeVersion}
            side={CARD_SIDE.BACK}
            className={styles.backSide}
            url={backUrl}
            containerSize={BACK_CARD_CONTAINER}
            placeholder="No Card Back"
          />
        }
      />
      <p className={styles.title}>{name}</p>
    </div>
  );
};

export default memo(OrgCardItem);
