import React from 'react';
import Ionicon from 'react-ionicons';

const styles = {
  logoStyle: {
    height: '100%',
    width: '100%',
  },
};

const HCProfile = ({ onClick, style, url, initials, placeholder, disabled }) => (
  <div className={`picture profile ${disabled ? 'disabled' : ''}`} onClick={onClick} style={style}>
    {url && url.length > 0 && <img src={url} style={styles.logoStyle} alt="logo" />}
    {initials && !url && <div className="initials">{initials}</div>}
    {!initials && !url && (
      <div className="placeholder">
        <Ionicon icon="md-add" fontSize="12px" color="grey" />
        <span>{placeholder || 'Edit'}</span>
      </div>
    )}
  </div>
);

export default HCProfile;
