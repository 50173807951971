import React from 'react';

import Ionicon from 'react-ionicons';

import classes from './HoverWrapper.module.css';

const HoverWrapper = ({ condition, onDelete, children }) => (
  <>
    {condition ? (
      <div className={classes.hoverWrapper}>
        <Ionicon className={classes.icon} onClick={onDelete} icon="ios-close" />
        {children}
      </div>
    ) : (
      children
    )}
  </>
);

export default HoverWrapper;
