import React, { useState, useMemo, useEffect, memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Formik, Form, Field } from 'formik';
import { MuiThemeProvider } from '@material-ui/core';
import Ionicon from 'react-ionicons';

import firebaseService from 'services/firebase';
import { useInfiniteFirebaseQuery } from 'global/hooks';
import { PATH, PATH_ACTION } from 'utils/constants';
import { getListItemsIdsByKeys, getObjectFromArrayByKey } from 'utils/helpers';
import Loader from 'global/components/Loader';
import { ContactType } from '../../../../Models/Contacts';
import OfficeType from '../../../../Models/OfficeType';
import themes from '../../../Common/themes';
import { Tabs, Tab } from '../../../Common/Tabs';
import Checkmark from '../../../Common/Checkmark';
import Button from '../../../Common/Button';
import OptionsModal from '../../OptionsModal';
import ValidableTextField from '../../../YourAccount/ValidableTextField';
import diskette from 'assets/icons/diskette.svg';
import styles from '../../ViewOfficeDetail.module.css';
import ownStyles from './OrgCardDetail.module.css';

function getConfirmMessage(employeesData, cardName, key) {
  const employeesNames = employeesData.map(({ data }) => ` "${data?.[key]}"`);
  return `${employeesNames} are already using other card. Would you like to remove them from those card and put in the "${cardName}"?`;
}

function convertTimestamp(date) {
  return new Date(date).toLocaleString();
}

const OrgCardDetail = inject('organizationStore')(props => {
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(props.activeTab === 'name' ? 3 : 0);
  const [search, setSearch] = useState('');
  const [isEmployeesAll, setAllEmployees] = useState(false);
  const [isOfficesAll, setAllOffices] = useState(false);
  const [isOptionsModal, setIsOptionsModal] = useState(false);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [officeIds, setOfficeIds] = useState([]);
  const [isNameChanged, setIsNameChanged] = useState(false);
  const [isSettingsView, setIsSettingsView] = useState(props.activeTab !== 'name');

  const history = useHistory();

  const getNewNameAlert = () => {
    props.onAddToastText('You have not saved new value');
    setTab(3);
  };

  const routeToCreate = useCallback(() => {
    history.push(`${PATH.ORG_CARD_EDITOR}/${PATH_ACTION.CREATE}/${history.location.hash.slice(1)}`);
  }, [history]);

  useEffect(() => {
    if (props.activeTab === 'name') {
      setTab(3);
      setIsSettingsView(false);
    }
  }, [props.activeTab]);

  const { data: employeesList, isFetching: isEmployeesFetching } = useInfiniteFirebaseQuery(
    'contacts',
    {
      adapter: contact => new ContactType().fromFirestoreDoc(contact),
      queryAdapter: query =>
        query.where('organizationId', '==', props.organizationStore.organization.id),
      search,
    }
  );

  const employeesData = useMemo(() => getObjectFromArrayByKey(employeesList, 'id'), [
    employeesList,
  ]);

  const {
    data: cardEmployees,
    refetch: refetchCardEmployees,
    isFetching: isCardEmployeesFetching,
  } = useInfiniteFirebaseQuery('contacts', {
    adapter: contact => new ContactType().fromFirestoreDoc(contact),
    queryAdapter: query =>
      query
        .where('organizationId', '==', props.organizationStore.organization.id)
        .where('cardId', '==', props.selectedCard?.id || null),
  });

  useEffect(() => {
    refetchCardEmployees();
  }, [props.selectedCard?.id]);

  const cardEmployeeIds = useMemo(
    () => getListItemsIdsByKeys(cardEmployees, ['hcInfo', 'cardId'], props.selectedCard?.id),
    [cardEmployees, props.selectedCard?.id]
  );

  const cardEmployeesData = useMemo(() => getObjectFromArrayByKey(cardEmployees, 'id'), [
    cardEmployees,
  ]);

  const { data: officesList, isFetching: isOfficesFetching } = useInfiniteFirebaseQuery('offices', {
    queryAdapter: query =>
      query.where('organizationId', '==', props.organizationStore.organization.id),
    adapter: doc => new OfficeType().fromFirestoreDoc(doc),
    search,
  });

  const officesData = useMemo(() => getObjectFromArrayByKey(officesList, 'id'), [officesList]);

  const {
    data: cardOffices,
    refetch: refetchCardOffices,
    isFetching: isCardOfficeFetching,
  } = useInfiniteFirebaseQuery('offices', {
    adapter: contact => new OfficeType().fromFirestoreDoc(contact),
    queryAdapter: query =>
      query
        .where('organizationId', '==', props.organizationStore.organization.id)
        .where('cardId', '==', props.selectedCard?.id || null),
  });

  useEffect(() => {
    refetchCardOffices();
  }, [props.selectedCard?.id]);

  const cardOfficeIds = useMemo(
    () => getListItemsIdsByKeys(cardOffices, ['data', 'cardId'], props.selectedCard?.id),
    [cardOffices, props.selectedCard?.id]
  );

  const cardOfficesData = useMemo(() => getObjectFromArrayByKey(cardOffices, 'id'), [cardOffices]);

  const availableEmployees = employeesList.filter(el => !cardEmployeeIds.includes(el.id));
  const availableOffices = officesList.filter(el => !cardOfficeIds.includes(el.id));

  const sortedVersions = useMemo(() => {
    return (
      props.selectedCard?.versions?.sort(
        (a, b) => Date.parse(b.lastUpdated) - Date.parse(a.lastUpdated)
      ) || []
    );
  }, [props.selectedCard?.versions]);

  const getEmployeesFromOtherCards = async list => {
    const nonCardEmployeeIds = list.filter(id => !cardEmployeeIds.includes(id)).map(id => ({ id }));
    try {
      const nonCardEmployees = await firebaseService.withInSearch(
        nonCardEmployeeIds,
        'contacts',
        'id'
      );

      return nonCardEmployees
        .filter(({ data: { cardId } }) => !!cardId)
        .map(({ data, id }) => ({
          id,
          data: new ContactType().fromFirestoreDoc(data),
        }));
    } catch (error) {
      alert(error.toString());
    }
  };

  const getOfficesFromOtherCards = async list => {
    const nonCardOfficeIds = list.filter(id => !cardOfficeIds.includes(id)).map(id => ({ id }));
    try {
      const nonCardOffices = await firebaseService.withInSearch(nonCardOfficeIds, 'offices', 'id');

      return nonCardOffices
        .filter(({ data: { cardId } }) => !!cardId)
        .map(({ data, id }) => ({
          id,
          data: new OfficeType().fromFirestoreDoc(data),
        }));
    } catch (error) {
      alert(error.toString());
    }
  };

  const updateEmployees = async employees => {
    await Promise.all(
      employees.map(id => {
        return firebaseService.updateContact(id, {
          cardId: props.selectedCard.id,
        });
      })
    );
  };

  const updateOffices = async offices => {
    await Promise.all(
      offices.map(id => {
        return firebaseService.updateOffice(id, { cardId: props.selectedCard.id });
      })
    );
  };

  const onCloseOptionsModal = () => {
    setEmployeeIds([]);
    setOfficeIds([]);
    setAllEmployees(false);
    setAllOffices(false);
    setIsOptionsModal(false);
  };

  // universal function for both offices and employees which are assigned to card
  // it takes universal parametrs and deletes or update every item(office or employee) to assign them or remove
  const onSaveOption = async ({
    items,
    itemsData,
    isDelete,
    getItemsFromOtherCards,
    saveItem,
    optionName,
    updateOptions,
    refetchOptions,
    key,
  }) => {
    try {
      setIsLoading(true);
      if (isDelete) {
        await saveItem();
        props.onAddToastText(`${optionName} was deleted successfully`);
      } else {
        const itemsFromOtherCards = await getItemsFromOtherCards(items, itemsData);

        if (itemsFromOtherCards.length === 0) {
          await updateOptions(items);
          props.onAddToastText(`${optionName}s were assigned successfully`);
        } else {
          const message = getConfirmMessage(itemsFromOtherCards, props.selectedCard.name, key);

          if (window.confirm(message)) {
            await updateOptions(items);
            props.onAddToastText(`${optionName}s were assigned successfully`);
          } else {
            setIsLoading(false);
            return;
          }
        }
      }
      refetchOptions();
      onCloseOptionsModal();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  // function which renders prepare JSX for tab
  // it is in use for 3 first tabs where list of items is required
  const getTabContent = ({ isVersionTab, list, activeId, onActivate, onEdit, onDelete }) => {
    return (
      <div>
        {list.map(el => (
          <div className={ownStyles.tabItem} key={el.id}>
            <p>{el.title}</p>
            <div className={ownStyles.actionsBlock}>
              {isVersionTab ? (
                <>
                  {activeId === el.id ? <div className={ownStyles.active}>ACTIVE</div> : null}
                  <Button
                    type="button"
                    caption={activeId === el.id ? 'Deactivate' : 'Activate'}
                    className={ownStyles.actionItem}
                    onClick={() => onActivate(el.id)}
                    icon="ios-checkmark-circle-outline"
                  />
                  <Button
                    type="button"
                    className={ownStyles.actionItem}
                    caption="Edit"
                    onClick={() => {
                      history.push(
                        `${PATH.ORG_CARD_EDITOR}/${PATH_ACTION.EDIT}/${history.location.hash.slice(
                          1
                        )}/${el.id}`
                      );
                    }}
                    icon="ios-create-outline"
                  />
                </>
              ) : null}
              <Button
                type="button"
                disabled={activeId === el.id}
                className={`${ownStyles.actionItem} ${ownStyles.delete}`}
                caption="Remove"
                onClick={() => onDelete(el.id)}
                customIcon={
                  <Ionicon
                    icon="ios-trash-outline"
                    fontSize="14px"
                    color="#EB4444"
                    style={{ marginRight: 8 }}
                  />
                }
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const settingsTab = (
    <div className="attributes">
      {isSettingsView && (
        <>
          <div className="attribute" style={{ padding: '10px' }}>
            <div className="value">{props.selectedCard?.name || ''}</div>
            <div className="type">Card Name</div>
          </div>
          <div className="attribute" style={{ padding: '10px' }}>
            <Checkmark
              className="value"
              checked={props.selectedCard?.isDefaultCard === 'true'}
              style={{ fill: 'grey' }}
            />
            <div className="type">Default Card</div>
          </div>
        </>
      )}
      {!isSettingsView && (
        <MuiThemeProvider theme={themes.black}>
          <div className="attribute" style={{ padding: '10px' }}>
            <ValidableTextField
              label="Card Name"
              errorMessage="Please enter valid card name"
              name="name"
              autoFocus
              required
              onBlur={e => {
                setIsNameChanged(e.currentTarget.value !== props.selectedCard?.name);
              }}
            />
          </div>
          <div className="attribute" style={{ padding: '10px' }}>
            <Field name="isDefaultCard">
              {({
                field: { name, value, onChange },
                form: {
                  initialValues: { id },
                },
              }) => (
                <Checkmark
                  name={name}
                  checked={value === 'true'}
                  style={{ cursor: 'pointer' }}
                  handleClick={async () => {
                    const newStatus = `${!(value === 'true')}`;
                    const orgDefaultCardId = props.organizationStore.organization.defaultCardId;
                    const isDiffOrgDefaultCardId = orgDefaultCardId && orgDefaultCardId !== id;
                    let shouldDefaultCardBeReplaced = true;

                    if (isDiffOrgDefaultCardId && newStatus === 'true') {
                      const orgDefaultCard = await firebaseService.getCard(orgDefaultCardId);
                      shouldDefaultCardBeReplaced = window.confirm(
                        `The card ${orgDefaultCard.name} is selected as default. Do you want to change it?`
                      );
                    }
                    if (shouldDefaultCardBeReplaced) {
                      onChange(name)(newStatus);
                    }
                  }}
                />
              )}
            </Field>
            <div className="type">Default Card</div>
          </div>
        </MuiThemeProvider>
      )}
    </div>
  );

  // function which render tab based on 'tab' index property
  // it gathers parametrs for 'getTabContent' which renders JSX
  const renderTab = () => {
    switch (tab) {
      case 1: {
        return getTabContent({
          list: cardEmployeeIds
            ? cardEmployeeIds.map(id => ({
                id,
                title: cardEmployeesData[id]?.fullName || '',
              }))
            : [],
          activeId: null,
          onDelete: id => {
            if (
              window.confirm(
                `Remove ${employeesData[id]?.fullName || ''} from the card ${
                  props.selectedCard.name
                }?`
              )
            ) {
              setEmployeeIds(cardEmployeeIds.filter(addedId => addedId !== id));
              onSaveOption({
                items: cardEmployeeIds.filter(addedId => addedId !== id),
                isDelete: true,
                saveItem: () => firebaseService.updateContact(id, { cardId: null }),
                refetchOptions: refetchCardEmployees,
                optionName: 'Employee',
              });
            }
          },
        });
      }
      case 2: {
        return getTabContent({
          list: cardOfficeIds
            ? cardOfficeIds.map(id => ({ id, title: cardOfficesData[id]?.name }))
            : [],
          activeId: null,
          onDelete: id => {
            if (
              window.confirm(
                `Remove ${officesData[id]?.name || ''} from the card ${props.selectedCard.name}?`
              )
            ) {
              setEmployeeIds(cardOfficeIds.filter(addedId => addedId !== id));
              onSaveOption({
                items: cardOfficeIds.filter(addedId => addedId !== id),
                isDelete: true,
                saveItem: () => firebaseService.updateOffice(id, { cardId: null }),
                refetchOptions: refetchCardOffices,
                optionName: 'Office',
              });
            }
          },
        });
      }
      case 3: {
        return settingsTab;
      }
      default: {
        return getTabContent({
          list: sortedVersions
            ? sortedVersions.map(el => ({
                id: el.id,
                title: convertTimestamp(el.lastUpdated),
              }))
            : [],
          activeId: props.selectedCard?.activeVersionId,
          isVersionTab: true,
          onActivate: id => {
            props.onSaveCard({
              ...props.selectedCard,
              activeVersionId: props.selectedCard.activeVersionId === id ? null : id,
            });
          },
          onDelete: id => {
            const version = props.selectedCard?.versions?.find(el => el.id === id);
            const message = `Delete "${props.selectedCard?.name || ''}", "${convertTimestamp(
              version.lastUpdated
            )}"?`;
            if (window.confirm(message)) {
              props.onSaveCard({
                ...props.selectedCard,
                versions: props.selectedCard?.versions?.filter(el => el.id !== id),
              });
            }
          },
        });
      }
    }
  };

  const onClickItem = (id, ids, setIds, isAll) => {
    if (isAll) return;
    const newList = ids.includes(id) ? ids.filter(elId => elId !== id) : [...ids, id];
    setIds(newList);
  };

  const isEmployeesModal = tab === 1;
  const isOfficesModal = tab === 2;

  const getModalContent = () => {
    if (isEmployeesModal) {
      return {
        title: 'Add Employees',
        isAll: isEmployeesAll,
        list: availableEmployees,
        onAllClick: () => {
          if (isEmployeesAll) {
            setEmployeeIds([]);
          } else {
            setEmployeeIds(availableEmployees.map(employee => employee.id));
          }
          setAllEmployees(!isEmployeesAll);
        },
        onClickItem: id => onClickItem(id, employeeIds, setEmployeeIds, isEmployeesAll),
        isChecked: id => employeeIds.includes(id) || isEmployeesAll,
        getItemTitle: item => item.fullName,
        onSave: () => {
          onSaveOption({
            items: [...cardEmployeeIds, ...employeeIds],
            itemsData: employeesData,
            getItemsFromOtherCards: getEmployeesFromOtherCards,
            optionName: 'Employee',
            updateOptions: updateEmployees,
            refetchOptions: refetchCardEmployees,
            key: 'fullName',
          });
        },
      };
    }
    if (isOfficesModal) {
      return {
        title: 'Add offices',
        isAll: isOfficesAll,
        list: availableOffices,
        onAllClick: () => {
          if (!isOfficesAll) {
            setOfficeIds(availableOffices.map(office => office.id));
          } else {
            setOfficeIds([]);
          }
          setAllOffices(!isOfficesAll);
        },
        onClickItem: id => onClickItem(id, officeIds, setOfficeIds, isOfficesAll),
        isChecked: id => officeIds.includes(id) || isOfficesAll,
        getItemTitle: item => item.name,
        onSave: () => {
          onSaveOption({
            items: [...cardOfficeIds, ...officeIds],
            itemsData: officesData,
            getItemsFromOtherCards: getOfficesFromOtherCards,
            optionName: 'Office',
            updateOptions: updateOffices,
            refetchOptions: refetchCardOffices,
            key: 'name',
          });
        },
      };
    }
  };

  const modalContent = getModalContent();

  const optionsModal = !isOptionsModal ? null : (
    <OptionsModal
      onClose={() => {
        setIsOptionsModal(false);
        setSearch('');
      }}
      onSave={modalContent.onSave}
      onSearch={setSearch}
      title={modalContent.title}
      searchValue={search}
    >
      {search.length || modalContent.list.length === 0 ? null : (
        <div onClick={modalContent.onAllClick} className={styles.employeeItem}>
          <Checkmark style={{ marginRight: 10 }} checked={modalContent.isAll} />
          <div>All</div>
        </div>
      )}
      {modalContent.list.map(item => (
        <div
          onClick={() => modalContent.onClickItem(item.id)}
          className={styles.employeeItem}
          key={item.id}
        >
          <Checkmark
            style={{ marginRight: 10, fill: modalContent.isAll ? 'grey' : 'black' }}
            checked={modalContent.isChecked(item.id)}
          />
          <div>{modalContent.getItemTitle(item)}</div>
        </div>
      ))}
    </OptionsModal>
  );

  const deleteCardBtn = (
    <Button
      type="button"
      disabled={props.selectedCard?.activeVersionId}
      caption="Delete"
      style={{ marginLeft: '8px' }}
      onClick={e => {
        e.preventDefault();
        if (
          window.confirm(`Are you sure you want to delete the card "${props.selectedCard?.name}"?`)
        ) {
          try {
            setIsLoading(true);
            props.onDeleteCard();
            setIsLoading(false);
          } catch (e) {
            console.error(e);
          }
        }
      }}
      icon="ios-trash"
    />
  );

  const getSettingsBtn = values => {
    return isSettingsView ? (
      <>
        <Button
          type="button"
          style={{ marginRight: 10, marginLeft: 10 }}
          caption="Edit"
          onClick={e => {
            e.preventDefault();
            setIsSettingsView(false);
          }}
          icon="md-create"
        />
        {deleteCardBtn}
      </>
    ) : (
      <Button
        type="submit"
        disabled={!values.name}
        caption="Save"
        className={ownStyles.saveBtn}
        customIcon={
          <img
            src={diskette}
            style={{ marginRight: '10px' }}
            width="12px"
            height="12px"
            alt="diskette"
          />
        }
      />
    );
  };

  const getHeaderBtns = values => (
    <div className="header-buttons">
      {tab !== 3 ? (
        <div className={ownStyles.headerBtnsBlock}>
          <Button
            caption="Add"
            style={{ marginRight: 10, marginLeft: 10 }}
            onClick={e => {
              e.preventDefault();
              if (isNameChanged) {
                getNewNameAlert();
                return;
              }
              if (tab === 0) {
                routeToCreate();
              } else {
                setIsOptionsModal(true);
              }
            }}
            icon="md-add"
          />
          {deleteCardBtn}
          {isOptionsModal ? optionsModal : null}
        </div>
      ) : (
        getSettingsBtn(values)
      )}
      <Ionicon
        onClick={() => {
          if (isNameChanged) {
            getNewNameAlert();
            return;
          }
          props.onClose();
        }}
        icon="ios-close"
        className="close-button"
      />
    </div>
  );

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        borderLeft: '1px solid #e8e8e8',
        boxSizing: 'border-box',
      }}
    >
      <div className="panel details details-view">
        <Formik
          enableReinitialize
          initialValues={{
            name: props.selectedCard?.name || '',
            isDefaultCard: props.selectedCard?.isDefaultCard,
            id: props.selectedCard?.id,
          }}
          onSubmit={values => {
            props
              .onSaveCard(values)
              .then(() => {
                setIsSettingsView(true);
                setIsNameChanged(false);
                if (props.activeTab === 'name') {
                  routeToCreate();
                }
              })
              .catch(error => {
                alert(error.toString());
              });
          }}
        >
          {({ values, handleChange }) => (
            <Form>
              {(isLoading ||
                isEmployeesFetching ||
                isOfficesFetching ||
                isCardEmployeesFetching ||
                isCardOfficeFetching) && (
                <div className={ownStyles.loader}>
                  <Loader />
                </div>
              )}
              <div className="header">
                <p>
                  {!props.selectedCard ? 'New Card' : `Card Details: ${props.selectedCard.name}`}
                </p>
                {getHeaderBtns(values)}
              </div>
              <Tabs
                value={tab}
                onChange={(e, index) => setTab(index)}
                aria-label="scrollable auto tabs ant example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Version" disabled={!props.selectedCard} />
                <Tab label="Employees using this card" disabled={!props.selectedCard} />
                <Tab label="Offices using this card" disabled={!props.selectedCard} />
                <Tab label="Settings" />
              </Tabs>
              {renderTab()}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
});

export default memo(OrgCardDetail);
