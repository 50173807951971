import { useState, useEffect } from 'react';

import mergewith from 'lodash.mergewith';
import { toJS } from 'mobx';

import firebaseService from 'services/firebase';
import { splitStringByUppercaseCharacters, capitalizeEachWordInString } from 'utils/helpers';

let MAX_REQUESTS_COUNT = 30;

const isUpdateAvailable = (parentContact, contact) => {
  if (!parentContact || !contact) {
    return false;
  }

  return parentContact.lastUpdateTime > contact.hcInfo?.lastUpdateTime;
};

const mergeUpdatePatch = (contact = {}, updatePatch = {}) => {
  // mergewith mutates the first argument.
  const clonedContactUpdatePatch = toJS(contact.updatePatch);

  mergewith(clonedContactUpdatePatch, updatePatch, (contactItem, updatePatchItem) => {
    const { value: contactValue, lastUpdateTime: contactLastUpdateTime } = contactItem || {};
    const { value: updatePatchValue, lastUpdateTime: updatePatchLastUpdateTime } =
      updatePatchItem || {};

    if (contactLastUpdateTime > updatePatchLastUpdateTime) {
      return contactItem;
    }

    if (Array.isArray(contactValue)) {
      updatePatchValue.forEach(item => {
        let contactValueItemIndex = contactValue.findIndex(
          ({ createTime }) => item.createTime === createTime
        );

        if (contactValueItemIndex > -1) {
          contactValue[contactValueItemIndex] = item;
          if (contactValue[contactValueItemIndex].id !== undefined) {
            contactValue[contactValueItemIndex].id = contactValueItemIndex;
          }
        } else {
          contactValue.push(item);
        }
      });

      return contactItem;
    }

    return updatePatchItem;
  });

  return Object.fromEntries(
    Object.entries(clonedContactUpdatePatch).map(([patchKey, { value }]) => [[patchKey], value])
  );
};

const fetchUpdatedContact = async contact => {
  try {
    let updatedContact = await firebaseService.fetchContact(contact.id);

    if (updatedContact.lastUpdateTime === contact.lastUpdateTime && MAX_REQUESTS_COUNT) {
      MAX_REQUESTS_COUNT -= 1;
      updatedContact = await new Promise(resolve =>
        setTimeout(async () => {
          const updatedContact = await fetchUpdatedContact(contact); // onUpdate trigger is async and does not provide anything about the end of own execution

          return resolve(updatedContact);
        }, 500)
      );
    }

    return updatedContact;
  } catch (error) {
    alert(error.toString());
  }
};

const updateContactState = (contact, updatedContact) => {
  contact.hcInfo = updatedContact;
  MAX_REQUESTS_COUNT = 30;
};

const getNotificationMessage = (parentUpdatePatch = {}, updatePatch = {}) => {
  const imgsFields = ['cardBackUrl', 'cardFrontUrl', 'imageUrl'];
  const newUpdates = Object.entries(parentUpdatePatch).reduce(
    (updates, [field, { lastUpdateTime, value }]) => {
      const customizedField = capitalizeEachWordInString(splitStringByUppercaseCharacters(field));
      const parentUpdatePatchValue = imgsFields.includes(field) ? 'Image was updated.' : value;
      let diffArrayValues = [];

      if (Array.isArray(parentUpdatePatchValue)) {
        diffArrayValues = parentUpdatePatchValue.filter(
          ({ value }) =>
            updatePatch[field].value.findIndex(valueProp => valueProp.value === value) === -1
        );

        if (!diffArrayValues.length) {
          return updates;
        }
      }

      if (updatePatch[field]) {
        return lastUpdateTime > updatePatch[field].lastUpdateTime
          ? {
              ...updates,
              [customizedField]: Array.isArray(parentUpdatePatchValue)
                ? diffArrayValues
                : parentUpdatePatchValue,
            }
          : updates;
      }

      return {
        ...updates,
        [customizedField]: Array.isArray(parentUpdatePatchValue)
          ? diffArrayValues
          : parentUpdatePatchValue,
      };
    },
    {}
  );

  return (
    Object.entries(newUpdates).reduce((message, [field, fieldValue]) => {
      let newValue = fieldValue;

      if (Array.isArray(fieldValue)) {
        newValue = fieldValue.reduce((newValue, { value }) => [...newValue, value], []).join(', ');
      }

      return `${message}\n${field}: ${newValue}`;
    }, 'The owner updated the contact.\n') + '\n\nAccept changes?'
  );
};

export default contact => {
  const [shouldBeContactUpdated, setShouldBeContactUpdated] = useState(false);
  const [parentOfSharedContact, setParentOfSharedContact] = useState(null);

  useEffect(() => {
    try {
      firebaseService
        .getParentOfSharedContact(contact?.hcInfo?.sharedFromId)
        .then(parentOfSharedContact => {
          setParentOfSharedContact(parentOfSharedContact);

          if (isUpdateAvailable(parentOfSharedContact, contact)) {
            const shouldBeContactUpdated = window.confirm(
              getNotificationMessage(parentOfSharedContact.updatePatch, contact.hcInfo.updatePatch)
            );

            if (!shouldBeContactUpdated) {
              firebaseService
                .handleDeclineUpdatePatch(toJS(contact.hcInfo))
                .then(() => fetchUpdatedContact(contact.hcInfo))
                .then(updatedContact => {
                  updateContactState(contact, updatedContact);
                });
            }
            setShouldBeContactUpdated(shouldBeContactUpdated);
          }
        });
    } catch (error) {
      alert(error.toString());
    }
  }, [contact]);

  useEffect(() => {
    if (shouldBeContactUpdated) {
      const newUpdatePatch = mergeUpdatePatch(contact.hcInfo, parentOfSharedContact.updatePatch);

      firebaseService
        .updateContact(contact.id, newUpdatePatch)
        .then(() => fetchUpdatedContact(contact.hcInfo))
        .then(updatedContact => {
          updateContactState(contact, updatedContact);
          setShouldBeContactUpdated(false);
        });
    }
  }, [contact, parentOfSharedContact, shouldBeContactUpdated]);
};
