import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import FlexView from 'react-flexview';
import { inject } from 'mobx-react';
import { useInfiniteFirebaseQuery, useSharedContactUpdate } from 'global/hooks';
import { Snackbar } from 'react-md';

import { BAR_MODE } from '../../utils/constants';
import { isConfirmLooseChanges, processAlreadyCreatedContacts } from '../../utils/helpers';
import { Table, TableCellFullName } from '../../global/components/Table';
import DebounceInput from '../../global/components/DebounceInput';
import Button from '../Common/Button';

import ContactDetail from '../YourAccount/ContactDetail';
import ImportCSVScreen from '../Common/ImportCSV';
import { ContactType } from '../../Models/Contacts';
import { CardType } from '../../Models/Cards';
import OfficeType from 'Models/OfficeType';
import { parseContactsCsv } from 'Models/Contacts.utils';

import ContactStore from 'Models/Contact.store';
import firebaseService from 'services/firebase';
import firebase from 'firebase/app';

import '../Common/table.css';
import styles from '../Common/TablePage.module.css';

import { ROLES } from '../../Models/Contacts.config.js';

function getColumnValue(id, index, data) {
  return data[index]?.hcInfo?.[id];
}

const convertOrderBy = orderBy => {
  if (orderBy.field === 'hcInfo') {
    return [
      { field: 'firstName', direction: orderBy.direction },
      { field: 'lastName', direction: orderBy.direction },
    ];
  }

  return [orderBy];
};

const OrgEmployeesScreen = inject(
  'organizationStore',
  'accountStore'
)(props => {
  const [toasts, setToasts] = useState([]);
  const [search, setSearch] = useState('');

  const [orderBy, setOrderBy] = useState({ field: 'hcInfo', direction: 'asc' });
  const [showImportEmployees, setShowImportEmployees] = useState(false);
  const [selectedEmployee, setEmployee] = useState(null);
  const [isImportSuccess, setIsImportSuccess] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [barMode, setBarMode] = useState(null);

  const {
    data: employeesData,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    refetch,
    error: employeesError,
  } = useInfiniteFirebaseQuery('contacts', {
    adapter: contact => new ContactType().fromFirestoreDoc(contact),
    queryAdapter: query =>
      query.where('organizationId', '==', props.organizationStore.organization.id),
    orderBy: convertOrderBy(orderBy),
    search,
  });

  // this code hide company creator from employees list
  // const _employeesData = useMemo(() => employeesData.filter(x => x.id !== props.accountStore.id), [
  //   employeesData,
  //   props.accountStore,
  // ]);

  // employee passed from offices screen
  const passedEmployee = employeesData.find(el => el.id === props.location.state?.employeeId);

  const getEmployee = useCallback(async id => {
    if (id) {
      const employee = await firebaseService.getContact(id);

      return new ContactType().fromFirestoreDoc(employee);
    }
  }, []);

  const { data: officesList, error: officesListError } = useInfiniteFirebaseQuery('offices', {
    queryAdapter: query =>
      query.where('organizationId', '==', props.organizationStore.organization.id),
    adapter: doc => new OfficeType().fromFirestoreDoc(doc),
  });
  const { data: cardsList, error: cardsListError } = useInfiniteFirebaseQuery('cards', {
    adapter: card => new CardType().fromFirestoreDoc(card),
    queryAdapter: query =>
      query.where('organizationId', '==', props.organizationStore.organization.id),
  });

  const isEditing = barMode === BAR_MODE.EDIT || barMode === BAR_MODE.NEW;

  const officesObj = useMemo(() => {
    return (
      officesList.reduce((acc, office) => {
        return { ...acc, [office.data.id]: office.data };
      }, {}) || {}
    );
  }, [officesList]);

  const debounceInputRef = useRef();

  useSharedContactUpdate(selectedEmployee);

  useEffect(() => {
    if (employeesError || officesListError || cardsListError) {
      props.history.push('/main');
    }
  }, []);

  useEffect(() => {
    debounceInputRef.current?.focus();
  }, [debounceInputRef]);

  useEffect(() => {
    if (passedEmployee) {
      setEmployee(passedEmployee);
      setBarMode(BAR_MODE.EDIT);
    }
  }, [passedEmployee]);

  useEffect(() => {
    getEmployee(selectedEmployee?.id)
      .then(employee => {
        setEmployee(employee);
      })
      .catch(error => {
        alert(error.toString());
      });
  }, [employeesData]);

  const columns = [
    {
      Header: 'Name',
      accessor: 'hcInfo',
      Cell: cellProps =>
        TableCellFullName({
          ...cellProps,
          cardsList,
          officesData: officesObj,
          organization: props.organizationStore.organization,
        }),
    },
    { Header: 'Title', accessor: 'jobTitle' },
    {
      Header: 'Office',
      accessor: 'officeName',
    },
    {
      Header: 'Role',
      accessor: 'role',
      Cell: ({ cell, row, data }, officesObj) => {
        const key = getColumnValue(cell?.column?.id, row.index, data);
        return ROLES[key];
      },
    },
    {
      Header: 'Active',
      accessor: 'isActive',
      Cell: ({ cell, row, data }) => {
        const key = getColumnValue(cell?.column?.id, row.index, data);
        return `${key}`;
      },
    },
  ];

  const checkIfLooseChanges = () =>
    isConfirmLooseChanges({ isDirty, isEditing, onApprove: () => setIsDirty(false) });

  const dismissToast = () => {
    const [, ...remain] = toasts;
    setToasts(remain);
  };

  const setToast = message => {
    setToasts([...toasts, { text: message }]);
  };

  const clickImport = () => {
    if (checkIfLooseChanges()) {
      return;
    }
    setShowImportEmployees(true);
  };

  const clickAdd = () => {
    if (checkIfLooseChanges()) {
      return;
    }
    const contact = new ContactType();
    const { id: organizationId } = props.organizationStore.organization;

    contact.createManualContact(props.accountStore.id, organizationId);
    setEmployee(contact);
    setBarMode(BAR_MODE.NEW);
  };

  const onSaveHandler = async () => {
    setToast('Employee was saved');

    if (barMode === 'edit') {
      // Refetch only "selectedEmployee" if it is "Editing Mode"
      await refetch(selectedEmployee);
    } else {
      // Otherwise it is a new contact
      // Refetch all
      await refetch();
    }

    setIsDirty(false);
    setBarMode(BAR_MODE.VIEW);
  };

  const onCloseHandler = () => {
    setBarMode(null);
    setEmployee(null);
    setIsDirty(false);
  };

  const onRowClick = index => {
    if (checkIfLooseChanges()) {
      return;
    }

    setEmployee(employeesData[index]);
    setIsDirty(false);
    setBarMode(BAR_MODE.VIEW);
  };

  const getRelatedContacts = async (id, key) => {
    return await firebase
      .firestore()
      .collection('contacts')
      .where(key, '==', id)
      .get()
      .then(({ docs }) => docs.map(doc => doc?.data()));
  };

  const onDeleteClick = async id => {
    if (checkIfLooseChanges()) {
      return;
    }

    const contact = employeesData.find(x => x.id === id);
    const ownContacts = await getRelatedContacts(contact.hcInfo.id, 'ownerId');
    const collectedContacts = await getRelatedContacts(contact.hcInfo.id, 'collectorId');

    if (ownContacts.length > 0 || collectedContacts.length > 0) {
      window.alert(
        `The ${contact.firstName} ${contact.lastName} owns or collects some contacts! Please reassign contacts to delete employee!`
      );
      return;
    }

    if (window.confirm(`Delete ${contact.firstName} ${contact.lastName} employee?`)) {
      contact.delete().then(() => {
        setBarMode(null);
        setEmployee(null);
        setToast('Employee was deleted');
        refetch();
      });
    }
  };

  const onActivateClick = async item => {
    if (checkIfLooseChanges()) {
      return;
    }

    if (
      window.confirm(
        `${item?.isActive ? 'Deactivate' : 'Activate'} ${item.firstName} ${item.lastName} employee?`
      )
    ) {
      await firebaseService.saveContact({
        ...item,
        isActive: !item?.isActive,
      });

      setToast(`Employee was ${item?.isActive ? 'deactivated' : 'activated'}`);
      refetch({ id: item.id });
    }
  };

  const onEditClick = id => {
    if (checkIfLooseChanges()) {
      return;
    }
    getEmployee(id)
      .then(employee => {
        setEmployee(employee);
        setBarMode(BAR_MODE.EDIT);
      })
      .catch(error => {
        alert(error.toString());
      });
  };

  const onImportComplete = data => {
    if (data) {
      const { id, name } = props.organizationStore.organization;
      props.accountStore
        .importCSVContacts({
          data,
          organizationId: id,
          organizationName: name,
          existedContacts: employeesData,
        })
        .then(result => {
          setIsImportSuccess(true);
          processAlreadyCreatedContacts(
            props.organizationStore,
            props.accountStore,
            result.alreadyCreatedContacts
          ).then(isAlreadyCreatedContactsChanged => {
            const addedContactsCount = isAlreadyCreatedContactsChanged
              ? [...result.contacts, ...result.alreadyCreatedContacts].length
              : result.contacts.length;
            const alreadyExistContactsCount = !isAlreadyCreatedContactsChanged
              ? [...result.contactsDuplicate, ...result.alreadyCreatedContacts].length
              : result.contactsDuplicate.length;
            refetch();
            setToast(`${addedContactsCount} employees created.
            ${alreadyExistContactsCount} employees skipped.`);
          });
        })
        .catch(error => {
          alert(error.toString());
        });
    }

    setShowImportEmployees(false);
  };

  const actions = ({ cell: { value } }) => {
    const item = employeesData.find(x => x.id === value)?.hcInfo;
    return [
      {
        caption: 'Edit',
        onClick: onEditClick,
        icon: 'ios-create-outline',
      },
      {
        caption: item?.isActive ? 'Deactivate' : 'Activate',
        onClick: () => onActivateClick(item),
        icon: 'ios-checkmark-circle-outline',
      },
      {
        caption: 'Delete',
        onClick: onDeleteClick,
        icon: 'ios-trash-outline',
        primary: true,
      },
    ];
  };

  const isEmpty = !isFetching && !isFetchingMore && !employeesData.length && !search;

  const NoEmployees = (
    <div className={styles.noItemsContainer}>
      <p>You don&rsquo;t have employees here yet. Let&rsquo;s add some</p>

      <Button
        onClick={clickImport}
        icon="md-cloud-upload"
        caption="Import employees from .csv file"
      />
      <Button onClick={clickAdd} icon="md-add" caption="Add an employee manually" />
    </div>
  );

  return (
    <FlexView row="true" grow style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
      <FlexView column style={{ width: selectedEmployee ? '55%' : '100%', height: '100%' }}>
        <FlexView
          height={60}
          row="true"
          vAlignContent="center"
          style={{ paddingRight: 20, paddingLeft: 20, fontWeight: 'bold', minHeight: 60 }}
        >
          <FlexView>Employees</FlexView>
          <FlexView grow row="true" width={220} hAlignContent="right">
            <DebounceInput
              onChange={setSearch}
              value={search}
              ref={debounceInputRef}
              debounce={250}
              placeholder="Search"
              icon="ios-search"
              style={{ marginRight: 8, maxWidth: 400 }}
            />
            <Button onClick={clickAdd} icon="md-add" caption="Add" style={{ marginRight: 8 }} />
            <Button onClick={clickImport} icon="md-cloud-upload" caption="Import" />
          </FlexView>
        </FlexView>

        <FlexView column style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
          <Table
            columns={columns}
            onRowClick={onRowClick}
            data={employeesData}
            actions={actions}
            onFetchMore={fetchMore}
            // Temporary solution because of firebase implementation.
            // User's itself contact is available in 'employeesData' but it's hidden
            // So the length for the empty 'employeesData' is 1
            canFetchMore={canFetchMore && employeesData.length > 1}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            orderBy={orderBy}
            onOrderByClick={setOrderBy}
            highlight={search}
            isSidebarOpen={barMode}
            isImportSuccess={isImportSuccess}
            resetImportSuccess={() => setIsImportSuccess(false)}
            isEmpty={isEmpty}
            emptyMessage={NoEmployees}
            activeId={selectedEmployee?.id}
            columnsToUpdateDependency={{ isDirty, barMode, officesObj }}
          />
        </FlexView>
      </FlexView>

      {selectedEmployee && (
        <FlexView
          style={{
            width: '45%',
            height: '100%',
            backgroundColor: 'white',
            borderLeft: '1px solid #e8e8e8',
          }}
        >
          <ContactDetail
            titles={{ new: 'New employee', view: 'Employee:' }}
            onClose={() => setEmployee(null)}
            contactInfo={selectedEmployee}
            store={new ContactStore(selectedEmployee)}
            theme="detail"
            isEditing={isEditing}
            onSave={isSaved => (isSaved ? onSaveHandler() : onCloseHandler())}
            onDirtyChanged={setIsDirty}
            onEdit={onEditClick}
            createLeadHandler={async () => {
              await refetch();
            }}
            organization={props.organizationStore.organization.orgData}
            offices={{ list: officesList, data: officesObj }}
            mode={barMode}
            type="employee"
          />
        </FlexView>
      )}

      {showImportEmployees && (
        <ImportCSVScreen
          title="Import Employees"
          onComplete={onImportComplete}
          csvHeader="first_name, last_name, job_title, phone_1, phone_type_1, email_1, email_type_1, physical_address_1, physical_address_type_1, social_media_1, social_media_type_1, website_1, website_type_1"
          note="To add more phone numbers, emails, or social handles, just increment the numbers"
          csvParser={parseContactsCsv}
        />
      )}

      <FlexView
        grow
        hAlignContent="center"
        row="true"
        style={{ position: 'absolute', bottom: 0, left: 220, right: 0 }}
      >
        <Snackbar
          style={{ padding: 20, color: 'white', backgroundColor: 'rgb(6, 16, 33)', fontSize: 16 }}
          toasts={toasts}
          autoFocusAction
          autohide={true}
          onDismiss={dismissToast}
        />
      </FlexView>
    </FlexView>
  );
});

export default OrgEmployeesScreen;
