export default function (theme) {
  return {
    textField: {
      color: theme.palette.primary.main,
    },

    cssLabel: {
      '&$cssFocused': {
        color: theme.palette.primary.main,
      },
      color: theme.palette.secondary.main,
      transform: 'translate(14px, 10px) scale(1)',
      fontSize: 12,
    },

    cssOutlinedInput: {
      color: theme.palette.primary.main,
      fontSize: 14,
      '&$cssFocused $notchedOutline': {
        borderWidth: '1px',
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },

    cssFocused: {
      color: theme.palette.primary.main,
    },

    input: {
      padding: '7px 10px',
      overflow: 'hidden',
    },

    notchedOutline: {
      borderWidth: '1px',
      borderColor: `${theme.palette.secondary.main} !important`,
    },

    cssMultilineInput: {
      padding: '0 14px 0 0',
    },

    menuPaper: {
      maxHeight: '300px',
    },
  };
}
