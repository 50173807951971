import { useEffect, useMemo, useState } from 'react';

import firebase from 'firebase/app';

import firebaseService from 'services/firebase';
import { CardType } from '../../Models/Cards';

export default (contact, offices) => {
  const [assignedCard, setAssignedCard] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const officeCardId = offices?.data?.[contact?.officeId]?.cardId || null;
  const contactCardId = contact?.hcInfo?.cardId || null;
  const defaultCardId = organization?.defaultCardId || null;

  const getCard = async id => {
    return await firebase
      .firestore()
      .collection('cards')
      .doc(id)
      .get()
      .then(doc => {
        const data = doc?.data();
        return new CardType().fromFirestoreDoc(data);
      });
  };

  const getOrganization = (id, name) => {
    if (id) {
      return firebaseService.getOrganization(id);
    }
    if (name) {
      return firebaseService.getOrganizationByName(name);
    }
  };

  const activeCardVersion = useMemo(() => {
    return assignedCard?.versions?.find(el => el.id === assignedCard?.activeVersionId);
  }, [assignedCard]);

  // it is possible to return it too if it is needed
  const contactOffice = offices?.data[contact?.officeId];
  const cardObjects = getCardObjects(activeCardVersion, contact, contactOffice, organization);

  const getItems = async (cardId, orgId, orgName) => {
    try {
      setIsLoading(true);
      const result = await Promise.all([
        ...(cardId ? [getCard(cardId)] : []),
        ...(orgId || orgName ? [getOrganization(orgId, orgName)] : []),
      ]);

      if (cardId && (orgId || orgName)) {
        setAssignedCard(result[0]);
        setOrganization(result[1]);
      }
      if (!cardId && (orgId || orgName)) {
        setAssignedCard(null);
        setOrganization(result[0]);
      }
      if (!(orgId || orgName) && cardId) {
        setOrganization(null);
        setAssignedCard(result[0]);
      }
      if (!(orgId || orgName) && !cardId) {
        setAssignedCard(null);
        setOrganization(null);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const cardId = contactCardId || officeCardId || defaultCardId || null;
    getItems(cardId, contact?.organizationId, contact?.organization);

    return () => {};
  }, [contactCardId, officeCardId, defaultCardId, contact]);

  return [activeCardVersion, cardObjects, contactOffice, isLoading];
};

function getCardObjects(cardVersion, contact, contactOffice, contactOrganization) {
  const getters = getCardObjectsDataGetters(contact, contactOffice, contactOrganization);

  cardVersion?.cardObjects.forEach(cardObject => {
    getters[cardObject.originType]?.(cardObject);
  });

  return getters.cardObjectsData;
}

function getCardObjectsDataGetters(contact, contactOffice, contactOrganization) {
  return {
    _cardObjectsData: [],
    User(cardObject) {
      if (cardObject?.key.includes('user')) {
        const keyPart = cardObject.key.replace('user-', '');

        if (keyPart === 'name') {
          const contactName = `${contact.firstName} ${contact.lastName}`;

          this._cardObjectsData.push({ ...cardObject, title: contactName });
        }

        if (keyPart === 'website') {
          const websites = contact.hcInfo.websites
            .filter(({ type }) => type === 'Web')
            .map(({ value }) => value)
            .join();

          this._cardObjectsData.push({ ...cardObject, title: websites });
        }

        if (keyPart.includes('Physical Address')) {
          const addressKeyPart = keyPart.replace('Physical Address - ', '');
          const addressObj = contact.hcInfo.physicalAddresses.find(
            ({ type }) => type === addressKeyPart
          );
          if (addressObj) {
            this._cardObjectsData.push({ ...cardObject, title: addressObj.value });
          }
        }

        if (contact.hcInfo[keyPart]) {
          this._cardObjectsData.push({ ...cardObject, title: contact.hcInfo[keyPart] });
        }

        if (contact.hcInfo[cardObject?.key]) {
          this._cardObjectsData.push({
            ...cardObject,
            title: `${cardObject.title}: ${contact.hcInfo[cardObject?.key]}`,
          });
        }
      }

      if (cardObject?.key.includes('phone-')) {
        const keyPart = cardObject.key.replace('phone-', '');
        const phoneObj = contact.hcInfo.phoneNumbers.find(({ type }) => type === keyPart);
        if (phoneObj) {
          this._cardObjectsData.push({ ...cardObject, title: phoneObj.value });
        }
      }

      if (cardObject?.key.includes('email-')) {
        const keyPart = cardObject?.key.replace('email-', '');
        const emailObj = contact.hcInfo.emailAddresses.find(({ type }) => type === keyPart);
        if (emailObj) {
          this._cardObjectsData.push({ ...cardObject, title: emailObj.value });
        }
      }

      if (cardObject?.key.includes('social-')) {
        const keyPart = cardObject?.key.replace('social-', '');
        const socialObj = contact.hcInfo.socialMedia.find(({ type }) => type === keyPart);
        if (socialObj) {
          this._cardObjectsData.push({ ...cardObject, title: socialObj.value });
        }
      }
    },
    Organization(cardObject) {
      if (contactOrganization && cardObject?.key.includes('org-')) {
        const keyPart = cardObject?.key.replace('org-', '');
        if (contactOrganization[keyPart]) {
          this._cardObjectsData.push({
            ...cardObject,
            title: contactOrganization[keyPart],
          });
        }
      }
    },
    Office(cardObject) {
      if (contactOffice && cardObject?.key.includes('office-')) {
        const keyPart = cardObject?.key.replace('office-', '');

        if (keyPart.includes('website')) {
          this._cardObjectsData.push({
            ...cardObject,
            title: contactOffice['website'],
          });
        }

        if (contactOffice[keyPart]) {
          this._cardObjectsData.push({
            ...cardObject,
            title: contactOffice[keyPart],
          });
        }
      }
    },
    get cardObjectsData() {
      return this._cardObjectsData;
    },
  };
}
