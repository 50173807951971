import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Ionicon from 'react-ionicons';
import FlexView from 'react-flexview/lib';

import { withAuth0 } from '@auth0/auth0-react';
import firebaseService from 'services/firebase';

@inject('accountStore')
@observer
class LinkedInSelection extends Component {
  constructor(props) {
    super(props);

    this.handleAuthorization = this.handleAuthorization.bind(this);
  }

  handleFailure = error => {
    console.log('error', error);
  };

  async handleAuthorization() {
    await this.props.auth0.loginWithPopup();

    await firebaseService.signInWithCustomToken(this.props.auth0.user.sub);

    this.props.onLogin(this.props.auth0.user);
  }

  render() {
    return (
      <button
        style={{
          backgroundColor: '#0077B5',
          borderRadius: 15,
          paddingLeft: 16,
          paddingRight: 16,
          margin: 0,
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
        }}
        onClick={this.handleAuthorization}
      >
        <FlexView hAlignContent="center" vAlignContent="center" height="30px">
          <Ionicon icon="logo-linkedin" fontSize="15px" color="white" />
          <FlexView style={{ marginLeft: 10, color: 'white' }}>{this.props.buttonText}</FlexView>
        </FlexView>
      </button>
    );
  }
}

export default withAuth0(LinkedInSelection);
