import { object, string } from 'yup';

import { PHONE_NUMBER_PATTERN } from 'global/validation';
import { toAbsoluteUrl } from 'global/string.utils';

export default object().shape({
  name: string().required(),
  emailAddress: string().email(),
  phoneNumber: string().matches(PHONE_NUMBER_PATTERN),
  faxNumber: string().matches(PHONE_NUMBER_PATTERN),
  website: string().transform(toAbsoluteUrl).url(),
});
