import React, { memo, useState } from 'react';
import Ionicon from 'react-ionicons';
import { useHistory } from 'react-router-dom';
import { inject } from 'mobx-react';
import cn from 'classnames';

import IconDiskette from 'assets/icons/diskette.svg';
import Loader from 'global/components/Loader';
import Button from '../../Common/Button';
import { FILE_TYPES } from 'utils/constants';
import { getFontFormatAndName } from 'utils/helpers';
import { CARD_EDITOR_STEP } from '../constants';
import FileUploadButton from '../../Common/FileUploadButton';
import styles from './CardEditorHeader.module.css';

const CardEditorHeader = ({ card, onSaveCard, organizationStore }) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const onFileInputChange = async ({ target }) => {
    const file = target.files[0];
    const { format } = getFontFormatAndName(file);

    if (file && FILE_TYPES.FONT.includes(format)) {
      setIsLoading(true);
      const isUploadedSuccess = await organizationStore.organization.uploadOrgFont({
        font: file,
        organizationId: card.organizationId,
      });
      setIsLoading(false);
      alert(isUploadedSuccess ? 'Font uploaded successfully' : 'Current font already exist');
    } else {
      alert('Please upload valid font file');
    }

    target.value = null;
  };

  return (
    <>
      <header className={styles.root}>
        <div className={styles.leftSide}>
          <Ionicon icon="md-arrow-round-back" className={styles.goBackIcon} onClick={goBack} />
          {card.isEditing ? 'Editing' : 'New'} version: {card.name}{' '}
          <span className={styles.subTitle}> &gt; {card.side} of Card</span>
        </div>

        <div className={styles.rightSide}>
          <FileUploadButton
            caption="Import font"
            className={cn('button white', styles.importBtn)}
            accept={FILE_TYPES.FONT}
            onChange={onFileInputChange}
          />
          <Button
            caption="Save"
            className={cn('button green')}
            customIcon={<img src={IconDiskette} alt="diskette" className={styles.saveBtnIcon} />}
            disabled={card.step === CARD_EDITOR_STEP.CROP_IMAGE}
            onClick={onSaveCard}
          />
        </div>

        {isLoading && (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        )}
      </header>
    </>
  );
};

export default inject('organizationStore')(memo(CardEditorHeader));
