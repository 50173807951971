import React, { useCallback, useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import Ionicon from 'react-ionicons';
import { MuiThemeProvider } from '@material-ui/core';

import { useContactCardVersion } from 'global/hooks';
import Button from '../Common/Button';
import { SECTIONS, ROLES } from 'Models/Contacts.config';
import ContactNotes from './ContactNotes';
import themes from '../Common/themes';
import Loader from 'global/components/Loader';
import { getHcInfo } from './EditContactDetail';
import { createContact } from 'services/hubspot';
import ViewContactImages from './ViewContactImages';
import { CARD_CONTAINER_SIZE_LABELS } from 'utils/constants';

const CARD_CONTAINER = {
  [CARD_CONTAINER_SIZE_LABELS.DEFAULT]: { width: 215, height: 120 },
  [CARD_CONTAINER_SIZE_LABELS.NON_STANDARD]: { width: 215, height: 215 },
};

const renderSections = ({ contact, onEditClicked, onSaveNotes, organization, contactOffice }) => {
  const hcInfo = getHcInfo({ hcInfo: contact.hcInfo, organization, office: contactOffice });
  return SECTIONS.map((section, index) => (
    <section key={section.id}>
      <header>
        <h2>{section.name}</h2>
        <Button onClick={() => onEditClicked(index)} caption="Add" icon="md-add" />
      </header>

      <div className="section-attributes">
        {hcInfo[section.id].map((item, index) => (
          <div className="view-contact-attribute" key={index}>
            <Ionicon
              className="icon"
              icon={section.icon || section.getIcon(item.type)}
              fontSize="24px"
            />
            <div>
              {section.getLink ? (
                <a
                  className="value"
                  href={section.getLink(item.value)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.value}
                </a>
              ) : (
                <div className="value" title={item.value}>
                  {item.value}
                </div>
              )}
              <div className="type">{item.type}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  ));
};

export default function ViewContactDetail(props) {
  const [cardVersion, cardObjects, contactOffice, isLoading] = useContactCardVersion(
    props.contact,
    props.offices
  );
  const [isHubspotLoader, setIsHubspotLoader] = useState(false);
  const [isAuthorizedToHubspot, setIsAuthorizedToHubspot] = useState(false);
  const organization = props.organization || {};

  useEffect(() => {
    const { hubSpot } = organization;

    setIsAuthorizedToHubspot(!!hubSpot);
  }, [organization]);

  const createHubspotContact = useCallback(async () => {
    try {
      setIsHubspotLoader(true);
      await createContact(props.contact.hcInfo.id, organization.id);
      await props.onCreateLead();
      alert('A new Contact in Hubspot was created successfully');
    } catch (error) {
      alert(error);
    } finally {
      setIsHubspotLoader(false);
    }
  }, [props.contact, organization]);

  if (isLoading) {
    return (
      <div className={`loader-wrapper ${props.type !== 'profile' ? 'loader-wrapper-black' : ''}`}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={`panel contact contact-${props.theme}`}>
      <div className="header">
        <p>{props.title}</p>
        <div className="header-buttons">
          {props.isHubspotBtnVisible && organization.id && (
            <div className="hubspot-button mr-15">
              {isHubspotLoader ? (
                <Loader className="smallGrey" />
              ) : (
                <Button
                  caption="Create Contact"
                  title={
                    props.contact.hcInfo.hubspotId
                      ? 'Contact is already created in HubSpot'
                      : isAuthorizedToHubspot
                      ? 'Create a new Contact in HubSpot'
                      : 'No connection. Go to Admin'
                  }
                  onClick={createHubspotContact}
                  className="button hubspot"
                  disabled={!!props.contact.hcInfo.hubspotId || !isAuthorizedToHubspot}
                />
              )}
            </div>
          )}
          <Button onClick={() => props.onEditClicked(null)} caption="Edit" icon="md-create" />
          {props.onClose && (
            <Ionicon onClick={props.onClose} icon="ios-close" className="close-button" />
          )}
        </div>
      </div>

      <div className="primary-attributes">
        <ViewContactImages
          cardVersion={cardVersion}
          contact={props.contact}
          cardObjects={cardObjects}
          cardContainer={CARD_CONTAINER}
        />

        <div className="text-fields">
          <FlexView
            row="true"
            style={{
              color: props.theme === 'you' ? 'white' : 'black',
              fontSize: 18,
              fontWeight: 'bold',
            }}
          >
            <FlexView>{props.contact.firstName}</FlexView>
            <FlexView style={{ marginLeft: 4 }}>{props.contact.lastName}</FlexView>
          </FlexView>

          <FlexView
            row="true"
            style={{ color: props.theme === 'you' ? 'white' : 'black', fontSize: 14 }}
          >
            <FlexView>{props.contact.jobTitle}</FlexView>
            {props.contact.organization && props.contact.jobTitle && (
              <>
                <FlexView style={{ paddingLeft: 4, paddingRight: 4 }}>at</FlexView>
                <FlexView>{props.contact.organization}</FlexView>
              </>
            )}
          </FlexView>
        </div>
      </div>
      {props.type === 'employee' && (
        <FlexView
          row="true"
          className="section-attributes admin-settings"
          style={{ minHeight: 'auto', justifyContent: 'space-between' }}
        >
          <div className="view-contact-attribute" style={{ display: 'block' }}>
            <div className="value" title="Office">
              {contactOffice?.name || ''}
            </div>
            <div className="type">Office</div>
          </div>
          <div className="view-contact-attribute" style={{ display: 'block' }}>
            <div className="value" title="Role">
              {ROLES[props.contact.hcInfo.role]}
            </div>
            <div className="type">Role</div>
          </div>
        </FlexView>
      )}
      {props.type === 'orgContact' && (
        <FlexView
          row="true"
          className="section-attributes admin-settings"
          style={{ minHeight: 'auto', justifyContent: 'space-between' }}
        >
          <div className="view-contact-attribute" style={{ display: 'block' }}>
            <div className="value" title="Owner">
              {props.contact.ownerName}
            </div>
            <div className="type">Contact Owner</div>
          </div>
          <div className="view-contact-attribute" style={{ display: 'block' }}>
            <div className="value" title="Collector">
              {props.contact.collectorName}
            </div>
            <div className="type">Contact Collected By</div>
          </div>
        </FlexView>
      )}

      <div className="secondary-attributes">
        {renderSections({ ...props, contactOffice })}
        {props.theme === 'detail' && (
          <MuiThemeProvider theme={themes.black}>
            <ContactNotes notes={props.contact.hcInfo.notes} onSave={props.onSaveNotes} />
          </MuiThemeProvider>
        )}
      </div>
    </div>
  );
}
