import React from 'react';

import { MuiThemeProvider } from '@material-ui/core/styles';

import themes from './themes';
import HCTextEdit from './HCTextEdit';

export default function HCTextEditBlack(props) {
  return (
    <MuiThemeProvider theme={themes.black}>
      <HCTextEdit {...props} />
    </MuiThemeProvider>
  );
}
