import React from 'react';
import Ionicon from 'react-ionicons';
import styles from './TableCellActions.module.css';

const TableCellActions = (actions, { cell: { value } }, isSidebarOpen) => (
  <div className={styles.actions}>
    {actions
      .filter(x => !x.hide)
      .map((action, i) => (
        <div
          className={`${styles.action} ${action.primary ? styles.primary : ''} ${
            isSidebarOpen ? styles.limited : ''
          }`}
          key={i}
          onClick={e => {
            e.stopPropagation();
            action.onClick(value);
          }}
        >
          <Ionicon
            icon={action.icon}
            fontSize="14px"
            color={action.primary ? '#EB4444' : 'black'}
          />
          <span>{action.caption}</span>
        </div>
      ))}
  </div>
);

export default TableCellActions;
