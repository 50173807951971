import React, { useState, useCallback, useEffect, useReducer, memo, useMemo } from 'react';
import cn from 'classnames';
import { Prompt } from 'react-router-dom';
import isEqual from 'lodash.isequal';

import firebaseService from 'services/firebase';
import Loader from 'global/components/Loader';
import { checkHasFormErrors, getCardVersion, getCardSizeByAspectRatio } from './helpers';
import { CARD_EDITOR_STEP, INITIAL_CARD_CROPPED_IMAGES, OMIT_VERSION_FIELDS } from './constants';
import { omit, getFieldFontSizeCoefficient } from 'utils/helpers';
import { PATH, CARD_SIDE, DEFAULT_ASPECT_RATIO } from 'utils/constants';
import ImageCropper from './ImageCropper';
import AddDataFields from './AddDataFields';
import CardEditorHeader from './CardEditorHeader';
import { cardFieldsReducer } from './CardEditorReducers';
import styles from './CardEditor.module.css';

const CardEditor = ({ match, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isGridVisible, setGridVisibility] = useState(false);
  const [step, setStep] = useState(CARD_EDITOR_STEP.ADD_FIELDS);
  const [imageFile, setImageFile] = useState(null);
  const [croppedImages, setCroppedImages] = useState(INITIAL_CARD_CROPPED_IMAGES);
  const [cardSide, setCardSide] = useState(CARD_SIDE.FRONT);
  const [currentCard, setCurrentCard] = useState(null);
  const [cardAspectRatio, setCardAspectRatio] = useState(DEFAULT_ASPECT_RATIO);
  const [cardBorderRadius, setCardBorderRadius] = useState(0);
  const [cardBorderRadiusProportion, setCardBorderRadiusProportion] = useState({ x: 0, y: 0 });
  const [cardSize, setCardSize] = useState(getCardSizeByAspectRatio({ cardAspectRatio }));
  const [savedCardSize, setSavedCardSize] = useState(cardSize);
  const [fontSizeCoefficient, setFontSizeCoefficient] = useState(1);
  const [cardFields, onDispatchCardFields] = useReducer(cardFieldsReducer, []);
  const { cardId = null, versionId = null } = match.params;
  const isShowLeaveAlert = useMemo(() => {
    const initialVersion = omit(
      currentCard?.versions?.find(({ id }) => id === +versionId),
      OMIT_VERSION_FIELDS
    );
    const currentVersion = omit(
      getCardVersion({
        croppedImages,
        cardFields,
        versionId,
        cardAspectRatio,
        initialVersion,
        cardBorderRadius,
        cardBorderRadiusProportion,
        cardSize,
      }),
      OMIT_VERSION_FIELDS
    );

    return !versionId || !isEqual(initialVersion, currentVersion);
  }, [currentCard, cardFields, cardAspectRatio, versionId, croppedImages]);

  useEffect(() => {
    const getCard = async () => {
      const card = await firebaseService.getCard(cardId);

      if (card) {
        setCurrentCard(card);

        if (versionId) {
          const {
            cardObjects,
            frontUrl,
            backUrl,
            aspectRatio,
            borderRadius = 0,
            borderRadiusProportion = { x: 0, y: 0 },
            cardSize,
          } = card.versions?.find(({ id }) => id === +versionId) || {};
          const preparedCardFields =
            cardObjects?.map(field => (field.side ? field : { ...field, side: CARD_SIDE.FRONT })) ||
            [];

          setCardBorderRadius(borderRadius);
          setCardBorderRadiusProportion(borderRadiusProportion);
          onDispatchCardFields({ type: 'addMoreThenOne', payload: preparedCardFields });
          setCroppedImages({
            [CARD_SIDE.FRONT]: frontUrl ? { url: frontUrl } : null,
            [CARD_SIDE.BACK]: backUrl ? { url: backUrl } : null,
          });

          if (aspectRatio && aspectRatio !== DEFAULT_ASPECT_RATIO) {
            setCardAspectRatio(aspectRatio);
          }

          if (cardSize) {
            setSavedCardSize(cardSize);
          }
        }
      }
    };

    if (cardId) {
      getCard();
    }
  }, [cardId, versionId]);

  const handleCardSizeChange = useCallback(() => {
    setCardSize(getCardSizeByAspectRatio({ cardAspectRatio }));
  }, [cardAspectRatio]);

  useEffect(() => {
    handleCardSizeChange();
  }, [handleCardSizeChange]);

  useEffect(() => {
    setFontSizeCoefficient(getFieldFontSizeCoefficient(cardSize, savedCardSize));
    setSavedCardSize(cardSize);
  }, [savedCardSize, cardSize]);

  const handleLeaveAlertMessage = (location, action) =>
    (location.pathname === `/${PATH.MAIN.ORGANIZATION_CARDS}` && action === 'PUSH') ||
    'You have unsaved changes, are you sure you want to leave?';

  const handleToggleGridVisibility = useCallback(() => {
    setGridVisibility(prevIsGridVisible => !prevIsGridVisible);
  }, []);

  const handleUploadImageFile = useCallback(image => {
    setImageFile(image);
    setStep(CARD_EDITOR_STEP.CROP_IMAGE);
  }, []);

  const handleCancelUploadImage = useCallback(() => {
    setImageFile(null);
    setStep(CARD_EDITOR_STEP.ADD_FIELDS);
  }, []);

  const handleSaveCroppedImage = useCallback(
    ({ imageObj, borderRadius, borderRadiusProportion, aspectRatio }) => {
      setCardBorderRadius(borderRadius);
      setCardBorderRadiusProportion(borderRadiusProportion);
      setCroppedImages(prevCroppedImages => ({ ...prevCroppedImages, [cardSide]: imageObj }));
      setImageFile(null);
      setStep(CARD_EDITOR_STEP.ADD_FIELDS);

      if (cardSide === CARD_SIDE.FRONT) {
        setCardAspectRatio(aspectRatio);
      }
    },
    [cardSide]
  );

  const handleChangeCardSide = useCallback(() => {
    setCardSide(prevSide => (prevSide === CARD_SIDE.FRONT ? CARD_SIDE.BACK : CARD_SIDE.FRONT));
  }, []);

  const handleAspectRatioChange = useCallback(newCardAspectRatio => {
    setCardAspectRatio(newCardAspectRatio);
  }, []);

  const handleRemoveImageAndFields = useCallback(() => {
    setImageFile(null);
    setCroppedImages(prevCroppedImages => ({ ...prevCroppedImages, [cardSide]: null }));
    onDispatchCardFields({
      type: 'removeByFieldAndValue',
      payload: { field: 'side', value: cardSide },
    });

    if (cardSide === CARD_SIDE.FRONT) {
      setCardAspectRatio(DEFAULT_ASPECT_RATIO);
      setCardBorderRadius(0);
      setCardBorderRadiusProportion({ x: 0, y: 0 });
    }
  }, [cardSide]);

  const handleSaveCard = async () => {
    if (checkHasFormErrors({ cardFields, cardHeight: cardSize.height })) {
      alert('Please enter valid data for all fields');
    } else {
      const { initialVersion = {}, anotherVersions = [] } =
        currentCard.versions?.reduce(
          (acc, version) => {
            if (version.id === +versionId) {
              acc.initialVersion = version;
            } else {
              acc.anotherVersions.push(version);
            }
            return acc;
          },
          { anotherVersions: [], initialVersion: {} }
        ) || {};
      let { url: frontUrl = '', blob: frontBlob } = croppedImages[CARD_SIDE.FRONT] || {};
      let { url: backUrl = '', blob: backBlob } = croppedImages[CARD_SIDE.BACK] || {};

      setIsLoading(true);

      if (frontBlob) {
        frontUrl = await firebaseService.uploadImage(1, frontBlob);
      }
      if (backBlob) {
        backUrl = await firebaseService.uploadImage(1, backBlob);
      }

      const newCardVersion = getCardVersion({
        versionId,
        cardFields,
        initialVersion,
        cardAspectRatio,
        cardBorderRadius,
        cardBorderRadiusProportion,
        croppedImages: { [CARD_SIDE.FRONT]: { url: frontUrl }, [CARD_SIDE.BACK]: { url: backUrl } },
        cardSize,
      });
      const updatedCard = { ...currentCard, versions: [...anotherVersions, newCardVersion] };

      await firebaseService.saveCard(updatedCard.id, updatedCard);
      setIsLoading(false);
      history.push(`/${PATH.MAIN.ORGANIZATION_CARDS}#${cardId}`);
    }
  };

  return (
    <div className={styles.root}>
      <CardEditorHeader
        card={{
          side: cardSide,
          name: currentCard?.name,
          organizationId: currentCard?.organizationId,
          isEditing: !!versionId,
          step,
        }}
        onSaveCard={handleSaveCard}
      />
      <div
        className={cn(styles.main, {
          [styles.backRotate]: cardSide === CARD_SIDE.BACK,
          [styles.frontRotate]: cardSide === CARD_SIDE.FRONT,
        })}
      >
        {step === CARD_EDITOR_STEP.ADD_FIELDS && (
          <AddDataFields
            isGridVisible={isGridVisible}
            croppedImages={croppedImages}
            cardSide={cardSide}
            cardSize={cardSize}
            fontSizeCoefficient={fontSizeCoefficient}
            cardFields={cardFields}
            cardBorderRadius={cardBorderRadius}
            cardBorderRadiusProportion={cardBorderRadiusProportion}
            onDispatchCardFields={onDispatchCardFields}
            onUploadImageFile={handleUploadImageFile}
            onChangeCardSide={handleChangeCardSide}
            onRemoveImageAndFields={handleRemoveImageAndFields}
            onToggleGridVisibility={handleToggleGridVisibility}
            onAspectRatioChange={handleAspectRatioChange}
            onCardSizeChange={handleCardSizeChange}
          />
        )}

        {step === CARD_EDITOR_STEP.CROP_IMAGE && imageFile && (
          <ImageCropper
            imageFile={imageFile}
            cardSide={cardSide}
            cardAspectRatio={cardAspectRatio}
            cardBorderRadius={cardBorderRadius}
            cardBorderRadiusProportion={cardBorderRadiusProportion}
            onSaveCroppedImage={handleSaveCroppedImage}
            onCancelUploadImage={handleCancelUploadImage}
            onAspectRatioChange={handleAspectRatioChange}
          />
        )}
      </div>

      {isLoading && (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      )}

      <Prompt when={isShowLeaveAlert} message={handleLeaveAlertMessage} />
    </div>
  );
};

export default memo(CardEditor);
