import { FONT_STYLE, FONT_STYLE_KEY, DEFAULT_FONTS, DEFAULT_ASPECT_RATIO } from './constants';
import {
  DEFAULT_FONT_STYLE,
  CARD_FIELD_MIN_FONT_SIZE,
  DEFAULT_FONT,
} from '../Screens/CardEditor/constants';
import { LIFECYCLE_STAGE_OPTIONS, LEAD_STATUS_OPTIONS } from './constants';
import firebaseService from 'services/firebase';
import { ROLES } from 'Models/Contacts.config';

const ROLES_KEYS = Object.keys(ROLES);

export const isConfirmLooseChanges = ({ isDirty, isEditing, onApprove }) => {
  if (isDirty && isEditing) {
    const isApproved = window.confirm(
      'All unsaved changes will be lost. Are you sure you want to continue?'
    );

    if (isApproved) {
      onApprove();
    }

    return !isApproved;
  }
};

export const debounce = (cb, time) => {
  let isWaitingToExecute = false;
  let timer = null;

  return (...args) => {
    if (isWaitingToExecute) {
      return;
    }

    cb.apply(this, args);

    isWaitingToExecute = true;
    clearTimeout(timer);

    timer = setTimeout(() => (isWaitingToExecute = false), time);
  };
};

export const isColor = strColor => {
  const option = new Option().style;
  option.color = strColor;

  return option.color !== '';
};

export const appendFont = ({ fontUrl, fontName = 'CustomCardFont', format = 'woff2' }) => {
  const style = document.createElement('style');

  style.innerHTML = `
    @font-face {
      font-family: '${fontName}';
      src: url('${fontUrl}') format('${format}');
    }
  `;

  document.head.appendChild(style);
};
export const getListItemsIdsByKeys = (list, keys, value) =>
  list.reduce((acc, item) => {
    const itemValue = keys.reduce((value, key) => value[key], item);

    if (itemValue !== value) return acc;
    return [...acc, item.id];
  }, []);

export const getObjectFromArrayByKey = (arr, key) =>
  arr.reduce((acc, el) => {
    return { ...acc, [el[key]]: el };
  }, {});

export const getPercentFromNumber = (value, from) => {
  return Math.round((value * 100) / from);
};

export const getNumberFromPercent = (percent, from) => {
  return Math.round((percent * from) / 100);
};

export const getFontWeightAndStyle = value => {
  let fontStyleValue = FONT_STYLE[FONT_STYLE_KEY.REGULAR];
  let fontWeightValue = FONT_STYLE[FONT_STYLE_KEY.REGULAR];

  if (value === FONT_STYLE_KEY.BOLD) {
    fontWeightValue = FONT_STYLE[FONT_STYLE_KEY.BOLD];
  }
  if (value === FONT_STYLE_KEY.ITALIC) {
    fontStyleValue = FONT_STYLE[FONT_STYLE_KEY.ITALIC];
  }
  if (value === FONT_STYLE_KEY.BOLD_ITALIC) {
    fontStyleValue = FONT_STYLE[FONT_STYLE_KEY.ITALIC];
    fontWeightValue = FONT_STYLE[FONT_STYLE_KEY.BOLD];
  }

  return { fontStyle: fontStyleValue, fontWeight: fontWeightValue };
};

export const omit = (object, omitFields) =>
  object && omitFields
    ? Object.fromEntries(Object.entries(object).filter(([key]) => !omitFields.includes(key)))
    : {};

export const getFileMimeType = file => (file?.type ? file.type.split('/')[1] : '');

export const getFontFormatAndName = fontFile => {
  if (fontFile?.name) {
    const words = fontFile.name.split('.');

    return {
      name: words.slice(0, -1).join('').replace(/-/g, '').replace(/\s+/g, ' '), // remove all hyphen and extra spaces
      format: words.slice(-1).join(''),
    };
  }
  return { name: '', format: '' };
};

export const getCardSizeByAspectRatioAndSideSize = ({
  aspectRatio = DEFAULT_ASPECT_RATIO,
  containerSize = { width: 100, height: 100 },
}) => {
  const width = aspectRatio >= 1 ? containerSize.width : containerSize.height * aspectRatio;
  const height = aspectRatio < 1 ? containerSize.height : containerSize.width / aspectRatio;

  return { width, height };
};

export const getFieldFontStyles = ({
  fontSize = CARD_FIELD_MIN_FONT_SIZE,
  fontStyle = DEFAULT_FONT_STYLE,
  font = DEFAULT_FONT,
  fontSizeCoefficient = 1,
}) => {
  const { fontStyle: fieldFontStyle, fontWeight } = getFontWeightAndStyle(fontStyle);
  const fieldFontSize = fontSize * fontSizeCoefficient;
  const fieldFont = `${DEFAULT_FONTS[font] || `"${font}"`}`;

  return `${fieldFontStyle} ${fontWeight} ${fieldFontSize}px ${fieldFont}`;
};

export const getFieldFontSizeCoefficient = (cardSize, prevCardSize) => {
  // https://stackoverflow.com/a/48234046
  const widthCoefficient =
    cardSize && prevCardSize ? getPercentFromNumber(cardSize.width, prevCardSize.width) / 100 : 1;

  const heightCoefficient =
    cardSize && prevCardSize ? getPercentFromNumber(cardSize.height, prevCardSize.height) / 100 : 1;

  return Math.min(widthCoefficient, heightCoefficient);
};

export const getContactCardImages = ({
  cardFrontUrl,
  cardBackUrl,
  frontUrl,
  backUrl,
  hasAssignedCard,
}) => {
  const isCardUploadedByUser = (!!cardFrontUrl || !!cardBackUrl) && !hasAssignedCard;
  let frontOfTheCard = '';
  let backOfTheCard = '';

  if (isCardUploadedByUser) {
    frontOfTheCard = cardFrontUrl;
    backOfTheCard = cardBackUrl;
  }
  if (!isCardUploadedByUser && hasAssignedCard) {
    frontOfTheCard = frontUrl;
    backOfTheCard = backUrl;
  }

  return { frontOfTheCard, backOfTheCard, isCardUploadedByUser };
};

export const getInitials = (firstName, lastName) => `${firstName?.[0] || ''}${lastName?.[0] || ''}`;

export const getHubspotConfigOptions = id => {
  switch (id) {
    case 'lifecycleStage':
      return LIFECYCLE_STAGE_OPTIONS;
    case 'leadStatus':
      return LEAD_STATUS_OPTIONS;
    default:
      return [];
  }
};

export const splitStringByUppercaseCharacters = (str = '') =>
  str.replace(/(?=[A-Z])/, word => ` ${word}`);

export const capitalizeEachWordInString = (str = '') =>
  str.replace(/(\b[a-z](?!\s))/g, word => word.toUpperCase());

export const processAlreadyCreatedContacts = async ({ organization }, ownerContact, contacts) => {
  const getPromptMessage = contacts => {
    const contactsFullName = contacts.map(({ firstName, lastName }) => `${firstName} ${lastName}`);

    return `${contactsFullName.join(
      ',\n'
    )}\nalready exist. Do you want to add them to the organization?`;
  };

  if (contacts.length) {
    try {
      const shouldBeContactsOrgChanged = window.confirm(getPromptMessage(contacts));

      if (shouldBeContactsOrgChanged) {
        await firebaseService.updateContactsOrganization(
          {
            organizationId: organization.id,
            sharedWithOrganization: organization.id,
            organization: organization.name,
            collectorId: ownerContact.id,
            ownerId: ownerContact.id,
            role: ROLES_KEYS[0],
          },
          contacts
        );
      }

      return shouldBeContactsOrgChanged;
    } catch (error) {
      throw error;
    }
  }
};
