import React, { useState } from 'react';

import EditContactAttribute from './EditContactAttribute';
import Button from '../Common/Button';
import { FieldArray } from 'formik';

const EditContactSection = ({ createNewAttribute, section, data, focused }) => {
  const [autoFocus, setAutoFocus] = useState(focused);

  return (
    <FieldArray
      name={section.id}
      render={arrayHelpers => (
        <section>
          <header>
            <h2>{section.name}</h2>
            <Button
              onClick={e => {
                e.preventDefault();
                const addedAttribute = createNewAttribute(section, data);
                arrayHelpers.push(addedAttribute);
                setAutoFocus(true);
              }}
              icon="md-add"
              caption="Add"
            />
          </header>

          <div className="section-attributes">
            {data.map((item, index) => (
              <EditContactAttribute
                name={`${section.id}[${index}]`}
                key={item.createTime || item.id}
                label={section.attributeName}
                valueTypes={section.attributeTypes}
                onDelete={() => arrayHelpers.remove(index)}
                item={item}
                autoFocus={index === data.length - 1 && autoFocus}
              />
            ))}
          </div>
        </section>
      )}
    />
  );
};

export default EditContactSection;
