import React from 'react';
import HoverWrapper from '../Common/HoverWrapper';

const EditContactImages = ({ images, onDelete, onUpload }) => (
  <div className="pictures">
    {Object.values(images).map(image => {
      const { component: Component, id, name, url, isDeleteBtn, ...rest } = image;

      return (
        <HoverWrapper key={id} condition={url && isDeleteBtn} onDelete={() => onDelete(image)}>
          <Component
            className="picture"
            placeholder={name}
            url={url}
            editable={true}
            onClick={() => {
              onUpload(image);
            }}
            {...rest}
          />
        </HoverWrapper>
      );
    })}
  </div>
);

export default EditContactImages; // ToDo ask about edit card images
