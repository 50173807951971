import React, { useRef, useEffect } from 'react';

import DebounceInput from 'global/components/DebounceInput';
import { OptionsModalDialog } from '../Common/ModalDialog';
import Button from '../Common/Button';
import disketteBlue from '../../diskette-blue.svg';

import classes from './ViewOfficeDetail.module.css';

const OfficeEmployessModal = props => {
  const { onClose, onSave, onSearch, children, searchValue, title } = props;

  const debounceInputRef = useRef();

  useEffect(() => {
    debounceInputRef.current?.focus();
  }, [debounceInputRef]);

  return (
    <OptionsModalDialog
      title={title}
      onClose={onClose}
      footer={
        <Button
          caption="Save"
          onClick={onSave}
          customIcon={
            <img
              src={disketteBlue}
              style={{ marginRight: '5px' }}
              width="12px"
              height="12px"
              alt="diskette"
            />
          }
          style={{ color: '#2A79FF', fontWeight: 'bold', boxShadow: 'none', height: 'auto' }}
        />
      }
    >
      <div className={classes.search}>
        <DebounceInput
          onChange={onSearch}
          value={searchValue}
          debounce={250}
          placeholder="Search"
          icon="ios-search"
          ref={debounceInputRef}
        />
      </div>
      <div className={classes.employeesBlock}>{children}</div>
    </OptionsModalDialog>
  );
};

export default OfficeEmployessModal;
