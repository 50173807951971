import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles, withTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import cn from 'classnames';

import styles from './TextField.styles';

class HCTextSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selection: this.getSelection(),
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ selection: this.getSelection() });
    }
  }

  getSelection() {
    if (this.props.disabled) {
      return this.props.value;
    }

    const selectionIndex = this.props.options.findIndex(
      x => x === this.props.value || x.id === this.props.value
    );

    return selectionIndex > -1 ? selectionIndex : this.props.value;
  }

  handleChange(event) {
    const text = event.target.value;
    this.setState({ selection: text });
    this.props.onChange(event.nativeEvent.target.id || text, event);
  }

  render() {
    const { classes, style, className, name } = this.props;
    return (
      <TextField
        style={style}
        select={!this.props.disabled}
        className={cn(classes.textField, className)}
        name={name}
        margin={this.props.margin || 'normal'}
        variant="outlined"
        label={this.props.label}
        value={this.state.selection}
        onChange={this.handleChange}
        disabled={this.props.disabled}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
            input: classes.input,
          },
        }}
        SelectProps={{
          MenuProps: {
            classes: {
              paper: classes.menuPaper,
            },
          },
        }}
      >
        {this.props.options.map((value, i) => (
          <MenuItem id={value.id} key={i} value={i}>
            {typeof value === 'object' ? value?.name : value}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

export default withTheme({
  primaryColor: '#468BFF',
  secondaryColor: 'rgba(0,0,0,0.3)',
})(withStyles(styles)(HCTextSelector));
