import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';

import Button from '../../../Screens/Common/Button';
import Loader from '../Loader';

import msGraphService from '../../../services/msGraph';
import { formatDate } from './helpers';

import styles from './ADImport.module.css';

const TOKEN_EXPIRE_CODE = 410; // https://github.com/microsoftgraph/microsoft-graph-docs/blob/master/concepts/delta-query-overview.md#synchronization-reset

const importUsers = async (request, onComplete) => {
  const users = [];
  let resp = {};
  try {
    resp = await request();
    users.push(...resp.value);

    while (resp['@odata.nextLink']) {
      resp = await msGraphService.callUrl(resp['@odata.nextLink']);
      users.push(...resp.value);
    }

    const deletedUsers = users.filter(user => user['@removed']);
    const existedUsers = users.filter(user => !user['@removed']);

    await onComplete(existedUsers, deletedUsers, resp['@odata.deltaLink']);
  } catch (error) {
    if (error?.response?.status === TOKEN_EXPIRE_CODE) {
      await importUsers(msGraphService.getUsersDelta, onComplete);
    } else {
      throw error;
    }
  }
};

const ADImport = ({ onAdImportComplete, orgData }) => {
  const [isAuthorized, setIsAuthorized] = useState(msGraphService.isAuthorized());
  const [caption, setCaption] = useState('');
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = useCallback(async () => {
    setIsLoading(true);
    try {
      await msGraphService.signIn();
      setIsAuthorized(true);
    } catch (error) {
      alert(error.toString());
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleImport = useCallback(async () => {
    setIsLoading(true);
    const request = orgData.deltaLink
      ? msGraphService.callUrl.bind(this, orgData.deltaLink)
      : msGraphService.getUsersDelta;
    try {
      await importUsers(request, onAdImportComplete);
    } catch (error) {
      alert(error.toString());
    } finally {
      setIsLoading(false);
    }
  }, [onAdImportComplete]);

  useEffect(() => {
    setCaption(isAuthorized ? 'Import from AD' : 'Login to AD');
    setTitle(isAuthorized ? 'Import Employees from Active Directory' : 'Login to Active Directory');
  }, [isAuthorized]);

  return (
    <div className={styles.adImport}>
      <div className={styles.buttonSection}>
        <Button
          type="button"
          className={cn('button', styles.importBtn)}
          caption={caption}
          title={title}
          icon="logo-windows"
          disabled={isLoading}
          onClick={isAuthorized ? handleImport : handleLogin}
        />
        {isLoading && <Loader className="smallGrey" />}
        {isAuthorized && isLoading && (
          <p className={styles.warnMessage}>Please, stay on the page while importing employees.</p>
        )}
      </div>
      <div className={styles.timeSection}>
        <div className={styles.timeValue}>{formatDate(orgData.adUpdateTime)}</div>
        <div className={styles.timeLabel}>Last Update Time</div>
      </div>
    </div>
  );
};
export default ADImport;
