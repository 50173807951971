export const OFFICE_ATTRIBUTES = [
  {
    id: 'name',
    name: 'Office Name',
    csvHeader: 'office_name',
  },
  {
    id: 'primary_mailing',
    name: 'Default Mailing Address',
    csvHeader: 'mailing_address',
  },
  {
    id: 'primary_phone',
    name: 'Default Phone Number',
    csvHeader: 'phone_number',
  },
  {
    id: 'primary_fax',
    name: 'Default Fax Number',
    csvHeader: 'fax_number',
  },
  {
    id: 'website',
    name: 'Default Website',
    csvHeader: 'website',
  },
  {
    id: 'cardId',
    name: 'Default Card',
    csvHeader: 'card',
  },
];

export const NAME_ATTRIBUTES = [
  {
    id: 'name',
    name: 'Office Name',
    csvHeader: 'office_name',
  },
];

export const OFFICE_ATTRIBUTE_TYPE = 'Office';
