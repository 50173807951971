import { toAbsoluteUrl } from 'global/string.utils';

const defaultAttributeType = 0;

const getCsvHeader = obj => obj.csvHeader;

export const ADDRESS_TYPES = ['Personal', 'Primary Work', 'Work', 'Other'];

export const ORGANIZATION_ATTRIBUTE_TYPE = 'Organization';

export const PHONE_TYPES = [
  'Personal (Landline)',
  'Personal (Mobile)',
  'Primary Work (Landline)',
  'Primary Work (Mobile)',
  'Work (Landline)',
  'Work (Mobile)',
  'Other',
];

export const PHYSICAL_ADDRESS_TYPES = [
  'Physical Address - Personal',
  'Physical Address - Primary Work',
  'Physical Address - Work',
  'Physical Address - Other',
];

export const SOCIAL_MEDIA_TYPES = ['LinkedIn', 'Twitter', 'Facebook'];

const socialMediaIcons = {
  Twitter: 'logo-twitter',
  LinkedIn: 'logo-linkedin',
  Facebook: 'logo-facebook',
};

export const NAME_ATTRIBUTES = [
  {
    name: 'First Name',
    id: 'firstName',
    csvHeader: 'first_name',
    adHeader: 'givenName',
  },
  {
    name: 'Last Name',
    id: 'lastName',
    csvHeader: 'last_name',
    adHeader: 'surname',
  },
];

export const NAME_ATTRIBUTES_CSV_HEADERS = NAME_ATTRIBUTES.map(getCsvHeader);

export const PRIMARY_ATTRIBUTES = [
  ...NAME_ATTRIBUTES,
  {
    name: 'Job Title',
    id: 'jobTitle',
    csvHeader: 'job_title',
    adHeader: 'jobTitle',
  },
  {
    name: 'Organization',
    id: 'organization',
    csvHeader: 'organization',
  },
];

export const SECTIONS = [
  {
    name: 'Phone Numbers',
    id: 'phoneNumbers',
    csvHeader: 'phone',
    adHeader: 'businessPhones',
    attributeName: 'Phone Number',
    attributeTypes: PHONE_TYPES,
    defaultAttributeType,
    icon: 'ios-call-outline',
  },
  {
    name: 'Email Addresses',
    id: 'emailAddresses',
    csvHeader: 'email',
    adHeader: 'otherMails',
    attributeName: 'Email Address',
    attributeTypes: ADDRESS_TYPES,
    icon: 'ios-mail-outline',
    getLink: value => `mailto:${value}`,
    defaultAttributeType,
  },
  {
    name: 'Physical Addresses',
    id: 'physicalAddresses',
    csvHeader: 'physical_address',
    adHeader: 'officeLocation',
    attributeName: 'Physical Address',
    attributeTypes: ADDRESS_TYPES,
    getLink: value => `https://www.google.com/maps/search/${value}`,
    defaultAttributeType,
    icon: 'ios-home-outline',
  },
  {
    name: 'Social Media',
    id: 'socialMedia',
    csvHeader: 'social_media',
    attributeName: 'Social Media',
    attributeTypes: SOCIAL_MEDIA_TYPES,
    getIcon: attributeType => socialMediaIcons[attributeType],
    getLink: toAbsoluteUrl,
    defaultAttributeType,
  },
  {
    name: 'Websites',
    id: 'websites',
    csvHeader: 'website',
    attributeName: 'Website',
    attributeTypes: ['Web'],
    icon: 'ios-globe-outline',
    getLink: toAbsoluteUrl,
    defaultAttributeType,
  },
];

export const ATTRIBUTE_CSV_HEADERS = [
  ...PRIMARY_ATTRIBUTES.map(getCsvHeader),
  ...SECTIONS.map(getCsvHeader),
];

export const ROLES = { basic: 'Basic user', admin: 'Admin' };
