import React, { memo, useEffect } from 'react';
import Resizable from 're-resizable';
import cn from 'classnames';

import DraggableItem from '../DraggableItem';
import {
  DEFAULT_CARD_RESIZIBLE_FIELD_MIN_WIDTH,
  DEFAULT_CARD_RESIZIBLE_FIELD_ROUTE,
} from '../../constants';
import { getNumberFromPercent, getPercentFromNumber } from 'utils/helpers';
import styles from './ResizableCardField.module.css';

const ResizableCardField = ({
  isActive,
  field,
  cardSize,
  fontSizeCoefficient,
  onDispatchCardFields,
  onDraggableItemMouseDown,
}) => {
  useEffect(() => {
    onDispatchCardFields({
      type: 'merge',
      payload: { id: field.id, fontSize: Math.round(field.fontSize * fontSizeCoefficient) },
    });
  }, [fontSizeCoefficient]);

  const onResizeStop = (e, direction, ref, diff) => {
    const diffWidth = getPercentFromNumber(diff.width, cardSize.width);

    onDispatchCardFields({
      type: 'merge',
      payload: { id: field.id, width: field.width + diffWidth },
    });
  };

  return (
    <Resizable
      enable={DEFAULT_CARD_RESIZIBLE_FIELD_ROUTE}
      minWidth={DEFAULT_CARD_RESIZIBLE_FIELD_MIN_WIDTH}
      style={{
        left: getNumberFromPercent(field.x, cardSize.width) || 0,
        top: getNumberFromPercent(field.y, cardSize.height) || 0,
        position: 'absolute',
      }}
      className={cn(styles.root, { [styles.active]: isActive })}
      size={{ width: getNumberFromPercent(field.width, cardSize.width) }}
      onResizeStop={onResizeStop}
    >
      <DraggableItem field={field} onDraggableItemMouseDown={onDraggableItemMouseDown} />
    </Resizable>
  );
};

export default memo(ResizableCardField);
