import { observable, computed, action } from 'mobx';
import firebaseService from 'services/firebase';
import { primaryValidationSchema } from '../Screens/Organization/EditOfficeSettings.validation';
import { filterInvalidAttributes } from './Office.util';

export default class OfficeType {
  @observable data = {};

  createNew(organizationId, data = {}) {
    this.data = {
      id: data.id || firebaseService.nextOfficeId(),
      ownerId: firebaseService.getUserId(),
      organizationId,
      name: data.name || '',
      cardId: data.cardId || null,
      primary_mailing: data.primary_mailing || '',
      primary_email: data.primary_email || '',
      primary_phone: data.primary_phone || '',
      primary_fax: data.primary_fax || '',
      primary_card: data.primary_card || '',
      website: data.website || '',
    };
    return this;
  }

  @computed get id() {
    return this.data.id;
  }

  @computed get dataId() {
    return this.data.data_id;
  }

  @computed get name() {
    return this.data.name;
  }

  set name(value) {
    this.data.name = value ? value.trim() : '';
  }

  @computed get defaultCard() {
    return this.data.defaultCard;
  }

  set defaultCard(value) {
    this.data.defaultCard = value ? value.trim() : '';
  }

  /*
   * I belive that properties are used somewhere.
   * So renaming is too time consuming for now
   */

  /* eslint-disable camelcase */

  @computed get primary_mailing() {
    return this.data.primary_mailing;
  }

  set primary_mailing(value) {
    this.data.primary_mailing = value ? value.trim() : '';
  }

  @computed get primary_email() {
    return this.data.primary_email;
  }

  set primary_email(value) {
    this.data.primary_email = value ? value.trim() : '';
  }

  @computed get primary_phone() {
    return this.data.primary_phone;
  }

  set primary_phone(value) {
    this.data.primary_phone = value ? value.trim() : '';
  }

  @computed get primary_fax() {
    return this.data.primary_fax;
  }

  set primary_fax(value) {
    this.data.primary_fax = value ? value.trim() : '';
  }

  @computed get primary_card() {
    return this.data.primary_card || this.data.cardId;
  }

  set primary_card(value) {
    this.data.primary_card = value ? value.trim() : '';
  }

  /* eslint-enable camelcase */

  @action fromFirestoreDoc(doc) {
    this.data = Object.assign(this.data, doc);
    return this;
  }

  @computed get firestoreDoc() {
    return this.data;
  }

  save() {
    const { id, data } = this;
    return firebaseService.saveOffice(id, data);
  }

  delete() {
    return firebaseService.deleteOffice(this.id);
  }

  createCsvOffice(organizationId, data) {
    this.createNew(organizationId, data);

    this.origData = data;

    /*
     * If primary validation failed office won't imported
     * and error will be thrown
     */

    primaryValidationSchema.validateSync(data);

    // secondary invalid attributes will be filtered
    const filtered = filterInvalidAttributes(data);

    this.data = Object.assign(this.data, filtered);
  }
}
