import firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';

import config from 'firebase.config.json';

firebase.initializeApp(config);

export default firebase;
