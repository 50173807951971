import HCProfile from '../Common/HCProfile';
import { CARD_SIDE } from 'utils/constants';
import Card from 'global/components/Card';

const imagesConfig = [
  {
    id: 'profileImage',
    name: 'Profile Image',
    urlPropertyName: 'imageUrl',
    component: HCProfile,
  },
  {
    id: 'cardFront',
    name: 'Card Front',
    urlPropertyName: 'cardFrontUrl',
    oppositeUrlPropertyName: 'cardBackUrl',
    component: Card,
    backImage: false,
    cardKey: 'frontUrl',
    side: CARD_SIDE.FRONT,
    style: { marginRight: '15px' },
  },
  {
    id: 'cardBack',
    name: 'Card Back',
    urlPropertyName: 'cardBackUrl',
    oppositeUrlPropertyName: 'cardFrontUrl',
    component: Card,
    backImage: true,
    cardKey: 'backUrl',
    side: CARD_SIDE.BACK,
    style: {},
  },
];

export default imagesConfig;
