import React from 'react';
import Ionicon from 'react-ionicons';
import { noop } from 'global/utils';

export default function Checkmark({ checked = true, style, handleClick = noop }) {
  return (
    <Ionicon
      style={style}
      icon={checked ? 'md-checkbox-outline' : 'md-square-outline'}
      fontSize="16px"
      onClick={handleClick}
    />
  );
}
