import React from 'react';
import { Tabs as MaterialTabs, Tab as MaterialTab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const Tabs = withStyles({
  root: {
    boxShadow: '0px 1px 0px 0px #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
  scrollButtons: {
    width: '20px',
  },
})(MaterialTabs);

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export const Tab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 100,
    fontFamily: 'Nunito Regular',
    fontSize: '12px',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))(props => <MaterialTab disableRipple {...a11yProps(props.index)} {...props} />);
