import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function joinPath(base, path) {
  return base.charAt(base.length - 1) === '/' ? base + path : base + '/' + path;
}

export function formatPhoneNumber(number) {
  let ret = '';
  try {
    const phoneNumber = parsePhoneNumberFromString(number, 'US');
    ret = phoneNumber?.formatNational();
  } catch (e) {
    console.log(e);
  }
  return ret || number;
}

const ABSOLUTE_URL_PATTERN = /^http[s]*:\/\/[\w]+/i;

export const isAbsoluteUrl = url => ABSOLUTE_URL_PATTERN.test(url);

export const toAbsoluteUrl = url => (isAbsoluteUrl(url) ? url : `//${url}`);
