import axios from 'axios';

const baseUrl = `${location.protocol}//${location.host}`;

const connectHubspot = async organizationId => {
  try {
    const authUrl = await axios.get(`${baseUrl}/hubspot/oauth/connect`, {
      params: { orgId: organizationId },
    });

    window.location.href = authUrl.data;
  } catch (error) {
    throw error;
  }
};

const createContact = async (contactId, organizationId) => {
  try {
    const createContactResponse = await axios.post(
      `${baseUrl}/hubspot/contacts/create`,
      {
        contactId,
        organizationId,
      },
      { withCredentials: true }
    );

    return createContactResponse.data;
  } catch (error) {
    throw error;
  }
};

export { createContact, connectHubspot };
