import React, { memo, useMemo } from 'react';
import Ionicon from 'react-ionicons';
import cn from 'classnames';

import {
  getPercentFromNumber,
  getNumberFromPercent,
  getCardSizeByAspectRatioAndSideSize,
  getFieldFontStyles,
  getFieldFontSizeCoefficient,
} from 'utils/helpers';
import {
  ALIGNMENT,
  FONT_CARD_FIELD_PADDING,
  DEFAULT_ASPECT_RATIO,
  CARD_CONTAINER_SIZE_LABELS,
} from 'utils/constants';
import { noop } from 'global/utils';
import styles from './Card.module.css';

const Card = ({
  card,
  url,
  side,
  className,
  containerSize = {},
  placeholder,
  isCardUploadedByUser,
  backSideCard,
  style,
  editable = false,
  onClick = noop,
}) => {
  const isShowPlaceholder = placeholder && !url && (isCardUploadedByUser || !card);
  const isApplyDefaultConfig = isCardUploadedByUser || (!card && !url);
  const {
    cardObjects,
    borderRadiusProportion = { x: 0, y: 0 },
    aspectRatio,
    cardSize: savedCardSize,
  } = card || {};
  const cardSize = getCardSizeByAspectRatioAndSideSize({
    aspectRatio: isApplyDefaultConfig ? DEFAULT_ASPECT_RATIO : aspectRatio,
    containerSize: isApplyDefaultConfig
      ? containerSize[CARD_CONTAINER_SIZE_LABELS.DEFAULT]
      : containerSize[CARD_CONTAINER_SIZE_LABELS.NON_STANDARD],
  });
  const widthCoefficient = useMemo(
    () =>
      cardSize && savedCardSize
        ? getPercentFromNumber(cardSize.width, savedCardSize.width) / 100
        : 1,
    [savedCardSize, cardSize]
  );
  const fontSizeCoefficient = getFieldFontSizeCoefficient(cardSize, savedCardSize);

  const handleCardClick = () => {
    onClick(card);
  };

  return (
    <div
      className={cn(styles.root, className)}
      style={{
        borderRadius: `${
          isCardUploadedByUser ? 0 : `${borderRadiusProportion.x}%/${borderRadiusProportion.y}%`
        }`,
        backgroundImage: url ? `url(${url})` : 'none',
        minWidth: cardSize.width,
        ...(style || {}),
        ...cardSize,
      }}
      onClick={handleCardClick}
    >
      {cardObjects?.length > 0 &&
        cardSize &&
        !isCardUploadedByUser &&
        cardObjects.map(field => {
          if (field.side !== side) {
            return null;
          }

          return (
            <div
              key={field.id}
              className={styles.field}
              style={{
                paddingTop: `${FONT_CARD_FIELD_PADDING * widthCoefficient}px`,
                paddingBottom: `${FONT_CARD_FIELD_PADDING * widthCoefficient}px`,
                width: `${getNumberFromPercent(field.width, cardSize.width)}px`,
                justifyContent: ALIGNMENT[field.alignment],
                font: getFieldFontStyles({
                  ...field,
                  fontSizeCoefficient,
                }),
                left: `${getNumberFromPercent(field.x, cardSize.width)}px`,
                top: `${getNumberFromPercent(field.y, cardSize.height)}px`,
                color: field.color,
              }}
            >
              {field.title}
            </div>
          );
        })}

      {isShowPlaceholder && (
        <div className={styles.placeholder}>
          {editable && <Ionicon icon="md-add" fontSize="12px" color="grey" />}
          {placeholder}
        </div>
      )}

      {backSideCard}
    </div>
  );
};

export default memo(Card);
