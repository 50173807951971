import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Prompt } from 'react-router-dom';

import Ionicon from 'react-ionicons';

import { useContactCardVersion } from 'global/hooks';

import ImportPhoto from './ImportPhoto';
import Button from '../Common/Button';
import themes from '../Common/themes';
import HCTextSelector from '../Common/HCTextSelector';
import EditContactSection from './EditContactSection';
import { SECTIONS, ORGANIZATION_ATTRIBUTE_TYPE, ROLES } from 'Models/Contacts.config';
import { OFFICE_ATTRIBUTE_TYPE } from 'Models/Office.config';
import imagesConfig from './Images.config';

import validationSchema from './EditContactDetail.validation';
import { Form, Formik } from 'formik';
import { MuiThemeProvider } from '@material-ui/core';
import { Snackbar } from 'react-md';

import ValidableTextField from './ValidableTextField';

import firebaseService from 'services/firebase';

import { createNewAttribute, capitalize } from 'global/utils';
import { toJS } from 'mobx';
import EditContactImages from './EditContactImages';
import Loader from '../../global/components/Loader';
import ContactNotes from './ContactNotes';
import ViewContactImages from './ViewContactImages';

import diskette from 'assets/icons/diskette.svg';
import HCBusinessCard from '../../HCBusinessCard.png';
import { useInfiniteFirebaseQuery } from '../../global/hooks';
import { ContactType } from '../../Models/Contacts';
import { BAR_MODE } from '../../utils/constants';
import { CARD_CONTAINER_SIZE_LABELS } from 'utils/constants';

const CARD_CONTAINER = {
  [CARD_CONTAINER_SIZE_LABELS.DEFAULT]: { width: 215, height: 120 },
  [CARD_CONTAINER_SIZE_LABELS.NON_STANDARD]: { width: 215, height: 215 },
};
const ROLES_KEYS = Object.keys(ROLES);
const ROLES_VALUES = Object.values(ROLES);

// TODO come up with better solution
const getCardVersionUrl = (image, cardVersion) => {
  let cardVersionUrl = cardVersion?.[image.cardKey];
  if (image.cardKey === 'frontUrl') {
    cardVersionUrl = cardVersion ? cardVersion?.frontUrl || HCBusinessCard : '';
  }

  return cardVersionUrl;
};

const getImages = (hcInfo, cardVersion, cardObjects) =>
  imagesConfig.reduce((sources, image) => {
    const cardVersionUrl = getCardVersionUrl(image, cardVersion);
    const isCardUploadedByUser =
      (!!hcInfo[image.urlPropertyName] || !!hcInfo[image.oppositeUrlPropertyName]) &&
      !cardVersionUrl;

    return {
      ...sources,
      [image.id]: {
        toUpload: false,
        url: cardVersionUrl || hcInfo[image.urlPropertyName],
        isDeleteBtn: hcInfo[image.urlPropertyName] || !cardVersionUrl,
        ...(cardVersion ? { card: { ...cardVersion, cardObjects } } : {}),
        isCardUploadedByUser,
        side: image.side,
        containerSize: CARD_CONTAINER,
        ...image,
      },
    };
  }, {});

function filterAttributes(attributes) {
  return attributes.length ? attributes.filter(el => !el.disabled) : [];
}

export function getHcInfo({ hcInfo, organization, office, isEmployee }) {
  const organizationEmail = {
    id: hcInfo.emailAddresses[hcInfo.emailAddresses.length - 1]?.id + 1 || 0,
    type: ORGANIZATION_ATTRIBUTE_TYPE,
    value: organization?.emailAddress,
    disabled: true,
  };
  const organizationPhone = {
    id: hcInfo.phoneNumbers[hcInfo.phoneNumbers.length - 1]?.id + 1 || 0,
    type: ORGANIZATION_ATTRIBUTE_TYPE,
    value: organization?.phoneNumber,
    disabled: true,
  };
  const organizationWebsite = {
    id: hcInfo.websites[hcInfo.websites.length - 1]?.id + 1 || 0,
    type: ORGANIZATION_ATTRIBUTE_TYPE,
    value: organization?.website,
    disabled: true,
  };
  const officeEmail = {
    id: organizationEmail.id + 1 || 0,
    type: OFFICE_ATTRIBUTE_TYPE,
    value: office?.primary_mailing,
    disabled: true,
  };
  const officePhone = {
    id: organizationPhone.id + 1 || 0,
    type: OFFICE_ATTRIBUTE_TYPE,
    value: office?.primary_phone,
    disabled: true,
  };
  const officeWebsite = {
    id: organizationWebsite.id + 1 || 0,
    type: OFFICE_ATTRIBUTE_TYPE,
    value: office?.website,
    disabled: true,
  };

  const phoneNumbers = [
    ...(organization?.phoneNumber && isEmployee ? [organizationPhone] : []),
    ...(office?.primary_phone ? [officePhone] : []),
  ];
  const emailAddresses = [
    ...(organization?.emailAddress && isEmployee ? [organizationEmail] : []),
    ...(office?.primary_mailing ? [officeEmail] : []),
  ];
  const websites = [
    ...(organization?.website && isEmployee ? [organizationWebsite] : []),
    ...(office?.website ? [officeWebsite] : []),
  ];

  return {
    ...hcInfo,
    ...(organization?.name && isEmployee ? { organization: organization?.name } : {}),
    phoneNumbers: [...hcInfo.phoneNumbers, ...phoneNumbers],
    emailAddresses: [...hcInfo.emailAddresses, ...emailAddresses],
    websites: [...hcInfo.websites, ...websites],
    collectorId: hcInfo.collectorId || hcInfo.ownerId,
  };
}

const EditContactDetail = ({ activeSectionIndex, store, onComplete, onDirtyChanged, ...props }) => {
  const contactInfo = props.contactInfo || store.contactInfo;
  const isEmployee =
    contactInfo.hcInfo.organizationId === props.organization?.id ||
    (props.mode === BAR_MODE.NEW && props.type === 'employee');

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);

  const [cardVersion, cardObjects, contactOffice, isVersionLoading] = useContactCardVersion(
    contactInfo,
    props.offices
  );

  const [uploadingImageConfig, setUploadingImageConfig] = useState(null);
  const [snackText, setSnackText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hcInfo, setHcInfo] = useState(
    getHcInfo({
      hcInfo: toJS(contactInfo.hcInfo),
      organization: props.organization,
      office: contactOffice,
      isEmployee,
    })
  );
  const [images, setImages] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [errorInput, setErrorInput] = useState(null);
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);

  const officesForSelect = useMemo(() => props.offices?.list.map(item => item.name) || [], [
    props.offices,
  ]);

  let employees = [];

  let ownerName = contactInfo.ownerName || '';
  let collectorName = contactInfo.collectorName || '';

  if (props.type === 'orgContact') {
    const result = useInfiniteFirebaseQuery('contacts', {
      queryAdapter: query => query.where('organizationId', '==', props.organization.id),
      adapter: doc => new ContactType().fromFirestoreDoc(doc),
    });

    employees = result.data;
  }

  const employeesForSelect = useMemo(() => employees.map(employee => employee.fullName), [
    employees,
  ]);

  useEffect(() => {
    if (props.isShowMessage && props.type === 'profile') {
      if (!contactInfo.firstName) {
        firstNameRef.current?.focus();
      } else if (!contactInfo.lastName) {
        lastNameRef.current?.focus();
      }
    }
  }, [props.isShowMessage]);

  useEffect(() => {
    if (contactInfo.id !== hcInfo.id) {
      setHcInfo(
        getHcInfo({
          hcInfo: toJS(contactInfo.hcInfo),
          organization: props.organization,
          office: contactOffice,
          isEmployee,
        })
      );
    }
    setImages(getImages(contactInfo.hcInfo, cardVersion, cardObjects, props.cardContainer));
  }, [contactInfo.id, cardVersion]);

  useEffect(() => {
    if (activeSectionIndex !== null) {
      const section = SECTIONS[activeSectionIndex];
      const data = hcInfo[section.id];
      const attribute = createNewAttribute(section, data);
      setHcInfo({ ...hcInfo, [section.id]: [...data, attribute] });
    }
  }, [activeSectionIndex, contactInfo.id]);

  useEffect(() => {
    onDirtyChanged(isDirty);
  }, [isDirty]);

  useEffect(() => {
    setIsFieldDisabled(!!hcInfo.sharedFromId);
  }, [hcInfo]);

  const dismiss = () => {
    errorInput?.focus();
    setSnackText('');
    setErrorInput(null);
  };

  const clickSave = async values => {
    setIsLoading(true);

    try {
      if (
        props.type === 'orgContact' &&
        contactInfo.ownerName &&
        values.ownerId !== contactInfo.hcInfo.ownerId
      ) {
        if (
          !window.confirm(
            `You are going to change the contact owner from ${contactInfo.ownerName} to ${
              employees.find(el => el.id === values.ownerId)?.fullName || ''
            }`
          )
        ) {
          return;
        }
      }
      if (
        props.type === 'orgContact' &&
        contactInfo.collectorName &&
        values.collectorId !== contactInfo.hcInfo.collectorId
      ) {
        if (
          !window.confirm(
            `You are going to change the contact collector from ${contactInfo.collectorName} to ${
              employees.find(el => el.id === values.collectorId)?.fullName || ''
            }`
          )
        ) {
          return;
        }
      }
      const result = Object.keys(images).map(async imageId => {
        const image = images[imageId];
        if (image.toUpload) {
          const url = await store.uploadImage(image);
          values[image.urlPropertyName] = url;
        } else if (image.toDelete) {
          await store.deleteImage(hcInfo[image.urlPropertyName], image.urlPropertyName);
          values[image.urlPropertyName] = '';
        }
      });

      await Promise.all(result);

      const personalEmail =
        values.emailAddresses.find(email => email.type === 'Personal')?.value || '';
      const requestBody = {
        ...values,
        firstName: capitalize(values.firstName),
        lastName: capitalize(values.lastName),
        ...(props.organization?.id && props.organization?.name && isEmployee
          ? { organizationId: props.organization?.id, organization: props.organization?.name }
          : {}),
        phoneNumbers: filterAttributes(values.phoneNumbers),
        emailAddresses: filterAttributes(values.emailAddresses),
        websites: filterAttributes(values.websites),
        personalEmail,
      };

      if (props.organization?.id && !contactInfo.hcInfo.sharedWithOrganization) {
        requestBody.sharedWithOrganization = props.organization.id;
      }

      await firebaseService.saveContact(requestBody);

      /*
       * Need to mutate original hcInfo mobx proxy
       * in order to refresh other components which referenced to it
       */

      Object.assign(contactInfo.hcInfo, requestBody);

      onComplete(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = values => {
    // if profile does not has a name
    if (props.type === 'profile' && !contactInfo.firstName && !values.firstName) {
      firstNameRef.current.focus();
    } else if (props.type === 'profile' && !contactInfo.lastName && !values.lastName) {
      lastNameRef.current.focus();
    } else if (
      props.type === 'profile' &&
      values.firstName &&
      values.lastName &&
      !contactInfo.firstName &&
      !contactInfo.lastName
    ) {
      setSnackText('Please save your changes');
    } else if (isDirty) {
      if (window.confirm('All unsaved changes will be lost. Are you sure you want to continue?')) {
        onComplete(false);
      }
    } else {
      onComplete(false);
    }
  };

  const renderList = (values, errors) => {
    return SECTIONS.map((section, index) => (
      <EditContactSection
        key={section.id}
        section={section}
        data={values[section.id]}
        createNewAttribute={createNewAttribute}
        focused={activeSectionIndex === index}
        errors={errors[section.id] || []}
      />
    ));
  };

  const getErrorInput = () => document.querySelector('[aria-invalid="true"]');

  const isSomeInputEmpty = (values, key) => values[key].some(el => el.value === '');

  const handleSubmit = (e, formikHandleSubmit, errors, values) => {
    e.preventDefault();
    const keys = Object.keys(errors);
    if (keys.lendth > 0) {
      if (isSomeInputEmpty(values, keys[0])) {
        setSnackText('Please complete all phone numbers, emails and addresses');
      } else if (!isSomeInputEmpty(values, keys[0])) {
        setSnackText('Please provide the valid information');
      }
      const errorEl = getErrorInput();
      setErrorInput(errorEl);
      return;
    }
    // for checking only for employee add 'props.type === 'employee''to condition
    if (props.type === 'employee' && !values.emailAddresses.some(el => el.type === 'Personal')) {
      setSnackText('Personal email is required for a contact');
      return;
    }

    return formikHandleSubmit(e);
  };

  const deleteImage = image => {
    if (image.toUpload || window.confirm('Delete the image?')) {
      setIsDirty(true);

      setImages({
        ...images,
        [image.id]: {
          ...images[image.id],
          blob: null,
          url: image.toUpload ? hcInfo[image.urlPropertyName] : null,
          card: null,
          toUpload: false,
          toDelete: !image.toUpload,
        },
      });
    }
  };

  const importImage = blob => {
    const { id, urlPropertyName } = uploadingImageConfig;

    let replace;

    if (hcInfo[urlPropertyName] && !images[id].toDelete) {
      replace = window.confirm('Replace the image?');
    } else {
      replace = true;
    }

    if (replace) {
      setUploadingImageConfig(null);
      setIsDirty(true);

      setImages({
        ...images,
        [id]: {
          ...images[id],
          toUpload: true,
          blob,
          url: URL.createObjectURL(blob),
          card: null,
        },
      });
    }
  };

  const uploadImage = uploadingImageConfig => setUploadingImageConfig(uploadingImageConfig);

  const renderEditScreen = () => {
    const theme = themes[props.theme === 'you' ? 'white' : 'black'];

    if (isVersionLoading) {
      return (
        <div className={`loader-wrapper ${props.type !== 'profile' ? 'loader-wrapper-black' : ''}`}>
          <Loader />
        </div>
      );
    }

    return (
      <Formik
        validationSchema={validationSchema}
        validateOnChange={false}
        initialValues={hcInfo}
        onSubmit={clickSave}
        key={hcInfo.id}
      >
        {({ values, errors, handleSubmit: formikHandleSubmit, handleChange, setValues, dirty }) => {
          const handleChangeAndBlurForm = () => {
            onDirtyChanged(dirty);
            if (dirty !== isDirty) setIsDirty(dirty);
          };

          return (
            <Form
              className={`panel contact contact-${props.theme} contact-edit`}
              onSubmit={e => handleSubmit(e, formikHandleSubmit, errors, values)}
              onChange={handleChangeAndBlurForm}
              onBlur={handleChangeAndBlurForm}
            >
              <Prompt
                when={dirty && !isLoading}
                message={() =>
                  'All unsaved changes will be lost. Are you sure you want to continue?'
                }
              />

              <div className="header">
                <p>{props.title}</p>
                <div className="header-buttons">
                  {isLoading ? (
                    <Loader className={props.theme === 'you' ? 'smallWhite' : 'smallGrey'} />
                  ) : (
                    <>
                      <Button
                        type="submit"
                        caption="Save"
                        customIcon={
                          <img
                            src={diskette}
                            style={{ marginRight: '10px' }}
                            width="12px"
                            height="12px"
                            alt="diskette"
                          />
                        }
                        style={{ backgroundColor: '#2DD955', color: '#fff' }}
                      />
                      <Ionicon
                        onClick={() => onClose(values)}
                        icon="ios-close"
                        className="close-button"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="primary-attributes">
                {isFieldDisabled ? (
                  <ViewContactImages
                    cardVersion={cardVersion}
                    contact={contactInfo}
                    cardObjects={cardObjects}
                    disabled={isFieldDisabled}
                  />
                ) : (
                  <EditContactImages
                    images={images}
                    onDelete={deleteImage}
                    onUpload={uploadImage}
                  />
                )}

                <MuiThemeProvider theme={theme}>
                  <div className="edit-contact-attribute-fields">
                    <ValidableTextField
                      label="First Name"
                      name="firstName"
                      style={{ marginRight: 8, flex: 1 }}
                      ref={firstNameRef}
                      disabled={isFieldDisabled}
                    />
                    <ValidableTextField
                      label="Last Name"
                      name="lastName"
                      style={{ flex: 1 }}
                      ref={lastNameRef}
                      disabled={isFieldDisabled}
                    />
                  </div>

                  <div className="edit-contact-attribute-fields">
                    <ValidableTextField
                      label="Job Title"
                      name="jobTitle"
                      style={{ marginRight: 8, flex: 1 }}
                      disabled={isFieldDisabled}
                    />
                    <ValidableTextField
                      label="Organization"
                      name="organization"
                      style={{ flex: 1 }}
                      disabled={isFieldDisabled || isEmployee}
                    />
                  </div>

                  {errors.firstName && <p className="error">{errors.firstName}</p>}
                </MuiThemeProvider>
              </div>

              {props.type === 'employee' && (
                <section className="admin-settings">
                  <header>
                    <h2>ADMIN SETTINGS</h2>
                  </header>
                  <MuiThemeProvider theme={props.type === 'profile' ? themes.black : theme}>
                    <div className="edit-contact-attribute-fields">
                      <HCTextSelector
                        style={{ flex: 1, marginRight: 8 }}
                        label="Office"
                        options={officesForSelect}
                        value={contactOffice?.name || ''}
                        onChange={index =>
                          setValues({
                            ...values,
                            officeName: props.offices.list[index]?.data?.name,
                            officeId: props.offices.list[index]?.data?.id,
                          })
                        }
                        margin="none"
                        disabled={officesForSelect.length === 0}
                      />
                      <HCTextSelector
                        style={{ flex: 1, marginRight: 8 }}
                        label="Role"
                        options={ROLES_VALUES}
                        value={ROLES[values.role]}
                        onChange={index => setValues({ ...values, role: ROLES_KEYS[index] })}
                        margin="none"
                      />
                    </div>
                  </MuiThemeProvider>
                </section>
              )}
              {props.type === 'orgContact' && employees.length > 0 && (
                <section className="admin-settings">
                  <header>
                    <h2>ADMIN SETTINGS</h2>
                  </header>
                  <MuiThemeProvider theme={props.type === 'profile' ? themes.black : theme}>
                    <div className="edit-contact-attribute-fields">
                      <HCTextSelector
                        style={{ flex: 1, marginRight: 8 }}
                        label="Owner"
                        options={employeesForSelect}
                        value={ownerName}
                        onChange={index =>
                          setValues({
                            ...values,
                            ownerName: employees[index].fullName,
                            ownerId: employees[index].id,
                          })
                        }
                        margin="none"
                      />
                      <HCTextSelector
                        style={{ flex: 1, marginRight: 8 }}
                        label="Collected By"
                        options={employeesForSelect}
                        value={collectorName}
                        onChange={index =>
                          setValues({
                            ...values,
                            collectorName: employees[index].fullName,
                            collectorId: employees[index].id,
                          })
                        }
                        margin="none"
                      />
                    </div>
                  </MuiThemeProvider>
                </section>
              )}
              <div className="secondary-attributes">
                <MuiThemeProvider theme={themes.black}>
                  {renderList(values, errors)}
                  {props.theme === 'detail' && (
                    <ContactNotes
                      isEdit={true}
                      notes={hcInfo.notes}
                      onNotesChanged={handleChange}
                    />
                  )}
                </MuiThemeProvider>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      {renderEditScreen()}
      {uploadingImageConfig && (
        <ImportPhoto
          isBusinessCard={uploadingImageConfig.id !== 'profileImage'}
          titleText={`Import ${uploadingImageConfig.name}`}
          onComplete={blobPromise => {
            if (blobPromise) {
              blobPromise.then(blob => {
                importImage(blob);
              });
            } else {
              setUploadingImageConfig(null);
            }
          }}
        />
      )}
      <div className="snakbar-container">
        <Snackbar
          style={{ padding: 20, color: 'white', backgroundColor: 'rgb(6, 16, 33)', fontSize: 16 }}
          toasts={snackText.length > 0 ? [{ text: snackText, action: 'x' }] : []}
          autoFocusAction
          autohide={false}
          onDismiss={dismiss}
        />
      </div>
    </>
  );
};

export default EditContactDetail;
