import { object, string } from 'yup';

import { PHONE_NUMBER_PATTERN } from 'global/validation';
import { toAbsoluteUrl } from 'global/string.utils';

const primaryAttributes = {
  name: string().required(),
};

const secondaryAttributes = {
  primary_phone: string().matches(PHONE_NUMBER_PATTERN),
  primary_fax: string().matches(PHONE_NUMBER_PATTERN),
  website: string().transform(toAbsoluteUrl).url(),
};

export const primaryValidationSchema = object().shape(primaryAttributes);

export const secondaryValidationSchema = object().shape(secondaryAttributes);

export default object().shape({
  ...primaryAttributes,
  ...secondaryAttributes,
});
