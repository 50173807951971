import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import styles from './TextField.styles';

class HCTextEdit extends React.Component {
  render() {
    const { classes, style, multiline = false, name, className, type = 'text', value } = this.props;

    return (
      <TextField
        inputRef={this.props.forwardedRef}
        name={name}
        error={!!this.props.error}
        helperText={this.props.helperText}
        autoFocus={this.props.autoFocus}
        style={style}
        className={cn(classes.textField, className)}
        type={type}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            multiline: classes.cssMultilineInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
            input: classes.input,
          },
        }}
        FormHelperTextProps={{
          style: {
            margin: '2px 6px',
          },
        }}
        label={this.props.label}
        value={value}
        onChange={event => {
          this.props.onChange(event.target.value, event);
        }}
        onBlur={this.props.onBlur}
        margin={this.props.margin || 'normal'}
        variant="outlined"
        multiline={multiline}
        disabled={this.props.disabled}
        required={this.props.required}
      />
    );
  }
}

const WarrpedTextField = withStyles(styles)(HCTextEdit);

// eslint-disable-next-line react/display-name
export default React.forwardRef((props, ref) => <WarrpedTextField {...props} forwardedRef={ref} />);
