import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { Field } from 'formik';

import HCTextSelector from '../Common/HCTextSelector';
import themes from '../Common/themes';
import { getHubspotConfigOptions } from '../../utils/helpers';

const OrgHubspotEditSettings = ({ name, label }) => (
  <Field name={name}>
    {({ field, form: { handleChange } }) => (
      <MuiThemeProvider theme={themes.black}>
        <HCTextSelector
          onChange={(text, event) => {
            const { value: valueIndex, name } = event.target;
            event.target.value = getHubspotConfigOptions(name)[valueIndex].id;
            handleChange(event);
          }}
          label={label}
          name={name}
          options={getHubspotConfigOptions(name)}
          value={field.value}
        />
      </MuiThemeProvider>
    )}
  </Field>
);

export default OrgHubspotEditSettings;
