import React, { useEffect } from 'react';

import { inject } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import Button from '../Common/Button';
import OrgHubspotViewSettings from './OrgHubspotViewSettings';
import { Snackbar } from 'react-md';
import FlexView from 'react-flexview';
import { ADImport } from '../../global/components/ADImport';
import { useInfiniteFirebaseQuery } from 'global/hooks';
import { ContactType } from '../../Models/Contacts';
import { useToasts } from '../../global/hooks/useToasts';
import firebaseService from 'services/firebase';
import { processAlreadyCreatedContacts } from 'utils/helpers';

import './OrgSettings.css';
import { ORGANIZATION_ATTRIBUTES } from 'Models/Organizations.config';

const ViewOrgSettings = inject(
  'organizationStore',
  'accountStore'
)(({ organizationStore, accountStore }) => {
  const history = useHistory();
  const { data: employees, refetch, error: employeesError } = useInfiniteFirebaseQuery('contacts', {
    adapter: card => new ContactType().fromFirestoreDoc(card),
    queryAdapter: query =>
      query.where('organizationId', '==', organizationStore.organization.id || null),
  });
  const [toasts, setToast, dismissToast] = useToasts();

  const goToEditMode = () => {
    history.push('/main/EditOrganizationSettings');
  };

  const onAdImportComplete = async (adEmployeesExisted, adEmployeesDeleted, deltaLink = null) => {
    try {
      const {
        contacts,
        contactsDuplicate,
        alreadyCreatedContacts,
      } = await accountStore.importAdContacts({
        data: adEmployeesExisted,
        organizationId: organizationStore.organization.id,
        organizationName: organizationStore.organization.name,
        collectorId: accountStore.id,
        existedContacts: employees,
      });

      const isAlreadyCreatedContactsChanged = await processAlreadyCreatedContacts(
        organizationStore,
        accountStore,
        alreadyCreatedContacts
      );

      await firebaseService.deleteContacts(adEmployeesDeleted);

      await firebaseService.updateOrganization(organizationStore.organization.id, {
        adUpdateTime: contacts.length
          ? Date.now()
          : organizationStore.organization.orgData.adUpdateTime || null,
        deltaLink,
      });

      const addedContactsCount = isAlreadyCreatedContactsChanged
        ? [...contacts, ...alreadyCreatedContacts].length
        : contacts.length;
      const alreadyExistContactsCount = !isAlreadyCreatedContactsChanged
        ? [...contactsDuplicate, ...alreadyCreatedContacts].length
        : contactsDuplicate.length;

      if (contacts.length || alreadyCreatedContacts.length) {
        await refetch();
      }
      setToast(`${addedContactsCount} were added, ${alreadyExistContactsCount} already exist.`);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (employeesError) {
      history.push('/main');
    }
  }, []);

  return (
    <>
      <div className="panel details details-view">
        <div className="header">
          <p>Organization Settings</p>
          <div className="header-buttons">
            <Button caption="Edit" icon="md-create" onClick={goToEditMode} />
          </div>
        </div>
        <div className="attributes">
          {ORGANIZATION_ATTRIBUTES.map((attribute, index) => (
            <div key={index} className={`attribute attribute-${attribute.id}`}>
              <div className="value">
                {organizationStore.organization.orgData[attribute.id] || '-'}
              </div>
              <div className="type">{attribute.name}</div>
            </div>
          ))}
        </div>
        <div className="hubspot-settings">
          <p className="hubspot-settings-label">HubSpot Configuration</p>
          <OrgHubspotViewSettings orgData={organizationStore.organization.orgData} />
        </div>
        <div className="ad-import">
          <p className="ad-import-label">Active Directory</p>
          <div className="attributes">
            <ADImport
              orgData={organizationStore.organization.orgData}
              className="attribute"
              onAdImportComplete={onAdImportComplete}
            />
          </div>
        </div>
      </div>
      <FlexView
        grow
        hAlignContent="center"
        row="true"
        style={{ position: 'absolute', bottom: 0, left: 220, right: 0 }}
      >
        <Snackbar
          style={{ padding: 20, color: 'white', backgroundColor: 'rgb(6, 16, 33)', fontSize: 16 }}
          toasts={toasts}
          autoFocusAction
          autohide={true}
          onDismiss={dismissToast}
        />
      </FlexView>
    </>
  );
});

export default ViewOrgSettings;
