import { observable, computed, action } from 'mobx';
import firebase from 'firebase/app';

import { SECTIONS } from './Contacts.config';
import { assignIds } from 'global/utils';
import { formatPhoneNumber } from 'global/string.utils';

import firebaseService from 'services/firebase';

import { primaryValidationSchema } from '../Screens/YourAccount/EditContactDetail.validation';
import { filterInvalidAttributes } from './Contacts.utils';
import { parseToHcInfo } from 'global/components/ADImport/helpers';

function getDefaultContactAttributes() {
  const attributes = {};

  SECTIONS.forEach(section => {
    attributes[section.id] = [];
  });

  return attributes;
}

async function getAlreadyCreatedContact(contacts) {
  try {
    const contactsWithDuplicatedPersonalEmail = await firebaseService.withInSearch(
      contacts,
      'contacts',
      'personalEmail'
    );

    const alreadyCreatedContacts = await firebaseService.withInSearch(
      contactsWithDuplicatedPersonalEmail.map(({ data }) => data),
      'users',
      'id',
      '__name__' //https://firebase.google.com/docs/reference/rules/rules.firestore.Resource#__name__
    );

    return contactsWithDuplicatedPersonalEmail
      .filter(({ id }) => alreadyCreatedContacts.some(contact => id === contact.id))
      .map(({ data }) => data);
  } catch (error) {
    throw error;
  }
}

async function convertImportedContacts({
  data,
  ownerId,
  organizationId,
  organization,
  existedContacts,
  collectorId,
  creatorName,
}) {
  const contacts = [];
  const contactsDuplicate = [];
  await Promise.all(
    data.map(async row => {
      const contact = new ContactType();
      try {
        contact[creatorName](row, ownerId, organizationId, organization, collectorId);
        const { emailAddresses } = contact.hcInfo;

        const isEmailDBDuplicate = existedContacts.some(el => {
          return el.hcInfo.emailAddresses.some(email =>
            emailAddresses.some(item => item.value === email.value)
          );
        });
        const isEmailImportedDuplicate = contacts.some(el => {
          return el.hcInfo.emailAddresses.some(email =>
            emailAddresses.some(item => item.value === email.value)
          );
        });

        const isSomePersonalEmail = contact.hcInfo.emailAddresses.some(
          email => email.type === 'Personal'
        );

        // checking on 'organizationId' disables validation on emails for contacts imports
        // (allows only for employees import)
        if (
          organizationId &&
          (isEmailDBDuplicate || isEmailImportedDuplicate || !isSomePersonalEmail)
        ) {
          contactsDuplicate.push(contact);
        } else {
          contacts.push(contact);
        }
      } catch (error) {
        throw error;
      }
    })
  );

  const alreadyCreatedContacts = await getAlreadyCreatedContact(contacts);

  return {
    contacts: contacts.filter(
      ({ hcInfo }) =>
        !alreadyCreatedContacts.some(({ personalEmail }) => personalEmail === hcInfo.personalEmail)
    ),
    contactsDuplicate,
    alreadyCreatedContacts,
  };
}
class ContactType {
  static getCleanHcInfo = () => ({
    id: null,
    ownerId: null,
    collectorId: null,
    organizationId: null,
    viewableBy: {},
    receivedCard: {},
    firstName: '',
    lastName: '',
    jobTitle: '',
    organization: '',
    isActive: true,
    officeId: null,
    cardId: null,
    personalEmail: '',
    role: 'basic', // could be 'basic' or 'admin'
    ownerName: '',
    collectorName: '',
    officeName: '',
    hubspotId: null,
    imageUrl: '',
    cardFrontUrl: '',
    cardBackUrl: '',
    ...getDefaultContactAttributes(),
  });

  @observable receivedCardAccounts = [];
  @observable ownerInfo = {};
  @observable hcInfo = ContactType.getCleanHcInfo();

  clone() {
    const cloned = new ContactType();
    cloned.copyFromContact(this);
    return cloned;
  }

  copyFromContact(contact) {
    this.hcInfo = Object.assign({}, contact.hcInfo);

    SECTIONS.forEach(section => {
      const attributes = contact.hcInfo[section.id];
      const clonedAttributes = attributes.map(attribute => ({ ...attribute }));
      this.setAttributesSection(section.id, clonedAttributes);
    });
  }

  @action clear() {
    this.hcInfo = ContactType.getCleanHcInfo();

    this.receivedCardAccounts = [];
    this.ownerInfo = {};
  }

  _createContact(
    type,
    ownerId = null,
    organizationId = null,
    organization = '',
    collectorId = null
  ) {
    this.hcInfo.platform = 'web';
    this.hcInfo.type = type;
    this.hcInfo.ownerId = ownerId;
    this.hcInfo.organizationId = organizationId;
    this.hcInfo.collectorId = collectorId;
    this.hcInfo.organization = organization;
    this.organization = organization;
    this.viewableBy[ownerId] = true;
  }

  createHcAccountContact(data, ownerId = null) {
    this._createContact('hcAccount', ownerId);
    this.hcInfo = Object.assign(this.hcInfo, data);
  }

  createManualContact(ownerId = null, organizationId) {
    this._createContact('manual', ownerId, organizationId);
    this.hcInfo.id = firebaseService.nextContactId();
  }

  createCsvContact(
    data,
    ownerId = null,
    organizationId = null,
    organization = '',
    collectorId = null
  ) {
    this._createContact('importCSV', ownerId, organizationId, organization, collectorId);

    this.origData = data;

    this.hcInfo.id = firebaseService.nextContactId();
    this.hcInfo.personalEmail = data.emailAddresses.find(el => el.type === 'Personal')?.value || '';
    this.hcInfo.collectorId = collectorId;

    /*
     * If primary validation failed contact won't imported
     * and error will be thrown
     */
    primaryValidationSchema.validateSync(data);

    // secondary invalid attributes will be filtered
    const filtered = filterInvalidAttributes(data);

    this.hcInfo = Object.assign(this.hcInfo, filtered);
  }

  createADContact(
    data,
    ownerId = null,
    organizationId = null,
    organization = '',
    collectorId = null
  ) {
    this._createContact('importAD', ownerId, organizationId, organization, collectorId);
    this.origData = data;
    const filtered = filterInvalidAttributes(parseToHcInfo(data));

    this.hcInfo = Object.assign(this.hcInfo, filtered);
    this.hcInfo.sharedWithOrganization = organizationId;
  }

  userCanEdit(uid) {
    return uid === this.contactOwnerId;
  }

  @computed get type() {
    return this.hcInfo.type;
  }

  @computed get viewableBy() {
    return this.hcInfo.viewableBy;
  }

  set viewableBy(id) {
    // have to use {<the user id>: true} instead of an array to search in firestore
    this.hcInfo.viewableBy[id] = true;
  }

  @computed get id() {
    return this.hcInfo.id;
  }

  set id(value) {
    this.hcInfo.id = value;
  }

  @computed get organizationId() {
    return this.hcInfo.organizationId;
  }

  set organizationId(value) {
    this.hcInfo.organizationId = value;
  }

  @computed get imageUrl() {
    if (this.hcInfo.imageUrl) {
      return { uri: this.hcInfo.imageUrl };
    }
    // let defaultImge = require(`../assets/default-user-image.png`)
    return null;
  }

  set imageUrl(value) {
    this.hcInfo.imageUrl = value;
  }

  @computed get businessCard() {
    if (this.type === 'hcAccount') {
      return this.hcInfo.businessCard;
    }
    return this.hcInfo.businessCard && this.hcInfo.businessCard.url
      ? this.hcInfo.businessCard
      : null;
  }

  set cardUrl(value) {
    this.hcInfo.businessCard = { url: value };
  }

  setDefaultBusinessCard() {
    this.hcInfo.businessCard = { defaultCard: Math.floor(Math.random() * 4) };
  }

  @computed get initials() {
    return [this.firstName, this.lastName].map(name => (name || '').charAt(0)).join('');
  }

  @computed get firstName() {
    return this.hcInfo.firstName?.trim() || '';
  }

  set firstName(value) {
    this.hcInfo.firstName = value ? value.trim() : '';
  }

  @computed get lastName() {
    return this.hcInfo.lastName?.trim() || '';
  }

  set lastName(value) {
    this.hcInfo.lastName = value ? value.trim() : '';
  }

  @computed get jobTitle() {
    return this.hcInfo.jobTitle?.trim() || '';
  }

  set jobTitle(value) {
    this.hcInfo.jobTitle = value ? value.trim() : '';
  }

  @computed get organization() {
    return this.hcInfo.organization?.trim() || '';
  }

  set organization(value) {
    this.hcInfo.organization = value ? value.trim() : '';
  }

  removeEmpties(array) {
    if (array === undefined) {
      array = [];
    }
    return array.filter(() => array.value !== '');
  }

  setAttributesSection(sectionId, attributes) {
    this.hcInfo[sectionId] = this.removeEmpties(attributes);
  }

  @computed get fullName() {
    return (this.firstName + ' ' + this.lastName).trim();
  }

  @computed get isNameFilled() {
    return this.firstName !== '' && this.lastName !== '';
  }

  @computed get officeId() {
    return this.hcInfo.officeId;
  }

  set officeId(value) {
    this.hcInfo.officeId = value;
  }

  @computed get contactOwnerId() {
    return this.hcInfo.ownerId;
  }

  set contactOwnerId(value) {
    this.hcInfo.contactOwnerId = value;
  }

  @computed get ownerName() {
    return this.hcInfo.ownerName?.trim() || '';
  }

  @computed get collectorName() {
    return this.hcInfo.collectorName?.trim() || this.hcInfo.ownerName?.trim() || '';
  }

  @computed get officeName() {
    return this.hcInfo.officeName?.trim() || '';
  }

  @computed get basicInfo() {
    return [
      { type: 'First Name', value: this.firstName },
      { type: 'Last Name', value: this.lastName },
      { type: 'Position', value: this.jobTitle },
      { type: 'Organization', value: this.organization },
    ];
  }

  // send card to multiple people, they must accept the card

  @action async sendCard(shareHash) {
    try {
      var batch = firebase.firestore().batch();

      this.execSend = async function (contactid) {
        if (contactid == null) {
          return;
        }
        const key = 'receivedCard.' + this.id;
        var doc = {};
        doc[key] = true;
        batch.update(firebase.firestore().collection('contacts').doc(contactid), doc);
        if (batch.batchSize === 499) {
          await batch.commit();
          batch = firebase.firestore().batch();
        }
      };
      Object.keys(shareHash).forEach(x => this.execSend(x));
      await batch.commit();
    } catch (e) {
      console.error(e);
    }
  }

  // fetch cards sent to user, pending acceptance

  @action async fetchSent() {
    try {
      for (var key in this.hcInfo.receivedCard) {
        const snapshot = await firebase.firestore().collection('contacts').doc(key).get();
        var contact = new ContactType();
        contact.fromFirestoreDoc(snapshot.data());

        var tmpArray = this.receivedCardAccounts.slice();
        tmpArray.push(contact);
        tmpArray.sort(function (a, b) {
          return a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0;
        });
        this.receivedCardAccounts = tmpArray;
      }
    } catch (e) {
      console.log(e);
    }
  }

  // accept card and send card back
  @action async acceptSent(contact) {
    try {
      // accept card
      contact.viewableBy[this.id] = true;
      await contact.save();

      // send card back
      this.viewableBy[contact.id] = true;
      await this.save();
      await this.dismissSent(contact);
    } catch (e) {
      console.error(e);
    }
  }

  // dismiss sent
  @action async dismissSent(contact) {
    try {
      delete this.hcInfo.receivedCard[contact.id];
      await firebase
        .firestore()
        .collection('contacts')
        .doc(this.id)
        .update('receivedCard', this.hcInfo.receivedCard);
    } catch (e) {
      console.error(e);
    }
  }

  // Firestore docs

  @computed get firestoreDoc() {
    return this.hcInfo;
  }

  @action fromFirestoreDoc(doc) {
    this.hcInfo = Object.assign(this.hcInfo, doc);

    SECTIONS.forEach(section => {
      const attributes = this.hcInfo[section.id] || [];
      this.hcInfo[section.id] = assignIds(attributes);
    });

    this.hcInfo.phoneNumbers = this.hcInfo.phoneNumbers.map(phoneNumber => ({
      ...phoneNumber,
      value: formatPhoneNumber(phoneNumber.value),
    }));

    return this;
  }

  cleanTypeArray(attributes) {
    return attributes
      .filter(attribute => attribute.type.length > 0 && attribute.value.length > 0)
      .map(({ type, value }) => ({ type, value }));
  }

  async save() {
    try {
      SECTIONS.forEach(section => {
        this.hcInfo[section.id] = this.cleanTypeArray(this.hcInfo[section.id]);
      });

      firebase.firestore().collection('contacts').doc(this.id).set(this.hcInfo);
      if (this.origData) {
        firebase
          .firestore()
          .collection('contacts')
          .doc(this.id)
          .collection('originalData')
          .doc('orid')
          .set(this.origData);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async delete() {
    try {
      await firebase.firestore().collection('contacts').doc(this.id).delete();
      return await firebaseService.handleDeleteContact(this.hcInfo);
    } catch (e) {
      console.error(e);
    }
  }
}

class ContactsStoreClass {
  @observable contactsList = [];
  @observable filter = '';

  clear() {
    this.contactsList.clear();
    this.filter = '';
  }

  constructor(account) {
    this.account = account;
  }

  @action addContacts(tempArr) {
    const contactIds = this.contactsList.map(x => x.id);
    const filtered = tempArr.filter(x => !contactIds.includes(x.id));
    this.contactsList.push.apply(this.contactsList, filtered.slice());
  }

  isNewContact(contact) {
    const filtered = this.contactsList.filter(function (x) {
      return contact.id === x.id;
    });
    return filtered.length === 0;
  }

  async saveContactsToDb(tempArr) {
    const batchSize = 250;
    // save contacts to db
    while (tempArr.length > 0) {
      const batch = firebase.firestore().batch();

      this.saveContacts = x => {
        batch.set(firebase.firestore().collection('contacts').doc(x.id), x.firestoreDoc, {
          merge: true,
        });

        batch.set(
          firebase
            .firestore()
            .collection('contacts')
            .doc(x.id)
            .collection('originalData')
            .doc(x.id),
          x.origData,
          { merge: true }
        );
      };

      const slice = tempArr.slice(0, batchSize - 1);
      slice.forEach(x => this.saveContacts(x));

      await batch
        .commit()
        .then(() => {
          console.log('Successfully executed batch.');
        })
        .catch(function (error) {
          console.error('Error updating: ', error.message);
        });
      const shorten = tempArr.slice(batchSize);
      tempArr = shorten;
    }
  }

  async importContacts({
    data,
    ownerId,
    organizationId,
    organization,
    existedContacts,
    collectorId,
    creatorName,
  }) {
    const { contacts, contactsDuplicate, alreadyCreatedContacts } = await convertImportedContacts({
      data,
      ownerId,
      organizationId,
      organization,
      existedContacts,
      collectorId,
      creatorName,
    });

    await this.saveContactsToDb(contacts);

    this.addContacts(contacts);

    return { contacts, contactsDuplicate, alreadyCreatedContacts };
  }

  async importCSVContacts({
    data,
    ownerId,
    organizationId,
    organization,
    existedContacts,
    collectorId,
  }) {
    const { contacts, contactsDuplicate, alreadyCreatedContacts } = await this.importContacts({
      data,
      ownerId,
      organizationId,
      organization,
      existedContacts,
      collectorId,
      creatorName: 'createCsvContact',
    });

    return { contacts, contactsDuplicate, alreadyCreatedContacts };
  }

  async importAdContacts({
    data,
    ownerId,
    organizationId,
    organization,
    existedContacts,
    collectorId,
  }) {
    const { contacts, contactsDuplicate, alreadyCreatedContacts } = await this.importContacts({
      data,
      ownerId,
      organizationId,
      organization,
      existedContacts,
      collectorId,
      creatorName: 'createADContact',
    });

    contacts.forEach(({ hcInfo: { personalEmail } }) => {
      firebaseService.sendWelcomeEmail(personalEmail);
    });

    return { contacts, contactsDuplicate, alreadyCreatedContacts };
  }

  @computed get groupByLastName() {
    var unsortedgroups = {};
    var groups = {};
    var unnamed = [];
    this.contactsList.slice().forEach(
      function (item) {
        const sortName = item.lastName + item.firstName;

        const wordList = item.fullName.split(' ');
        var numMatchedWords = 0;
        for (var i in wordList) {
          if (wordList[i].toUpperCase().startsWith(this.filter.toUpperCase())) {
            numMatchedWords++;
          }
        }
        if (this.filter.length > 0 && numMatchedWords === 0) {
          return;
        }

        if (!sortName) {
          unnamed.push(item);
          return;
        }
        unsortedgroups[sortName.toUpperCase()] = item;
      }.bind(this)
    );

    const sortedKeys = Object.keys(unsortedgroups).sort();
    sortedKeys.forEach(function (key) {
      const groupName = key[0];
      if (groups[groupName]) {
        groups[groupName].push(unsortedgroups[key]);
      } else {
        groups[groupName] = [unsortedgroups[key]];
      }
    });

    if (unnamed.length > 0) {
      groups['#'] = unnamed;
    }

    return groups;
  }

  @computed get groupByLastNameHCAccounts() {
    var unsortedgroups = {};
    var groups = {};
    var unnamed = [];
    this.contactsList
      .filter(x => x.type === 'hcAccount')
      .forEach(
        function (item) {
          const sortName = item.lastName + item.firstName;

          const wordList = item.fullName.split(' ');
          var numMatchedWords = 0;
          for (var i in wordList) {
            if (wordList[i].toUpperCase().startsWith(this.filter.toUpperCase())) {
              numMatchedWords++;
            }
          }
          if (this.filter.length > 0 && numMatchedWords === 0) {
            return;
          }

          if (!sortName) {
            unnamed.push(item);
            return;
          }
          unsortedgroups[sortName.toUpperCase()] = item;
        }.bind(this)
      );

    const sortedKeys = Object.keys(unsortedgroups).sort();
    sortedKeys.forEach(function (key) {
      const groupName = key[0].toUpperCase();
      if (groups[groupName]) {
        groups[groupName].push(unsortedgroups[key]);
      } else {
        groups[groupName] = [unsortedgroups[key]];
      }
    });

    if (unnamed.length > 0) {
      groups['#'] = unnamed;
    }

    return groups;
  }
}

export { ContactsStoreClass, ContactType };
