import React, { useState } from 'react';

import FlexView from 'react-flexview/lib';

import { ModalDialog } from './ModalDialog';
import ConfirmFileUpload from './ConfirmFileUpload';
import DropFileUpload from './DropFileUpload';
import Button from './Button';

const ImportCSVScreen = ({ title, csvHeader, note, onComplete, csvParser }) => {
  const [file, setFile] = useState('');

  const onImport = () => {
    const reader = new FileReader();

    reader.addEventListener('load', e => {
      try {
        csvParser(e.target.result, data => {
          onComplete(data);
        });
      } catch (e) {
        alert(e.message);
      }
    });

    reader.readAsText(file);
  };

  const onDelete = () => setFile('');

  const onClose = () => onComplete();

  return (
    <ModalDialog
      title={title}
      onClose={onClose}
      footer={
        <Button
          caption={title}
          className="button blue"
          icon="md-checkmark-circle-outline"
          disabled={file === ''}
          onClick={onImport}
        />
      }
    >
      {!file.name || file.name.length === 0 ? (
        <DropFileUpload
          filename={setFile}
          fileType="csv"
          text={
            <FlexView
              height="100%"
              hAlignContent="center"
              vAlignContent="center"
              column
              style={{ paddingLeft: '5px', paddingRight: '5px' }}
            >
              <FlexView style={{ marginBottom: 20, minHeight: 'auto' }}>
                <p style={{ marginTop: 0, marginBottom: 0 }}>
                  Drag and drop a .csv or click <span style={{ color: '#2A79FF' }}>here</span> to
                  select files to upload
                </p>
              </FlexView>
              <FlexView style={{ marginBottom: 20, minHeight: 'auto' }}>
                Available column headers:
              </FlexView>
              <FlexView style={{ textAlign: 'center', marginBottom: 20, minHeight: 'auto' }}>
                {csvHeader}
              </FlexView>
              <FlexView style={{ textAlign: 'center', minHeight: 'auto' }}>{note}</FlexView>
            </FlexView>
          }
        />
      ) : (
        <ConfirmFileUpload filename={file.name} onDelete={onDelete} />
      )}
    </ModalDialog>
  );
};

export default ImportCSVScreen;
