import { observable, action } from 'mobx';
import firebaseService from 'services/firebase';
import firebase from 'firebase/app';

class CardType {
  static getCardObject = () => ({
    id: null,
    title: '',
    width: null,
    x: null,
    boundingRect: {
      width: null,
      height: null,
      y: null,
      x: null,
    },
    originType: null,
    y: null,
    height: null,
  });

  static getCardTemplate = () => ({
    id: null,
    name: '',
    ownerId: null,
    activeVersionId: null,
    versions: null,
    isDefaultCard: 'false',
  });

  @observable data = CardType.getCardTemplate();

  saveCard(organizationId, data) {
    this.data = {
      id: data.id || firebaseService.nextCardId(),
      ownerId: firebaseService.getUserId(),
      organizationId,
      name: data.name,
      activeVersionId: data.activeVersionId || null,
      versions: data.versions || null,
      isDefaultCard: data.isDefaultCard,
    };
  }

  async save() {
    try {
      const { data } = this;
      return await firebaseService.saveCard(data.id, data);
    } catch (e) {
      console.error(e);
    }
  }

  async delete() {
    try {
      await firebase.firestore().collection('cards').doc(this.data.id).delete();
    } catch (e) {
      console.error(e);
    }
  }

  @action fromFirestoreDoc(doc) {
    this.data = Object.assign(this.data, doc);
    return this.data;
  }
}

export { CardType };
