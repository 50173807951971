import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { inject } from 'mobx-react';
import FlexView from 'react-flexview/lib';

import '../Common/table.css';
import ContactDetail from './ContactDetail';
import ImportCSVScreen from '../Common/ImportCSV';

import { Snackbar } from 'react-md';
import { ContactType } from '../../Models/Contacts';
import OfficeType from 'Models/OfficeType';
import { isConfirmLooseChanges, processAlreadyCreatedContacts } from '../../utils/helpers';
import { BAR_MODE } from '../../utils/constants';

import Button from '../Common/Button';
import { Table, TableCellFullName } from '../../global/components/Table';
import DebounceInput from '../../global/components/DebounceInput';
import { useInfiniteFirebaseQuery, useSharedContactUpdate } from '../../global/hooks';
import { OrganizationType } from '../../Models/Organization';

import styles from '../Common/TablePage.module.css';
import ContactStore from 'Models/Contact.store';
import { parseContactsCsv } from 'Models/Contacts.utils';
import firebaseService from 'services/firebase';

const convertOrderBy = orderBy => {
  if (orderBy.field === 'hcInfo') {
    return [
      { field: 'firstName', direction: orderBy.direction },
      { field: 'lastName', direction: orderBy.direction },
    ];
  }

  if (['organization', 'jobTitle'].includes(orderBy.field)) {
    return [
      orderBy,
      { field: 'firstName', direction: 'asc' },
      { field: 'lastName', direction: 'asc' },
    ];
  }

  return [orderBy];
};

const YourContactsScreen = inject(
  'accountStore',
  'yourContactStore'
)(props => {
  const [toasts, setToasts] = useState([]);
  const [search, setSearch] = useState('');

  const [orderBy, setOrderBy] = useState({ field: 'hcInfo', direction: 'asc' });
  const [showImportContacts, setShowImportContacts] = useState(false);
  // const [contacts, setContacts] = useState([]);
  const [selectedContact, setContact] = useState(null);
  const [isImportSuccess, setIsImportSuccess] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [barMode, setBarMode] = useState(null);
  const [contactsCards, setContactsCards] = useState([]);

  const { data: organizations } = useInfiniteFirebaseQuery('organizations', {
    adapter: organization => {
      const org = new OrganizationType();
      org.fromFirestoreDoc(organization);
      return org;
    },
    queryAdapter: query =>
      query.where('id', '==', props.yourContactStore.contactInfo.organizationId),
    limit: 1,
  });

  const organization = organizations[0] || {};

  const { data: officesList } = useInfiniteFirebaseQuery('offices', {
    queryAdapter: query =>
      query.where('organizationId', '==', props.yourContactStore.contactInfo.organizationId),
    adapter: doc => new OfficeType().fromFirestoreDoc(doc),
  });

  const officesObj = useMemo(() => {
    return (
      officesList.reduce((acc, office) => {
        return { ...acc, [office.data.id]: office.data };
      }, {}) || {}
    );
  }, [officesList]);

  const getContact = useCallback(async id => {
    if (id) {
      const employee = await firebaseService.getContact(id);

      return new ContactType().fromFirestoreDoc(employee);
    }
  }, []);

  const {
    data: contactsData,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    refetch,
  } = useInfiniteFirebaseQuery('contacts', {
    adapter: contact => new ContactType().fromFirestoreDoc(contact),
    // Temporary solution because of firebase implementation.
    // Contacts created by user only. No 'shared' contacts.
    queryAdapter: query => query.where('ownerId', '==', firebaseService.getUserId()),
    orderBy: convertOrderBy(orderBy),
    search,
  });

  useEffect(() => {
    const getContactsCards = async () => {
      const dirtyContactsCards = await Promise.all(
        contactsData.map(async ({ hcInfo: { cardId, officeId, organizationId } }) => {
          if (cardId) {
            return await firebaseService.getCard(cardId);
          }
          if (officeId) {
            const office = await firebaseService.getOffice(officeId);

            if (office.cardId) {
              return await firebaseService.getCard(office.cardId);
            }
          }
          if (organizationId) {
            const organization = await firebaseService.getOrganization(organizationId);

            if (organization.defaultCardId) {
              return await firebaseService.getCard(organization.defaultCardId);
            }
          }
        })
      );

      setContactsCards(dirtyContactsCards.filter(card => card));
    };

    if (contactsData?.length) {
      getContactsCards();
    }
  }, [contactsData]);

  useEffect(() => {
    getContact(selectedContact?.id)
      .then(contact => {
        setContact(contact);
      })
      .catch(error => {
        alert(error.toString());
      });
  }, [contactsData]);

  // this code hide user from contacts list
  const contacts = useMemo(() => contactsData.filter(x => x.id !== props.accountStore.id), [
    contactsData,
    props.accountStore,
  ]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'hcInfo',
        Cell: props =>
          TableCellFullName({
            ...props,
            cardsList: contactsCards,
            officesData: officesObj,
            organization,
          }),
      },
      { Header: 'Company', accessor: 'organization' },
      { Header: 'Title', accessor: 'jobTitle' },
    ],
    [officesObj, contactsCards, organization]
  );

  const debounceInputRef = useRef();

  useSharedContactUpdate(selectedContact);

  useEffect(() => {
    debounceInputRef.current?.focus();
  }, [debounceInputRef]);

  const isEditing = barMode === BAR_MODE.EDIT || barMode === BAR_MODE.NEW;

  const dismissToast = () => {
    const [, ...remain] = toasts;
    setToasts(remain);
  };

  const setToast = message => {
    setToasts([...toasts, { text: message }]);
  };

  const checkIfLooseChanges = () =>
    isConfirmLooseChanges({ isDirty, isEditing, onApprove: () => setIsDirty(false) });

  const clickImport = () => {
    if (checkIfLooseChanges()) {
      return;
    }
    setShowImportContacts(true);
  };

  const clickAdd = e => {
    if (checkIfLooseChanges()) {
      return;
    }

    const contact = new ContactType();
    contact.createManualContact(props.accountStore.id);

    setContact(contact);
    setBarMode(BAR_MODE.NEW);

    // history.push(`${basePath}/new/${contact.id}`);
  };

  const onRowClick = (index, { id }) => {
    if (checkIfLooseChanges()) {
      return;
    }

    setContact(contacts[index]);
    setIsDirty(false);
    setBarMode(BAR_MODE.VIEW);
    // history.push(`${basePath}/view/${id}`)
  };

  const onEditClick = id => {
    if (checkIfLooseChanges()) {
      return;
    }

    getContact(id)
      .then(contact => {
        setContact(contact);
        setBarMode(BAR_MODE.EDIT);
      })
      .catch(error => {
        alert(error.toString());
      });

    // history.push(`${basePath}/edit/${id}`)
  };

  // const onEditHandler = () => history.push(`${basePath}/edit/${contactId}`);

  const onCloseHandler = () => {
    setIsDirty(false);
    setBarMode(null);
    setContact(null);

    // history.push(basePath)
  };

  const onDeleteClick = id => {
    if (checkIfLooseChanges()) {
      return;
    }

    const contact = contactsData.find(x => x.id === id);

    if (window.confirm(`Delete ${contact.firstName} ${contact.lastName} Contact?`)) {
      contact.delete().then(() => {
        // history.push(basePath);
        setBarMode(null);
        setContact(null);
        setToast('Contact Deleted');
        refetch();
      });
    }
  };

  const onSaveHandler = async () => {
    setToast('Contact Saved');

    if (barMode === BAR_MODE.EDIT) {
      // Refetch only "selectedContact" if it is "Editing Mode"
      await refetch(selectedContact);
    } else {
      // Otherwise it is a new contact
      // Refetch all
      await refetch();
    }

    setIsDirty(false);
    setBarMode(BAR_MODE.VIEW);
    // history.push(`${basePath}/view/${selectedContact.id}`);
  };

  const actions = useCallback(
    ({ cell: { value } }) => [
      {
        caption: 'Edit',
        onClick: onEditClick,
        icon: 'ios-create-outline',
      },
      {
        primary: true,
        caption: 'Delete',
        onClick: onDeleteClick,
        icon: 'ios-trash-outline',
      },
    ],
    [contactsData, isDirty, isEditing]
  );

  const onImportComplete = data => {
    if (data) {
      props.accountStore
        .importCSVContacts({
          organizationId: organization.id,
          organizationName: organization.name,
          data,
          existedContacts: contactsData,
        })
        .then(result => {
          setIsImportSuccess(true);
          processAlreadyCreatedContacts(
            organization,
            props.accountStore,
            result.alreadyCreatedContacts
          ).then(isAlreadyCreatedContactsChanged => {
            const addedContactsCount = isAlreadyCreatedContactsChanged
              ? [...result.contacts, ...result.alreadyCreatedContacts].length
              : result.contacts.length;
            const alreadyExistContactsCount = !isAlreadyCreatedContactsChanged
              ? [...result.contactsDuplicate, ...result.alreadyCreatedContacts].length
              : result.contactsDuplicate.length;
            refetch();
            setToast(`${addedContactsCount} contacts created.
          ${alreadyExistContactsCount} contacts skipped.`);
          });
        })
        .catch(error => {
          alert(error.toString());
        });
    }

    setShowImportContacts(false);
  };

  // useEffect(() => {
  // Temporary solution because of firebase implementation.
  // Remove user profile contact from user's contacts list.
  // const _contacts = contactsData.filter((x) => x.id !== firebase.auth().currentUser.uid);
  // setContacts(_contacts);

  // if (selectedContact) {
  //   setSelectedContact(_contacts.find((contact) => contact.id === selectedContact.id) || null);
  // }
  // }, [contactsData]);

  // useEffect(() => {
  //   if (isCreating) {
  //     return;
  //   }

  //   setSelectedContact(contactsData.find((x) => x.id === contactId) || null);
  // }, [contactsData, contactId]);

  const isEmpty = !isFetching && !isFetchingMore && !contacts.length && !search;

  const NoContacts = (
    <div className={styles.noItemsContainer}>
      <p>You don&rsquo;t have contacts here yet. Let&rsquo;s add some</p>

      <Button
        onClick={clickImport}
        icon="md-cloud-upload"
        caption="Import contacts from .csv file"
      />
      <Button onClick={clickAdd} icon="md-add" caption="Add a contact manually" />
    </div>
  );

  return (
    <FlexView grow style={{ backgroundColor: 'white', height: '100%' }}>
      <FlexView column grow style={{ height: '100%' }}>
        <FlexView
          width="100%"
          height={60}
          row="true"
          vAlignContent="center"
          style={{ paddingRight: 20, paddingLeft: 20, fontWeight: 'bold', minHeight: 60 }}
        >
          <FlexView>Contacts</FlexView>
          <FlexView grow row="true" width={220} hAlignContent="right">
            <DebounceInput
              onChange={setSearch}
              value={search}
              ref={debounceInputRef}
              debounce={250}
              placeholder="Search"
              icon="ios-search"
              style={{ marginRight: 8, maxWidth: 400 }}
            />
            <Button onClick={clickAdd} icon="md-add" caption="Add" style={{ marginRight: 8 }} />
            <Button onClick={clickImport} icon="md-cloud-upload" caption="Import" />
          </FlexView>
        </FlexView>

        <FlexView column style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
          <Table
            columns={columns}
            onRowClick={onRowClick}
            data={contacts}
            actions={actions}
            onFetchMore={fetchMore}
            // Temporary solution because of firebase implementation.
            // User's itself contact is available in 'contactsData' but hidden by 'queryAdapter' filter.
            // So length for the empty 'contactsData' is 1
            canFetchMore={canFetchMore && contactsData.length > 1}
            isFetching={isFetching}
            isFetchingMore={isFetchingMore}
            orderBy={orderBy}
            onOrderByClick={setOrderBy}
            highlight={search}
            isSidebarOpen={barMode}
            isImportSuccess={isImportSuccess}
            resetImportSuccess={() => setIsImportSuccess(false)}
            isEmpty={isEmpty}
            emptyMessage={NoContacts}
            activeId={selectedContact?.id}
            columnsToUpdateDependency={{ isDirty, barMode, officesObj }}
          />
        </FlexView>
      </FlexView>

      {/* <Route
        path={`${basePath}/:mode/:contactId`}
        render={() => ( */}
      {selectedContact && (
        <div style={{ width: '45%' }} className={styles.detailsPanel}>
          <ContactDetail
            titles={{ new: 'New contact', view: 'Contact:' }}
            contactInfo={selectedContact || {}}
            store={new ContactStore(selectedContact)}
            theme="detail"
            onClose={onCloseHandler}
            isEditing={isEditing}
            onEdit={onEditClick}
            onSave={isSaved => (isSaved ? onSaveHandler() : onCloseHandler())}
            offices={{ list: officesList, data: officesObj }}
            organization={organization?.orgData}
            onDirtyChanged={setIsDirty}
            createLeadHandler={async () => {
              await refetch();
            }}
            mode={barMode}
            type="yourContact"
          />
        </div>
      )}
      {/* /> */}
      {showImportContacts && (
        <ImportCSVScreen
          title="Import Contacts"
          onComplete={onImportComplete}
          csvHeader="first_name, last_name, job_title, organization, website, phone_type_1, phone_1, email_type_1, email_1, physical_address_type_1, physical_address_1, social_media_type_1, social_media_1"
          note="To add more phone numbers, physical addresses, emails, websites, or social handles, just increment the numbers"
          csvParser={parseContactsCsv}
        />
      )}
      <FlexView
        grow
        hAlignContent="center"
        row="true"
        style={{ position: 'absolute', bottom: 0, left: 220, right: 0 }}
      >
        <Snackbar
          style={{ padding: 20, color: 'white', backgroundColor: 'rgb(6, 16, 33)', fontSize: 16 }}
          toasts={toasts}
          autoFocusAction
          autohide={true}
          onDismiss={dismissToast}
        />
      </FlexView>
    </FlexView>
  );
});

export default YourContactsScreen;
