import React, { useRef } from 'react';
import Ionicon from 'react-ionicons';
import { useOnClickOutside } from '../../../global/hooks';

import classes from './ModalDialog.module.css';

const ModalDialog = ({ className, footer, onClose, title, children }) => {
  const ref = useRef();

  useOnClickOutside(ref, onClose);

  return (
    <div ref={ref} className={`${classes.modalDialogWrapper} ${className}`} onClick={onClose}>
      <div className={classes.modalDialog} onClick={e => e.stopPropagation()}>
        <header>
          <p>{title}</p>
          <button className={classes.closeButton} onClick={onClose}>
            <Ionicon icon="md-close" fontSize="20px" color="black" />
          </button>
        </header>
        <div className={classes.content}>{children}</div>
        <footer>{footer}</footer>
      </div>
    </div>
  );
};
export default ModalDialog;
