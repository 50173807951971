import React, { memo } from 'react';

import { CARD_FIELDS } from '../../config';
import DraggableItem from '../DraggableItem';
import styles from './FieldListBar.module.css';

const FieldListBar = ({ onAddNewField }) => {
  return (
    <aside className={styles.root}>
      <h3 className={styles.title}>Available Data</h3>
      <div>
        {CARD_FIELDS.map(field => {
          return (
            <DraggableItem
              showOriginType={true}
              key={field.key}
              field={field}
              className={styles.draggableItem}
              onAddNewField={onAddNewField}
            />
          );
        })}
      </div>
    </aside>
  );
};

export default memo(FieldListBar);
