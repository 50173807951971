import {
  ADDRESS_TYPES,
  PHONE_TYPES,
  SOCIAL_MEDIA_TYPES,
  PHYSICAL_ADDRESS_TYPES,
} from 'Models/Contacts.config';

export const CARD_FIELDS = [
  { key: 'user-name', title: 'Name', originType: 'User' },
  { key: 'user-jobTitle', title: 'Title', originType: 'User' },
  { key: 'user-organization', title: 'Organization', originType: 'User' },
  { key: 'user-website', title: 'Website', originType: 'User' },
  ...ADDRESS_TYPES.map(type => ({
    key: `email-${type}`,
    title: `Mailing Address - ${type}`,
    originType: 'User',
  })),
  ...PHONE_TYPES.map(type => ({
    key: `phone-${type}`,
    title: `Phone - ${type}`,
    originType: 'User',
  })),
  ...SOCIAL_MEDIA_TYPES.map(type => ({
    key: `social-${type}`,
    title: `Social - ${type}`,
    originType: 'User',
  })),
  ...PHYSICAL_ADDRESS_TYPES.map(title => ({
    key: `user-${title}`,
    title,
    originType: 'User',
  })),

  { key: 'office-primary_phone', title: 'Phone - Work', originType: 'Office' },
  { key: 'office-primary_mailing', title: 'Mailing Address - Work', originType: 'Office' },
  { key: 'office-primary_fax', title: 'Fax', originType: 'Office' },
  { key: 'office-primary_website', title: 'Website', originType: 'Office' },

  { key: 'org-name', title: 'Name', originType: 'Organization' },
  { key: 'org-phoneNumber', title: 'Phone - Work', originType: 'Organization' },
  { key: 'org-emailAddress', title: 'Mailing Address - Work', originType: 'Organization' },
  { key: 'org-website', title: 'Website', originType: 'Organization' },
  { key: 'org-faxNumber', title: 'Fax Number', originType: 'Organization' },
];
