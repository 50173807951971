import React, { useEffect, useMemo, useState } from 'react';

import Card from 'global/components/Card';
import { CARD_SIDE, CARD_CONTAINER_SIZE_LABELS } from 'utils/constants';
import { getContactCardImages, getInitials } from 'utils/helpers';
import HighlightedJSX from '../HighlightedJSX';
import { CardType } from '../../../Models/Cards';
import firebaseService from 'services/firebase';
import styles from './TableCellFullName.module.css';

const CARD_CONTAINER = {
  [CARD_CONTAINER_SIZE_LABELS.DEFAULT]: { width: 50, height: 30 },
  [CARD_CONTAINER_SIZE_LABELS.NON_STANDARD]: { width: 30, height: 30 },
};

const TableCellFullName = ({
  cell: {
    value: {
      cardId,
      firstName,
      lastName,
      imageUrl,
      cardFrontUrl,
      cardBackUrl,
      officeId,
      organization: orgName,
    },
  },
  matcher,
  cardsList = [],
  officesData,
  organization = {},
}) => {
  const [orgCard, setOrgCard] = useState(null);
  const [officeCard, setOfficeCard] = useState(null);
  const [defaultCard, setDefaultCard] = useState(null);

  useEffect(() => {
    let unmounted = false;
    const getDefaultCard = async (organization, orgName, cardsList) => {
      if (organization.name === orgName) {
        return cardsList.find(({ id }) => id === organization.defaultCardId);
      }

      if (orgName) {
        const organization = await firebaseService.getOrganizationByName(orgName);
        if (organization.defaultCardId) {
          const defaultCard = await firebaseService.getCard(organization.defaultCardId);
          return new CardType().fromFirestoreDoc(defaultCard);
        }
      }
    };

    getDefaultCard(organization, orgName, cardsList)
      .then(defaultCard => {
        if (defaultCard && !unmounted) {
          setDefaultCard(defaultCard);
        }
      })
      .catch(error => {
        alert(error.toString());
      });

    return () => {
      unmounted = true;
    };
  }, [organization, orgName, cardsList]);

  useEffect(() => {
    const officeCardId = officesData?.[officeId]?.cardId || null;
    const officeCard = officeCardId ? cardsList.find(({ id }) => id === officeCardId) : null;
    if (officeCard) {
      setOfficeCard(officeCard);
    }
  }, [officesData, cardsList]);

  useEffect(() => {
    const orgCard = cardId ? cardsList.find(({ id }) => id === cardId) : null;
    if (orgCard) {
      setOrgCard(orgCard);
    }
  }, [cardId, cardsList]);

  const activeCardVersion = useMemo(() => {
    const assignedCard = orgCard || officeCard || defaultCard || null;

    return assignedCard?.versions?.find(({ id }) => id === assignedCard?.activeVersionId);
  }, [orgCard, officeCard, defaultCard]);

  const userInitials = getInitials(firstName, lastName);

  const { frontOfTheCard, isCardUploadedByUser } = getContactCardImages({
    cardFrontUrl,
    cardBackUrl,
    frontUrl: activeCardVersion?.frontUrl,
    backUrl: activeCardVersion?.backUrl,
    hasAssignedCard: !!activeCardVersion,
  });

  return (
    <div className={styles.root}>
      <span className={styles.name}>
        <HighlightedJSX value={firstName} matcher={matcher} />{' '}
        <HighlightedJSX value={lastName} matcher={matcher} />
      </span>

      <div className={styles.cardBlock}>
        {imageUrl ? (
          <img className={styles.profileImage} src={imageUrl} alt="Profile" />
        ) : (
          <div className={styles.profileImagePlaceholder}>{userInitials}</div>
        )}

        <Card
          card={activeCardVersion}
          side={CARD_SIDE.FRONT}
          url={frontOfTheCard}
          containerSize={CARD_CONTAINER}
          className={styles.card}
          isCardUploadedByUser={isCardUploadedByUser}
          placeholder="No Card"
        />
      </div>
    </div>
  );
};

export default TableCellFullName;
