import React, { useState } from 'react';
import Ionicon from 'react-ionicons';

import { validateEmail as isEmailValid } from 'global/validation';

import LoginInput from './LoginInput';

import styles from './Login.module.css';

const ResetPasswordForm = props => {
  const { errorMessage = '', isEmailSent, onComplete, onError, onResetEmail } = props;
  const [email, setEmail] = useState(props.email);

  const validateEmail = value => {
    if (!isEmailValid(value)) {
      return 'Invalid email address';
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const error = validateEmail(email);

    if (error) {
      return onError(error);
    }

    return onResetEmail(email);
  };

  return (
    <form
      className={`${styles.loginForm} ${styles.resetPasswordForm}`}
      noValidate
      onSubmit={handleSubmit}
    >
      <div className={styles.title}>{isEmailSent ? 'Check your Email' : 'Reset Password'}</div>

      <div className={styles.inputWrapper}>
        {isEmailSent ? (
          <div className={styles.emailSentText}>
            <p>Reset password link has been sent to your registered email address.</p>
            <p>Please click the link to set a new password.</p>
          </div>
        ) : (
          <>
            <LoginInput text={email} type="email" onChange={setEmail} />

            {errorMessage ? (
              <p className={styles.error}>{errorMessage}</p>
            ) : (
              <p>Please enter your registered email to reset your password.</p>
            )}
          </>
        )}
      </div>

      <div className={styles.footer}>
        {isEmailSent ? (
          <button onClick={onComplete}>
            <span>Return to Login</span>
          </button>
        ) : (
          <button disabled={email.length === 0}>
            <span>Send Email</span>
            <Ionicon
              className={styles.icon}
              icon="md-arrow-round-forward"
              fontSize="15px"
              color="white"
            />
          </button>
        )}
      </div>
    </form>
  );
};

export default ResetPasswordForm;
