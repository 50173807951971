import { array, object, string } from 'yup';

import { SECTIONS } from 'Models/Contacts.config';
import { PHONE_NUMBER_PATTERN } from 'global/validation';
import { toAbsoluteUrl } from 'global/string.utils';

const primaryAttributes = {
  firstName: string().when('lastName', {
    is: value => !value,
    then: string().required('One of the fields, Last Name or First Name, should be filled.'),
  }),
  lastName: string(),
  organization: string(),
  jobTitle: string(),
};

const required = () => string().required();

const types = {
  emailAddresses: required().email(),
  phoneNumbers: required().matches(PHONE_NUMBER_PATTERN),
  physicalAddresses: required(),
  socialMedia: required().transform(toAbsoluteUrl).url(),
  websites: required().transform(toAbsoluteUrl).url(),
};

const secondaryAttributes = SECTIONS.reduce(
  (acc, section) => ({
    ...acc,
    [section.id]: array()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value;
        }
        return originalValue ? [...originalValue] : [];
      })
      .of(
        object().shape({
          value: types[section.id],
        })
      ),
  }),
  {}
);

export const primaryValidationSchema = object().shape(primaryAttributes);

export const secondaryValidationSchema = object().shape(secondaryAttributes);

export default object().shape({ ...primaryAttributes, ...secondaryAttributes });
