import { PATH, PATH_ACTION } from 'utils/constants';

import YourAccountInfoScreen from './YourAccount/YourAccountInfo';
import YourCardScreen from './YourAccount/YourCard';
import YourContactsScreen from './YourAccount/YourContacts';

import OrgEmployeesScreen from './Organization/OrgEmployees';
import OrgCardsScreen from './Organization/OrgCards';
import CardEditor from '../Screens/CardEditor';
import OrgContactsScreen from './Organization/OrgContacts';
import OrgOfficesScreen from './Organization/OrgOffices';
import ViewOrgSettings from './Organization/ViewOrgSettings';
import EditOrgSettings from './Organization/EditOrgSettings';

export default [
  {
    path: 'YourAccount',
    component: YourAccountInfoScreen,
  },
  {
    path: 'YourCard',
    component: YourCardScreen,
  },
  {
    path: 'YourContacts/:mode?/:contactId?',
    component: YourContactsScreen,
  },
  {
    path: 'OrganizationEmployees',
    component: OrgEmployeesScreen,
  },
  {
    path: PATH.ORGANIZATION_CARDS,
    component: OrgCardsScreen,
  },
  {
    path: `${PATH.ORG_CARD_EDITOR}/${PATH_ACTION.CREATE}/:cardId`,
    component: CardEditor,
  },
  {
    path: `${PATH.ORG_CARD_EDITOR}/${PATH_ACTION.EDIT}/:cardId/:versionId`,
    component: CardEditor,
  },
  {
    path: 'OrganizationSettings',
    component: ViewOrgSettings,
  },
  {
    path: 'EditOrganizationSettings',
    component: EditOrgSettings,
  },
  {
    path: 'OrganizationContacts/:mode?/:contactId?',
    component: OrgContactsScreen,
  },
  {
    path: 'OrganizationOffices/:mode?/:officeId?/:tab?',
    component: OrgOfficesScreen,
  },
];
