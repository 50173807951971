import React, { useState, useMemo } from 'react';
import { Snackbar } from 'react-md';
import FlexView from 'react-flexview/lib';

import ViewContactDetail from './ViewContactDetail';
import EditContactDetail from './EditContactDetail';

import './ContactDetail.css';
import '../Common/ContactPictures.css';
import { noop } from 'global/utils';

const ContactDetail = ({
  onSave = noop,
  onEdit = noop,
  onDirtyChanged = noop,
  isEditing,
  isHubspotBtnVisible = true,
  ...props
}) => {
  const [snackText, setSnackText] = useState('');
  const [activeSectionIndex, setActiveSectionIndex] = useState(null);

  const contactInfo = props.contactInfo || props.store.contactInfo;

  const dismiss = () => setSnackText('');

  const title = useMemo(() => {
    const { firstName, lastName } = contactInfo?.hcInfo || {};
    const editingTitle = contactInfo.hcInfo?.firstName
      ? `Editing ${firstName} ${lastName}`
      : props.titles.new;
    return isEditing ? editingTitle : `${props.titles.view} ${firstName} ${lastName}`;
  }, [contactInfo, isEditing]);

  const renderChoice = () => {
    if (!contactInfo.hcInfo) {
      return null;
    }

    if (isEditing) {
      return (
        <EditContactDetail
          title={title}
          theme={props.theme}
          activeSectionIndex={activeSectionIndex}
          store={props.store}
          contactInfo={contactInfo}
          onComplete={saved => {
            onSave(saved);
            if (saved) {
              setSnackText('Contact Saved');
            }
          }}
          onDirtyChanged={onDirtyChanged}
          organization={props.organization}
          offices={props.offices}
          type={props.type}
          mode={props.mode}
          isShowMessage={props.isShowMessage}
        />
      );
    }

    return (
      <ViewContactDetail
        title={title}
        contact={contactInfo}
        theme={props.theme}
        onClose={props.onClose}
        onSaveNotes={props.store.saveNotes}
        onEditClicked={activeSectionIndex => {
          onEdit(contactInfo.id);
          setActiveSectionIndex(activeSectionIndex);
        }}
        onCreateLead={props.createLeadHandler}
        offices={props.offices}
        type={props.type}
        organization={props.organization}
        isHubspotBtnVisible={isHubspotBtnVisible}
      />
    );
  };

  return (
    <>
      {renderChoice()}

      <FlexView
        grow
        hAlignContent="center"
        row="true"
        style={{ position: 'absolute', bottom: 0, left: 220, right: 0 }}
      >
        <Snackbar
          style={{ padding: 20, color: 'white', backgroundColor: 'rgb(6, 16, 33)', fontSize: 16 }}
          toasts={snackText.length > 0 ? [{ text: snackText }] : []}
          autoFocusAction
          autohide={true}
          onDismiss={dismiss}
        />
      </FlexView>
    </>
  );
};

export default ContactDetail;
