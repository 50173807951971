import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Ionicon from 'react-ionicons';

import styles from './Login.module.css';

const commonAttributes = {
  autoFocus: true,
  className: styles.formControl,
  variant: 'outlined',
};

const EmailInput = ({ text, onChange }) => (
  <TextField
    {...commonAttributes}
    label="Email Address"
    value={text}
    onChange={event => onChange(event.target.value)}
    type="email"
    autoComplete="email"
  />
);

class PasswordInput extends Component {
  static ICONS = ['ios-eye', 'ios-eye-off'];

  constructor(props) {
    super(props);

    this.state = { showPassword: false };
  }

  toggle = () => {
    this.input.focus();

    setTimeout(() => {
      this.input.selectionStart = this.input.selectionEnd = this.input.value.length;
    });

    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  render() {
    return (
      <TextField
        {...commonAttributes}
        inputRef={ref => {
          this.input = ref;
        }}
        label="Password"
        value={this.props.text}
        onChange={event => this.props.onChange(event.target.value)}
        type={this.state.showPassword ? 'text' : 'password'}
        autoComplete="current-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Ionicon
                className={styles.icon}
                onClick={this.toggle}
                style={{ marginLeft: 8 }}
                icon={PasswordInput.ICONS[+this.state.showPassword]}
                fontSize="24px"
                color="lightgrey"
              />
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

export default class LoginInput extends Component {
  render() {
    const { type, text, onChange } = this.props;
    const Component = type === 'password' ? PasswordInput : EmailInput;

    return <Component text={text} onChange={onChange} />;
  }
}
