import { observable } from 'mobx';

import firebaseService from 'services/firebase';
import { OrganizationType } from './Organization';
import { noop } from 'global/utils';

export default class OrganizationStore {
  @observable isLoading = true;

  static getInstance() {
    if (!this._instance) {
      this._instance = new OrganizationStore();
    }

    return this._instance;
  }

  constructor() {
    this.organization = new OrganizationType();
  }

  subscribe(callback = noop) {
    this.isLoading = true;

    this._unsubsribe = firebaseService.subscribeOnOrganization(
      firebaseService.getUserId(),
      orgDoc => {
        this.organization.getOrgFonts(orgDoc.id);
        this.organization.fromFirestoreDoc(orgDoc);
        this.isLoading = false;
        callback(this.organization);
      }
    );
  }

  onOrganizationDelete(callback) {
    this.onDelete = callback;
  }

  createOrganization() {
    let org = new OrganizationType();
    org = org.createNew(firebaseService.getUserId());
    org.save();
    this.organization = org;
    return org;
  }

  async deleteOrganization() {
    await firebaseService.deleteOrganization(this.organization.id);
    this.organization = new OrganizationType();
    this.onDelete();
  }

  done() {
    this._unsubsribe();
    this.organization.clear();
  }
}
