export function nextId(items) {
  if (items.length === 0) {
    return 0;
  }
  return Math.max(...items.map(item => item?.id || items.length - 1)) + 1;
}

export function assignIds(items) {
  return items.map((item, index) => ({
    id: index,
    ...item,
  }));
}

export const noop = () => {};

export const indentity = value => value;

export const isEqual = require('lodash.isequal');

export const invokeOnce = fn => {
  let invoked = false;
  let result;

  return (...args) => {
    if (!invoked) {
      invoked = true;
      result = fn(...args);
    }

    return result;
  };
};

export const capitalize = value => {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const createNewAttribute = (section, data) => ({
  id: nextId(data),
  createTime: Date.now(),
  type: section.attributeTypes[section.defaultAttributeType],
  value: '',
});
