import React from 'react';
import { Link } from 'react-router-dom';
import Ionicon from 'react-ionicons';

import {
  validateEmail as globalValidateEmail,
  validatePassword as globalValidatePassword,
} from 'global/validation';

import LoginInput from './LoginInput';

import styles from './Login.module.css';

export default function LoginForm(props) {
  const {
    email,
    password,
    isPasswordMode,
    errorMessage,
    mode,
    setPasswordMode,
    onError,
    onComplete,
    title,
    setPassword,
    setEmail,
    bottomText,
    isEmailInUse,
  } = props;

  const validateEmail = async (email, isLogingIn) => {
    if (!globalValidateEmail(email)) {
      return 'Email address is not formatted properly';
    }

    if (isLogingIn) {
      return;
    }

    const inUse = await isEmailInUse(email);

    if (inUse) {
      return 'Email already in use';
    }
  };

  const validatePassword = () => {
    if (globalValidatePassword(password)) return '';
    return 'Must be 8 characters long, with at least one letter, one number and one special character';
  };

  const complete = async event => {
    event.preventDefault();

    try {
      let error = '';

      if (!isPasswordMode) {
        error = await validateEmail(email, mode === 'login');

        if (error) {
          onError(error);
        } else {
          setPasswordMode();
          onError('');
        }
      } else if (isPasswordMode) {
        error = mode !== 'login' ? validatePassword(password) : '';

        if (error) {
          onError(error);
        } else {
          onComplete(email, password);
          onError('');
        }
      } else {
        onError(error);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <form className={styles.loginForm} noValidate onSubmit={complete}>
      <div className={styles.title}>{title}</div>

      {isPasswordMode && <Link to="./reset-password">Reset Password</Link>}

      <div className={styles.inputWrapper}>
        <LoginInput
          type={isPasswordMode ? 'password' : 'email'}
          text={isPasswordMode ? password : email}
          onChange={value => {
            if (isPasswordMode) {
              setPassword(value);
            } else {
              setEmail(value);
            }
          }}
          onFocus={() => {
            if (errorMessage.length) {
              onError('');
            }
          }}
        />

        {errorMessage.length ? <p className={styles.error}>{errorMessage}</p> : <p>{bottomText}</p>}
      </div>

      <div className={styles.footer}>
        <button disabled={email.length === 0}>
          <span>Next</span>
          <Ionicon
            className={styles.icon}
            icon="md-arrow-round-forward"
            fontSize="15px"
            color="white"
          />
        </button>
      </div>
    </form>
  );
}
