import {
  CARD_FIELD_MAX_FONT_SIZE,
  CARD_FIELD_STYLE_NAME,
  CARD_FIELD_MIN_FONT_SIZE,
  DEFAULT_COLOR,
} from './constants';
import {
  FONT_STYLE,
  DEFAULT_FIELD_VERTICAL_PADDING,
  ALIGNMENT,
  DEFAULT_FONTS,
  CARD_SIDE,
} from 'utils/constants';
import { isColor, getPercentFromNumber } from 'utils/helpers';

// Field config form normalizers onChange
export const normalizeCardFieldStylesOnChange = {
  [CARD_FIELD_STYLE_NAME.X]: ({ value }) => (value === '' ? value : Math.round(value)),
  [CARD_FIELD_STYLE_NAME.Y]: ({ value }) => (value === '' ? value : Math.round(value)),
  [CARD_FIELD_STYLE_NAME.FONT_SIZE]: ({ value }) => (value === '' ? value : Math.round(value)),
  [CARD_FIELD_STYLE_NAME.COLOR]: ({ value }) => value || '#',
  [CARD_FIELD_STYLE_NAME.FONT]: ({ value, orgFonts }) =>
    Object.keys({ ...DEFAULT_FONTS, ...orgFonts })[value],
  [CARD_FIELD_STYLE_NAME.ALIGNMENT]: ({ value }) => Object.keys(ALIGNMENT)[value],
  [CARD_FIELD_STYLE_NAME.FONT_STYLE]: ({ value }) => Object.keys(FONT_STYLE)[value],
};

// Field config form normalizers onBlur
export const normalizeCardFieldStylesOnBlur = {
  [CARD_FIELD_STYLE_NAME.X]: ({ value }) => (value === '' ? 0 : Math.round(value)),
  [CARD_FIELD_STYLE_NAME.Y]: ({ value }) => (value === '' ? 0 : Math.round(value)),
  [CARD_FIELD_STYLE_NAME.FONT_SIZE]: ({ value }) =>
    value === '' ? CARD_FIELD_MIN_FONT_SIZE : Math.round(value),
  [CARD_FIELD_STYLE_NAME.COLOR]: ({ value }) => (value === '#' ? DEFAULT_COLOR : value),
  [CARD_FIELD_STYLE_NAME.FONT]: ({ value }) => value,
  [CARD_FIELD_STYLE_NAME.ALIGNMENT]: ({ value }) => value,
  [CARD_FIELD_STYLE_NAME.FONT_STYLE]: ({ value }) => value,
};

// Field config form validators
const validateXPositionField = ({ value, width }) => {
  const rightPoint = 100 - width;
  let helperText = '';

  if (value > rightPoint) {
    helperText = `Please enter valid value low or equal ${rightPoint}`;
  }
  if (rightPoint < 0) {
    helperText = 'Please make field smaller';
  }
  if (value < 0) {
    helperText = 'Please enter valid value more or equal 0';
  }

  return { error: !!helperText, helperText };
};

const validateYPositionField = ({ value, fontSize, cardHeight }) => {
  const pxHeight = (fontSize || 0) + DEFAULT_FIELD_VERTICAL_PADDING;
  const percentHeight = getPercentFromNumber(pxHeight, cardHeight);
  const bottomPoint = 100 - percentHeight; // 100% - card height
  let helperText = '';

  if (value > bottomPoint && bottomPoint >= 0) {
    helperText = `Please enter valid value low or equal ${bottomPoint}`;
  }
  if (value < 0) {
    helperText = 'Please enter valid value more or equal 0';
  }

  return { error: !!helperText, helperText };
};

const validateFontSizeField = ({ value }) => {
  let helperText = '';

  if (value > CARD_FIELD_MAX_FONT_SIZE) {
    helperText = `Please enter valid value low or equal ${CARD_FIELD_MAX_FONT_SIZE}`;
  }
  if (value < CARD_FIELD_MIN_FONT_SIZE) {
    helperText = `Please enter valid value more or equal ${CARD_FIELD_MIN_FONT_SIZE}`;
  }

  return { error: !!helperText, helperText };
};

const validateColorField = ({ value }) => {
  const helperText = isColor(value) ? '' : 'Please enter valid hex color';

  return { error: !!helperText, helperText };
};

export const validateCardFieldStyles = {
  [CARD_FIELD_STYLE_NAME.X]: validateXPositionField,
  [CARD_FIELD_STYLE_NAME.Y]: validateYPositionField,
  [CARD_FIELD_STYLE_NAME.FONT_SIZE]: validateFontSizeField,
  [CARD_FIELD_STYLE_NAME.COLOR]: validateColorField,
};

export const checkHasFormErrors = ({ cardFields, cardHeight }) =>
  cardFields.some(field =>
    Object.keys(field).some(key => {
      if (validateCardFieldStyles[key]) {
        const { error } = validateCardFieldStyles[key]({
          value: field[key],
          width: field.width,
          height: field.height,
          fontSize: field.fontSize,
          cardHeight,
        });
        return error;
      }
      return false;
    })
  );

export const getCardFieldPercentPosition = ({ pageX, pageY, cardCoordinates, cardSize }) => {
  const positionX = pageX - cardCoordinates.left;
  const positionY = pageY - cardCoordinates.top;
  const percentX = getPercentFromNumber(positionX, cardSize.width);
  const percentY = getPercentFromNumber(positionY, cardSize.height);

  return { x: percentX, y: percentY };
};

export const getCardVersion = ({
  croppedImages,
  cardFields,
  versionId,
  cardAspectRatio,
  initialVersion,
  cardBorderRadius,
  cardBorderRadiusProportion,
  cardSize,
}) => {
  return {
    frontUrl: croppedImages[CARD_SIDE.FRONT]?.url || '',
    backUrl: croppedImages[CARD_SIDE.BACK]?.url || '',
    borderRadius: cardBorderRadius,
    borderRadiusProportion: cardBorderRadiusProportion,
    cardObjects: cardFields,
    aspectRatio: cardAspectRatio,
    cardSize,
    publishDate: initialVersion?.publishDate || new Date().toUTCString(),
    lastUpdated: new Date().toUTCString(),
    id: +versionId || Date.now(),
  };
};

export const getDefaultCardSize = () => {
  const screenWidth =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  if (screenWidth >= 1600) {
    return { defaultCardWidth: 850, defaultCardHeight: 550 };
  }
  if (screenWidth >= 1400 && screenWidth < 1600) {
    return { defaultCardWidth: 550, defaultCardHeight: 450 };
  }

  return { defaultCardWidth: 350, defaultCardHeight: 350 };
};

export const getCardSizeByAspectRatio = ({ cardAspectRatio }) => {
  const { defaultCardWidth, defaultCardHeight } = getDefaultCardSize();
  const width = cardAspectRatio > 1 ? defaultCardWidth : defaultCardHeight * cardAspectRatio;
  const height = cardAspectRatio < 1 ? defaultCardHeight : defaultCardWidth / cardAspectRatio;

  // in case when card is large and height more than 600px
  if (height > 600) {
    return { width: 600 * cardAspectRatio, height: 600 };
  }

  // in case when card square(cardAspectRatio = 1)
  if (cardAspectRatio === 1) {
    return { width: defaultCardHeight, height: defaultCardHeight };
  }

  return { width, height };
};
