export const MIN_CARD_WIDTH = 540;
export const FONT_CARD_FIELD_PADDING = 9;
export const DEFAULT_FIELD_VERTICAL_PADDING = 18;
export const DEFAULT_ASPECT_RATIO = 16 / 9;

export const BAR_MODE = { NEW: 'new', EDIT: 'edit', VIEW: 'view' };

export const CARD_CONTAINER_SIZE_LABELS = { DEFAULT: 'default', NON_STANDARD: 'nonStandard' };

export const PATH = {
  MAIN: {
    ORG_CARD_EDITOR: 'main/OrganizationCardEditor',
    ORGANIZATION_CARDS: 'main/OrganizationCards',
  },
  ORG_CARD_EDITOR: 'OrganizationCardEditor',
  ORGANIZATION_CARDS: 'OrganizationCards',
};

export const PATH_ACTION = { CREATE: 'create', EDIT: 'edit' };

export const FILE_TYPES = {
  IMAGE: '.png, .jpg, .jpeg',
  CSV: '.csv',
  FONT: '.otf, .ttf, .woff',
};

export const CARD_SIDE = { FRONT: 'Front', BACK: 'Back' };

export const ALIGNMENT = {
  Left: 'flex-start',
  Center: 'center',
  Right: 'flex-end',
};

export const FONT_STYLE_KEY = {
  REGULAR: 'Regular',
  BOLD: 'Bold',
  ITALIC: 'Italic',
  BOLD_ITALIC: 'Bold Italic',
};

export const FONT_STYLE = {
  [FONT_STYLE_KEY.REGULAR]: 'normal',
  [FONT_STYLE_KEY.BOLD]: 'bold',
  [FONT_STYLE_KEY.ITALIC]: 'italic',
  [FONT_STYLE_KEY.BOLD_ITALIC]: 'boldItalic',
};

export const DEFAULT_FONTS = {
  Nunito: 'Nunito Regular',
  Tahoma: 'Tahoma, Geneva, sans-serif',
  Vergana: 'Verdana, Geneva, sans-serif',
  Trebuchet: 'Trebuchet MS, Helvetica, sans-serif',
};

export const LIFECYCLE_STAGE_OPTIONS = [
  { id: 'empty', name: '' },
  { id: 'subscriber', name: 'Subscriber' },
  { id: 'lead', name: 'Lead' },
  { id: 'marketingqualifiedlead', name: 'Marketing qualified lead' },
  { id: 'salesqualifiedlead', name: 'Sales qualified lead' },
  { id: 'opportunity', name: 'Opportunity' },
  { id: 'customer', name: 'Customer' },
  { id: 'evangelist', name: 'Evangelist' },
  { id: 'other', name: 'Other' },
];

export const LEAD_STATUS_OPTIONS = [
  { id: 'empty', name: '' },
  { id: 'NEW', name: 'New' },
  { id: 'OPEN', name: 'Open' },
  { id: 'IN_PROGRESS', name: 'In progress' },
  { id: 'OPEN_DEAL', name: 'Open deal' },
  { id: 'UNQUALIFIED', name: 'Unqualified' },
  { id: 'ATTEMPTED_TO_CONTACT', name: 'Attempted to contact' },
  { id: 'CONNECTED', name: 'Connected' },
  { id: 'BAD_TIMING', name: 'Bad timing' },
];
