import { useEffect } from 'react';

const useInfiniteScroll = ({ ref, isFetching, canFetchMore, fetchMore }) => {
  const shouldFetchMore = () =>
    canFetchMore &&
    !isFetching &&
    ref.current &&
    ref.current.scrollHeight - ref.current.scrollTop <= ref.current.clientHeight;

  const onScroll = () => shouldFetchMore() && fetchMore();

  useEffect(() => {
    ref.current.addEventListener('scroll', onScroll);
    ref.current.addEventListener('resize', onScroll);
    return () => {
      ref.current.removeEventListener('scroll', onScroll);
      ref.current.removeEventListener('resize', onScroll);
    };
  });
};

export default useInfiniteScroll;
