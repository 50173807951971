import axios from 'axios';

import { PRIMARY_ATTRIBUTES, SECTIONS } from '../../../Models/Contacts.config';
import { createNewAttribute } from 'global/utils';

const concatEmails = adContact => {
  const extraMails = [];
  if (adContact.mail) {
    extraMails.push(adContact.mail);
  }
  adContact.otherMails = [...new Set([...(adContact.otherMails || []), ...extraMails])];
};

export const callMSGraph = async (endpoint, token, options) => {
  const { method, params } = options;
  const result = await axios({
    method,
    url: endpoint,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

  return result?.data;
};

export const parseToHcInfo = (adContact = {}) => {
  concatEmails(adContact);
  const primaryValues = PRIMARY_ATTRIBUTES.reduce((acc, { id, adHeader }) => {
    acc[id] = adContact[adHeader] || '';
    return acc;
  }, {});

  const sectionsValues = SECTIONS.reduce((acc, { id, adHeader, ...section }) => {
    if (Array.isArray(adContact[adHeader])) {
      acc[id] = adContact[adHeader].map(adValue => {
        const newAdValue = createNewAttribute(section, adContact[adHeader]);
        delete newAdValue.id; // Due to https://jira.epam.com/jira/browse/ALTRCRDV-2562
        newAdValue.value = adValue;

        return newAdValue;
      });
    } else if (adContact[adHeader]) {
      const newAdValue = createNewAttribute(section, []);
      delete newAdValue.id; // Due to https://jira.epam.com/jira/browse/ALTRCRDV-2562
      newAdValue.value = adContact[adHeader];
      acc[id] = newAdValue;
    } else {
      acc[id] = [];
    }

    return acc;
  }, {});

  const hcInfo = { ...primaryValues, ...sectionsValues };
  hcInfo.id = adContact.id;
  hcInfo.personalEmail = adContact.mail || adContact.otherMails[0];

  return hcInfo;
};

export const formatDate = dateStr => {
  if (!dateStr) {
    return '-';
  }
  const date = new Date(dateStr);
  const hours = date.getHours().toString().length > 1 ? date.getHours() : `0${date.getHours()}`;
  const minutes =
    date.getMinutes().toString().length > 1 ? date.getMinutes() : `0${date.getMinutes()}`;
  const seconds =
    date.getSeconds().toString().length > 1 ? date.getSeconds() : `0${date.getSeconds()}`;

  return `${date.toLocaleDateString()} ${hours}:${minutes}:${seconds}`;
};
