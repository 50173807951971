export const LOGIN_REQUEST_SCOPES = {
  scopes: ['openid', 'profile', 'User.Read'],
};

export const MS_GRAPH_SCOPES = {
  scopes: [
    'user.read',
    'user.read.all',
    'user.readWrite',
    'user.readWrite.all',
    'user.readBasic.all',
    'directory.accessAsUser.all',
    'directory.read.all',
    'directory.readWrite.all',
    'group.read.all',
    'group.readWrite.all',
  ],
};

export const QUERY_SELECTORS = [
  'id',
  'userPrincipalName',
  'givenName',
  'surname',
  'jobTitle',
  'businessPhones',
  'officeLocation',
  'otherMails',
  'mail',
];

export const ENDPOINTS = {
  users: 'https://graph.microsoft.com/v1.0/users',
  usersDelta: 'https://graph.microsoft.com/v1.0/users/delta',
};
