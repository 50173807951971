import React, { useMemo } from 'react';
import { inject } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import ContactDetail from './ContactDetail';
import { joinPath } from 'global/string.utils';
import { useInfiniteFirebaseQuery } from 'global/hooks';
import { OrganizationType } from '../../Models/Organization';
import OfficeType from 'Models/OfficeType';

const YourAccountInfoScreen = inject('yourContactStore')(props => {
  const { isShowMessage, yourContactStore, match, history } = props;
  const { data: organizations } = useInfiniteFirebaseQuery('organizations', {
    adapter: organization => {
      const org = new OrganizationType();
      org.fromFirestoreDoc(organization);
      org.getOrgFonts(organization.id);
      return org;
    },
    queryAdapter: query => query.where('id', '==', yourContactStore.contactInfo.organizationId),
    limit: 1,
  });
  const { data: officesList } = useInfiniteFirebaseQuery('offices', {
    queryAdapter: query =>
      query.where('organizationId', '==', yourContactStore.contactInfo.organizationId),
    adapter: doc => new OfficeType().fromFirestoreDoc(doc),
  });

  const officesObj = useMemo(() => {
    return (
      officesList.reduce((acc, office) => {
        return { ...acc, [office.data.id]: office.data };
      }, {}) || {}
    );
  }, [officesList]);

  const contactOrganization = organizations[0];

  const organizationData = useMemo(
    () => ({
      id: contactOrganization?.id,
      emailAddress: contactOrganization?.orgData.emailAddress,
      name: contactOrganization?.name,
      phoneNumber: contactOrganization?.orgData.phoneNumber,
      website: contactOrganization?.orgData.website,
    }),
    [contactOrganization]
  );

  return (
    <Switch>
      <Route
        path={`${match.path}/:mode?`}
        render={({
          match: {
            params: { mode },
          },
        }) => (
          <ContactDetail
            type="profile"
            isShowMessage={isShowMessage}
            titles={{ new: 'Your profile', view: 'Profile:' }}
            theme="you"
            store={yourContactStore}
            isEditing={mode === 'edit'}
            onEdit={() => {
              const path = joinPath(match.url, 'edit');
              history.push(path);
            }}
            onSave={() => history.push('.')}
            organization={organizationData}
            offices={{ list: officesList, data: officesObj }}
            isHubspotBtnVisible={false}
          />
        )}
      />
    </Switch>
  );
});

export default YourAccountInfoScreen;
