import { observable, computed, action } from 'mobx';
import { ContactsStoreClass, ContactType } from './Contacts';
import { OrganizationType } from './Organization';

import firebaseGlobal from 'firebase/app';
import firebase from 'services/firebase';
import ContactStore from './Contact.store';
import { noop } from 'global/utils';

export default class AccountStore {
  @observable accountData;

  @observable contacts = new ContactsStoreClass(this);
  @observable organization = new OrganizationType();

  @observable loadingState = {
    state: '',
    completionText: '',
    errorText: '',
  };

  startLoading() {
    this.loadingState = { state: 'Loading', completionText: '', errorText: '' };
  }

  finishLoading(completionText = '') {
    this.loadingState = { state: 'Complete', completionText: completionText, errorText: '' };
  }

  finishLoadingWithError(errorText) {
    this.loadingState = { state: 'Complete', completionText: '', errorText };
  }

  @computed get id() {
    return this.accountData.id;
  }

  set id(value) {
    this.accountData.id = value;
  }

  @computed get linkedInId() {
    return this.accountData.linkedInId;
  }

  @computed get organizationId() {
    console.log(this);
    return this.accountData.organizationId;
  }

  set linkedInId(value) {
    this.accountData.linkedInId = value;
  }

  @computed get hasLinkedInId() {
    return !(this.linkedInId == null || this.linkedInId.length === 0);
  }

  @computed get deviceSecurity() {
    return this.accountData.deviceSecurity;
  }

  linkedInToken = null;
  linkedInProfile = null;

  constructor(isSimulator) {
    this.accountData = this.clearAccountData();
  }

  @action clearAccountData() {
    return {
      deviceSecurity: [
        { type: 'camera', value: false },
        { type: 'contacts', value: false },
        { type: 'airdrop', value: false },
      ],
    };
  }

  @action setCurrentUser(uid) {
    if (uid === this.id) {
      return;
    }

    this.id = uid;

    this.startLoading();

    // set up user info
    this.unsubscribeUser = firebase.subscribeOnUser(user => {
      this.accountData = Object.assign(this.accountData, user);
      this.finishLoading();
    });
  }

  addOnUserChangedLister(callback) {
    this.onUserChanged = callback;
  }

  async logout(auth0 = { logout: noop }) {
    try {
      this.startLoading();
      this.unsubscribeUser();

      auth0.logout();
      await firebase.signOut();

      this.accountData = this.clearAccountData();

      this.finishLoading();
    } catch (error) {
      this.finishLoadingWithError(error.message);
      throw error;
    }
  }

  // Sign in

  async signIn(email, password) {
    try {
      this.startLoading();
      await firebase.signInWithEmailAndPassword(email, password);
      this.finishLoading();
    } catch (error) {
      this.finishLoadingWithError(error.message);
      throw error;
    }
  }

  async getExistedEmployeeByEmail(email) {
    try {
      return await firebaseGlobal
        .firestore()
        .collection('contacts')
        .where('personalEmail', '==', email)
        .get()
        .then(({ docs }) =>
          docs.reduce((acc, doc) => {
            const data = doc?.data();
            if (data?.organizationId && !acc) {
              acc = data;
            }
            return acc;
          }, null)
        );
    } catch (error) {
      this.finishLoadingWithError(error.message);
      throw error;
    }
  }

  @action async resetPassword(email) {
    try {
      await firebase.resetPassword(email);
      this.finishLoadingWithError(
        'A password reset email has been sent to ' +
          email +
          '.  After completing the instructions, please enter the password created in the password field.'
      );
    } catch (error) {
      this.finishLoadingWithError(error.message);
    }
  }

  @action async isEmailInUse(email) {
    this.startLoading();
    const isEmailInUse = await firebase.isEmailInUse(email);
    this.finishLoading();
    return isEmailInUse;
  }

  @action async createAccount(email, password) {
    try {
      this.startLoading();

      // create user
      await firebase.createUserWithEmailAndPassword(email, password);

      // create account and contact
      this.accountData = Object.assign(this.accountData, this.clearAccountData());
      await this.save();

      // check if there is a contact with the same Personal emaild
      const employee = await this.getExistedEmployeeByEmail(email);
      const contact = employee ? new ContactType().fromFirestoreDoc(employee) : null;

      await ContactStore.getYourContactStore().createYourContact(email, contact?.hcInfo);

      if (contact) {
        await contact.delete();
      }

      this.finishLoading();
    } catch (error) {
      this.finishLoadingWithError(error.message);
      throw error;
    }
  }

  async createLinkedInAccount(user) {
    try {
      this.startLoading();
      await ContactStore.getYourContactStore().createLinkedInAccount(user);
      this.finishLoading();
    } catch (error) {
      this.finishLoadingWithError(error.message);
      throw error;
    }
  }

  async createAccountFromAppleId(user) {
    try {
      this.startLoading();
      await ContactStore.getYourContactStore().createAccountFromAppleId(user);
      this.finishLoading();
    } catch (error) {
      this.finishLoadingWithError(error.message);
      throw error;
    }
  }

  async importCSVContacts({ data, organizationId, organization, existedContacts, collectorId }) {
    try {
      const result = await this.contacts.importCSVContacts({
        data,
        ownerId: this.id,
        organizationId,
        organization,
        existedContacts,
        collectorId,
      });
      return result;
    } catch (error) {
      this.finishLoadingWithError(error.message);
      throw error;
    }
  }

  async importAdContacts({ data, organizationId, organization, existedContacts, collectorId }) {
    try {
      const result = await this.contacts.importAdContacts({
        data,
        ownerId: this.id,
        organizationId,
        organization,
        existedContacts,
        collectorId,
      });
      return result;
    } catch (error) {
      this.finishLoadingWithError(error.message);
      throw error;
    }
  }

  async importCSVOffices(rows, organizationId) {
    try {
      const result = await this.organization.importCSVOffices(rows, organizationId);
      return result;
    } catch (error) {
      this.finishLoadingWithError(error.message);
      throw error;
    }
  }

  @action async importPhoneContacts() {
    this.startLoading();
    try {
      const initialContactNum = this.contacts.contactsList.length;
      await this.contacts.importPhoneContacts();
      this.finishLoading(
        'Imported ' + (this.contacts.contactsList.length - initialContactNum) + ' contacts'
      );
    } catch (error) {
      this.finishLoadingWithError(error.message);
      throw error;
    }
  }

  @action async importOutlookContacts() {
    this.startLoading();
    try {
      const initialContactNum = this.contacts.contactsList.length;
      await this.contacts.importOutlookContacts();
      this.finishLoading(
        'Imported ' + (this.contacts.contactsList.length - initialContactNum) + ' contacts'
      );
    } catch (error) {
      this.finishLoadingWithError(error);
    }
  }

  @action async saveContact(contact) {
    try {
      this.startLoading();
      await contact.save();
      this.contacts.addContacts([contact]);
      this.finishLoading();
    } catch (error) {
      this.finishLoadingWithError(error.message);
      throw error;
    }
  }

  @action createCameraContact(data, frontOrBack) {
    return this.contacts.createCameraContact(data, frontOrBack);
  }

  async emailCardToContact(contact) {
    try {
      this.startLoading();
      const sendMail = firebase.functions().httpsCallable('sendEmail');
      var response = await sendMail({
        uid: this.id,
        email: contact.hcInfo.emailAddresses[0],
        fullname: contact.fullname,
        front_card_url: contact.businessCard.frontUrl,
        back_card_url: contact.businessCard.backUrl,
      });
      if (response.status === 'error') {
        this.finishLoadingWithError(response.message);
      } else {
        this.finishLoading('Contact sent by email');
      }
    } catch (e) {
      console.log(e);
      this.finishLoadingWithError(e.message);
    }
  }

  @action fromFirestoreDoc(doc) {
    this.accountData = Object.assign(this.accountData, doc);
  }

  async save() {
    return firebase.saveUser(this.accountData);
  }
}
