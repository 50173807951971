import parseCsv from 'global/parseCsv';
import { OFFICE_ATTRIBUTES, NAME_ATTRIBUTES } from './Office.config';
import { secondaryValidationSchema } from '../Screens/Organization/EditOfficeSettings.validation';

export const parseOfficesCsv = (csv, callback) =>
  parseCsv(csv, callback, {
    ATTRIBUTE_CSV_HEADERS: OFFICE_ATTRIBUTES.map(x => x.csvHeader),
    PRIMARY_ATTRIBUTES: OFFICE_ATTRIBUTES,
    SECTIONS: [],
    NAME_ATTRIBUTES_CSV_HEADERS: NAME_ATTRIBUTES.map(x => x.csvHeader),
  });

export function getInvalidAttributesPathes(office) {
  try {
    secondaryValidationSchema.validateSync(office, { abortEarly: false });
  } catch (error) {
    return error.inner.map(e => {
      const [section, index] = e.path.split(/[[\].]+/);
      return [section, +index];
    });
  }

  return [];
}

export function filterInvalidAttributes(office) {
  const pathes = getInvalidAttributesPathes(office);

  return pathes.reduce(
    (acc, [section, index]) => ({
      ...acc,
      [section]: Array.isArray(acc[section]) ? acc[section].filter((_, i) => i !== index) : '',
    }),
    office
  );
}
