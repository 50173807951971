import React from 'react';

import styles from './Login.module.css';

const SelectionBox = ({ onChangedMode, mode }) => (
  <div
    className={`${styles.selectionBox} ${mode === 'login' ? styles.login : styles.createAccount}`}
  >
    <div onClick={() => onChangedMode('create')}>CREATE ACCOUNT</div>
    <div onClick={() => onChangedMode('login')}>LOGIN</div>
  </div>
);

export default SelectionBox;
