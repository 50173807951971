import { observable } from 'mobx';

import firebaseService from 'services/firebase';
import { ContactType } from './Contacts';
import { noop } from 'global/utils';

import { ADDRESS_TYPES } from './Contacts.config';

export default class ContactStore {
  @observable isLoading = true;

  static getYourContactStore() {
    if (!ContactStore._yourContactStore || !ContactStore._yourContactStore.contactInfo.id) {
      const contactInfo = new ContactType();
      contactInfo.id = firebaseService.getUserId();
      ContactStore._yourContactStore = new ContactStore(contactInfo);
    }

    return ContactStore._yourContactStore;
  }

  constructor(contactInfo) {
    this.contactInfo = contactInfo;
    this.saveNotes = this.saveNotes.bind(this);
  }

  subscribe(callback = noop) {
    this.isLoading = true;

    this._unsubsribe = firebaseService.subscribeOnContact(this.contactInfo.id, async contact => {
      this.contactInfo.fromFirestoreDoc(contact);

      await this.contactInfo.fetchSent();

      this.isLoading = false;

      callback(this.contactInfo);
    });
  }

  async deleteImage(imageUrl, urlPropertyName) {
    await firebaseService.deleteFromStorage(imageUrl);
    this.contactInfo.hcInfo[urlPropertyName] = null;
  }

  async uploadImage(image) {
    try {
      const { id, [image.urlPropertyName]: oldUrl } = this.contactInfo.hcInfo;
      const url = await firebaseService.uploadImage(id, image.blob, image.id, oldUrl);
      this.contactInfo.hcInfo[image.urlPropertyName] = '';
      return url;
    } catch (e) {
      console.log(e);
    }
  }

  async createYourContact(email, otherData) {
    const { id } = this.contactInfo;

    const data = {
      id,
      emailAddresses: [{ type: ADDRESS_TYPES[0], value: email }],
    };

    this.contactInfo.createHcAccountContact(otherData ? { ...otherData, id } : data, id);
    await this.contactInfo.save();
  }

  createLinkedInAccount(user) {
    // picture
    const { email, family_name: lastName, given_name: firstName, picture: imageUrl } = user;
    const { id } = this.contactInfo;

    const data = {
      id,
      emailAddresses: [{ type: ADDRESS_TYPES[0], value: email }],
      firstName,
      lastName,
      imageUrl,
    };

    this.contactInfo.createHcAccountContact(data, id);
    return this.contactInfo.save();
  }

  async createAccountFromAppleId({ user }) {
    const { email } = user;
    const { id } = this.contactInfo;

    const contact = await firebaseService.getContact(id);

    if (contact) {
      return;
    }

    const data = {
      id,
      emailAddresses: [{ type: ADDRESS_TYPES[0], value: email }],
    };

    this.contactInfo.createHcAccountContact(data, id);
    return this.contactInfo.save();
  }

  saveNotes(notes) {
    this.contactInfo.hcInfo.notes = notes;
    return firebaseService.saveContact(this.contactInfo.hcInfo);
  }

  done() {
    this._unsubsribe();
    this.contactInfo.clear();
  }
}
