import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useHistory, Switch, Route, useParams, useLocation } from 'react-router-dom';
import FlexView from 'react-flexview';
import { Snackbar } from 'react-md';

import { useInfiniteFirebaseQuery } from 'global/hooks';
import { Table, TableCellOfficeCard } from 'global/components/Table';
import Button from '../Common/Button';
import ViewOfficeDetail from './ViewOfficeDetail';
import { ContactType } from 'Models/Contacts';
import OfficeType from 'Models/OfficeType';
import { inject } from 'mobx-react';
import styles from '../Common/TablePage.module.css';
import DebounceInput from 'global/components/DebounceInput';
import ImportCSVScreen from '../Common/ImportCSV';
import { parseOfficesCsv } from 'Models/Office.util';
import firebaseService from 'services/firebase';

const basePath = '/main/OrganizationOffices';

const OrgOfficesScreen = inject(
  'organizationStore',
  'accountStore'
)(props => {
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [orderBy, setOrderBy] = useState({ field: 'name', direction: 'asc' });
  const [search, setSearch] = useState('');
  const [searchEmployee, setSearchEmployee] = useState('');
  const [toasts, setToasts] = useState([]);
  const [showImportOffices, setShowImportOffices] = useState(false);
  const [isEmployeeSidebarOpen, setEmployeeSidebar] = useState(false);
  const [officeCards, setOfficeCards] = useState([]);
  const history = useHistory();
  const location = useLocation();

  // There are three 'modes' in the path: edit, view, new
  const { officeId, mode, tab } = useParams();
  const isCreating = mode === 'new';
  const isEditing = mode === 'edit';

  const { data: employeesList, error: employeesListError } = useInfiniteFirebaseQuery('contacts', {
    adapter: contact => new ContactType().fromFirestoreDoc(contact),
    queryAdapter: query =>
      query.where('organizationId', '==', props.organizationStore.organization.id),
    search: searchEmployee,
  });

  const {
    data: selectedOfficeEmployees,
    refetch: refetchSelectedOfficeEmployees,
    error: selectedOfficeEmployeesError,
  } = useInfiniteFirebaseQuery('contacts', {
    adapter: contact => new ContactType().fromFirestoreDoc(contact),
    queryAdapter: query =>
      query
        .where('organizationId', '==', props.organizationStore.organization.id)
        .where('officeId', '==', officeId || null),
  });

  const onDeleteClick = id => {
    const office = data.find(x => x.id === id);
    const isAssigned = employeesList.some(el => el.hcInfo.officeId === id);

    if (isAssigned) {
      window.alert(
        `The office ${office.name} cannot be deleted. Please delete all items assigned the office first!`
      );
    } else if (window.confirm(`Delete ${office.name} Office?`)) {
      office.delete().then(() => {
        history.push(basePath);

        // When the last office has been deleted
        if (data.length === 1) {
          return refetch();
        }

        refetch({ id });
      });
    }
  };

  const actions = ({ cell: { value } }) => [
    {
      caption: 'Edit',
      onClick: onEditClick,
      icon: 'ios-create-outline',
    },
    {
      primary: true,
      caption: 'Delete',
      onClick: onDeleteClick,
      icon: 'ios-trash-outline',
    },
  ];

  const {
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    refetch,
    error: officesListError,
  } = useInfiniteFirebaseQuery('offices', {
    queryAdapter: query =>
      query.where('organizationId', '==', props.organizationStore.organization.id),
    adapter: doc => new OfficeType().fromFirestoreDoc(doc),
    orderBy: [orderBy],
    search,
  });

  const columns = useMemo(
    () => [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Default Mailing Address', accessor: 'primary_mailing' },
      { Header: 'Default Work Phone', accessor: 'primary_phone' },
      { Header: 'Default Fax', accessor: 'primary_fax' },
      {
        Header: 'Default Card',
        accessor: 'primary_card',
        Cell: props => TableCellOfficeCard({ ...props, cardsList: officeCards }),
      },
    ],
    [data, officeCards]
  );

  const clickImport = () => setShowImportOffices(true);

  const onImportComplete = data => {
    if (data) {
      props.accountStore
        .importCSVOffices(data, props.organizationStore.organization.id)
        .then(result => {
          refetch();
          setToast(`${result.offices.length} offices created.
            ${result.errors.length} offices skipped.`);
        })
        .catch(error => {
          alert(error.toString());
        });
    }

    setShowImportOffices(false);
  };

  const setToast = message => setToasts([...toasts, { text: message }]);

  const dismissToast = () => {
    const [, ...remain] = toasts;
    setToasts(remain);
  };

  const onEditClick = id => history.push(`${basePath}/edit/${id}/${tab || 'employees'}`);

  const onEditHandler = () => history.push(`${basePath}/edit/${officeId}/${tab}`);

  const onRowClick = index =>
    history.push(`${basePath}/view/${data[index].id}/${tab || 'employees'}`);

  const onCloseHandler = () => history.push(basePath);

  const clickAdd = e => {
    e.preventDefault();
    const officeId = firebaseService.nextOfficeId();

    history.push(`${basePath}/new/${officeId}/admin`);
  };

  const onSaveHandler = () => {
    if (isEditing) {
      // Refetch only "selectedContact" if it is "Editing Mode"
      refetch(selectedOffice);
    } else {
      // Otherwise it is a new contact
      // Refetch all
      refetch();
    }

    history.push(`${basePath}/view/${selectedOffice.id}/admin`);
  };

  const debounceInputRef = useRef();

  useEffect(() => {
    if (employeesListError || selectedOfficeEmployeesError || officesListError) {
      history.push('/main');
    }
  });

  useEffect(() => {
    debounceInputRef.current?.focus();
  }, [debounceInputRef]);

  useEffect(() => {
    if (isCreating) {
      return;
    }

    const getOffice = async () => {
      const office = await firebaseService.getOffice(officeId);

      setSelectedOffice(new OfficeType().fromFirestoreDoc(office));
    };

    try {
      if (officeId) {
        getOffice(officeId);
      }
    } catch (error) {
      alert(error.toString());
    }
  }, [officeId]);

  useEffect(() => {
    refetchSelectedOfficeEmployees();
  }, [selectedOffice?.id]);

  useEffect(() => {
    const getCards = async offices => {
      const officeCards = await Promise.all(
        offices.map(({ primary_card }) => {
          if (primary_card) {
            return firebaseService.getCard(primary_card);
          }
        })
      );

      setOfficeCards(officeCards.filter(officeCard => officeCard));
    };

    if (data) {
      getCards(data);
    }
  }, [data]);

  useEffect(() => {
    if (location.pathname.startsWith(`${basePath}/new/`)) {
      const office = new OfficeType();
      const { id: organizationId } = props.organizationStore.organization;

      office.createNew(organizationId, { id: officeId });
      setSelectedOffice(office);
    }
  }, [location, officeId]);

  const isEmpty = !isFetching && !isFetchingMore && !data.length && !search;

  const widths = useMemo(() => {
    let left = '100%';
    let right = '100%';
    if (selectedOffice) {
      left = '70%';
      right = '30%';
    }
    if (isEmployeeSidebarOpen) {
      left = '50%';
      right = '50%';
    }
    return { left, right };
  }, [selectedOffice, isEmployeeSidebarOpen]);

  const NoOffices = (
    <div className={styles.noItemsContainer}>
      <p>You don&rsquo;t have offices here yet. Let&rsquo;s add some</p>

      <Button
        onClick={clickImport}
        icon="md-cloud-upload"
        caption="Import offices from .csv file"
      />
      <Button onClick={clickAdd} icon="md-add" caption="Add an office manually" />
    </div>
  );

  return (
    <FlexView grow style={{ backgroundColor: 'white', height: '100%' }}>
      <FlexView column grow style={{ width: widths.left, height: '100%' }}>
        <FlexView
          width="100%"
          height={60}
          row="true"
          vAlignContent="center"
          style={{ paddingRight: 20, paddingLeft: 20, fontWeight: 'bold', minHeight: 60 }}
        >
          <FlexView>Offices</FlexView>
          <FlexView grow row="true" width={220} hAlignContent="right">
            <DebounceInput
              onChange={setSearch}
              value={search}
              ref={debounceInputRef}
              debounce={250}
              placeholder="Search"
              icon="ios-search"
              style={{ marginRight: 8, maxWidth: 400 }}
            />
            <Button onClick={clickAdd} icon="md-add" caption="Add" style={{ marginRight: 8 }} />
            <Button onClick={clickImport} icon="md-cloud-upload" caption="Import" />
          </FlexView>
        </FlexView>
        <FlexView column style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
          <Table
            actions={actions}
            columns={columns}
            onRowClick={onRowClick}
            data={data}
            onFetchMore={fetchMore}
            canFetchMore={canFetchMore}
            isFetching={isFetching}
            orderBy={orderBy}
            isFetchingMore={isFetchingMore}
            highlight={search}
            onOrderByClick={setOrderBy}
            isSidebarOpen={officeId || selectedOffice}
            isEmpty={isEmpty}
            emptyMessage={NoOffices}
            activeId={officeId}
            columnsToUpdateDependency={(employeesList, officeCards)}
          />
        </FlexView>
      </FlexView>
      <Switch>
        <Route
          path={`${basePath}/:mode/:officeId/:tab?`}
          render={() => (
            <div style={{ width: widths.right }} className={styles.detailsPanel}>
              <ViewOfficeDetail
                office={selectedOffice || {}}
                onClose={onCloseHandler}
                onEdit={onEditHandler}
                onSave={onSaveHandler}
                setToast={setToast}
                setEmployeeSidebar={setEmployeeSidebar}
                history={props.history}
                employeesList={employeesList}
                officeEmployeesList={selectedOfficeEmployees}
                refetch={refetchSelectedOfficeEmployees}
                onSearchEmployee={setSearchEmployee}
                searchValue={searchEmployee}
                organizationStore={props.organizationStore}
              />
            </div>
          )}
        />
      </Switch>

      {showImportOffices && (
        <ImportCSVScreen
          title="Import Offices"
          onComplete={onImportComplete}
          csvHeader="office_name, phone_number, fax_number, mailing_address, website, card"
          csvParser={parseOfficesCsv}
        />
      )}

      <FlexView
        grow
        hAlignContent="center"
        row="true"
        style={{ position: 'absolute', bottom: 0, left: 220, right: 0 }}
      >
        <Snackbar
          style={{ padding: 20, color: 'white', backgroundColor: 'rgb(6, 16, 33)', fontSize: 16 }}
          toasts={toasts}
          autoFocusAction
          autohide={true}
          onDismiss={dismissToast}
        />
      </FlexView>
    </FlexView>
  );
});

export default OrgOfficesScreen;
