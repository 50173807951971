import React, { memo } from 'react';
import Ionicon from 'react-ionicons';
import cn from 'classnames';

import styles from './FileUploadButton.module.css';

const FileUploadButton = ({
  caption,
  icon,
  className,
  id = 'uploadFile',
  theme = 'white',
  ...rest
}) => (
  <label htmlFor={id} className={cn('button', theme, className)}>
    {icon && <Ionicon style={{ marginRight: caption ? 10 : 0 }} icon={icon} fontSize="14px" />}
    {caption && <span>{caption}</span>}
    <input type="file" id={id} className={styles.input} {...rest} />
  </label>
);

export default memo(FileUploadButton);
