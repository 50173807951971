import Papa from 'papaparse';

import { invokeOnce } from './utils';

/*
 * [
 *  wholeMatch,
 *  ([a-z]+), // name
 *  (_(\d+)), // _index
 *  (\d+) // index
 * ]
 */
const ATTRIBUTE_VALUE_PATTERN = /^([a-z_]+)?(_(\d+))?$/;

const TYPE_POSTFIX = '_type';

const matchByNameAndIndex = (col1, col2) => col1.index === col2.index && col1.name === col2.name;

const buildValueColumnHeader = (name, index) => (index === null ? `${name}` : `${name}_${index}`);

const buildTypeColumnHeader = (name, index) =>
  index === null ? `${name}${TYPE_POSTFIX}` : `${name}${TYPE_POSTFIX}_${index}`;

function addItemAttribute(row, header, columns, item, config) {
  const value = row[header];
  const primaryAttribute = config.PRIMARY_ATTRIBUTES.find(
    attribute => attribute.csvHeader === header
  );

  if (primaryAttribute) {
    item[primaryAttribute.id] = value;
    return;
  }

  const column = columns.find(column => column.header === header);

  if (column.isType) {
    return;
  }

  const section = config.SECTIONS.find(section => section.csvHeader === column.name);

  if (!section) {
    return;
  }

  if (!item[section.id]) {
    item[section.id] = [];
  }

  const rawType = column.typeColumn ? row[column.typeColumn.header] : section.attributeTypes[0];

  const type = section.attributeTypes.includes(rawType)
    ? rawType
    : section.attributeTypes[section.defaultAttributeType];

  item[section.id].push({ value, type });
}

function buildItem(row, columns, config) {
  const item = {};

  Object.keys(row).forEach(key => {
    addItemAttribute(row, key, columns, item, config);
  });

  return item;
}

function buildConfig(columns, config) {
  const errors = [];

  if (config.NAME_ATTRIBUTES_CSV_HEADERS?.length) {
    const isNameExist = columns.some(header =>
      config.NAME_ATTRIBUTES_CSV_HEADERS.includes(header.name)
    );

    if (!isNameExist) {
      errors.push(`${config.NAME_ATTRIBUTES_CSV_HEADERS.join(' or ')} is required.`);
    }
  }

  columns.forEach(column => {
    if (column.isType) {
      if (!columns.some(col => !col.isType && matchByNameAndIndex(col, column))) {
        errors.push(`Column ${buildValueColumnHeader(column.name, column.index)} is missed`);
      }
      return;
    }

    const section = config.SECTIONS.find(section => section.csvHeader === column.name);

    if (section) {
      const typeHeader = columns.find(col => col.isType && matchByNameAndIndex(col, column));
      if (typeHeader) {
        column.typeColumn = typeHeader;
      } else if (section.attributeTypes.length > 1) {
        errors.push(`Column ${buildTypeColumnHeader(column.name, column.index)} is missed`);
      }
    }
  });

  return errors;
}

export function parseColumnHeader(header) {
  const trimedHeader = header.trim();
  const parsed = ATTRIBUTE_VALUE_PATTERN.exec(trimedHeader);

  if (parsed === null) {
    return null;
  }

  const name = parsed[1];

  const isType = name.endsWith(TYPE_POSTFIX);

  return {
    isType,
    header: trimedHeader,
    name: isType ? name.slice(0, -TYPE_POSTFIX.length) : name,
    index: parsed[3] || null,
  };
}

export default function parseCsv(csv, onComplete, config) {
  const columns = [];
  const invalidHeaders = [];

  const onValue = invokeOnce(() => {
    const errors = [
      ...(invalidHeaders.length > 0 ? [`Invalid header(s): ${invalidHeaders.join(', ')}`] : []),
      ...buildConfig(columns, config),
    ];

    if (errors.length > 0) {
      throw new Error(errors.join('\n'));
    }
  });

  Papa.parse(csv, {
    header: true,
    skipEmptyLines: true,
    quotes: false, // save quotes in order to not trim spaces between them
    transformHeader: header => {
      const parsed = parseColumnHeader(header) || {};
      if (!config.ATTRIBUTE_CSV_HEADERS.includes(parsed.name)) {
        invalidHeaders.push(parsed.header || header);
      }
      columns.push(parsed);
      return parsed.header || {};
    },
    transform: value => {
      onValue();
      const trimmed = value.trim();
      return trimmed.startsWith('"') && trimmed.endsWith('"') ? trimmed.slice(1, -1) : trimmed;
    },
    complete: result => {
      const data = result.data.map(row => buildItem(row, columns, config));

      onComplete(data);
    },
  });
}
