import firebase from 'firebase/app';
import { useState, useEffect, useCallback } from 'react';
import { indentity, isEqual, noop } from 'global/utils';

const setOrderByToQuery = (query, orderBy = []) => {
  orderBy.forEach(({ field, direction }) => (query = query.orderBy(field, direction)));

  return query;
};

const setSearchToQuery = (query, search, isAdditionalFields) =>
  search && search !== ''
    ? query.where(
        `${isAdditionalFields ? 'keywordsWithAdditionalFields' : 'keywords'}`, //TODO; remove comments after BD patching
        'array-contains',
        search.toLowerCase()
      )
    : query;

const useInfiniteFirebaseQuery = (
  collectionKey,
  {
    adapter = indentity,
    dataAdapter = indentity,
    queryAdapter = indentity,
    orderBy = [],
    search = '',
    ...config
  }
) => {
  const [last, setLast] = useState(null);
  const [isMount, setIsMount] = useState(false);
  const [firebaseOrderBy, setFirebaseOrderBy] = useState(orderBy);
  const [firebaseSearch, setFirebaseSearch] = useState(search);

  const [canFetchMore, setCanFetchMore] = useState(true);
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [error, setError] = useState(null);

  let configuredQuery;
  try {
    configuredQuery = queryAdapter(
      firebase
        .firestore()
        .collection(collectionKey)
        .limit(config.limit || 50)
    );
  } catch (error) {
    return {
      error,
      data: [],
      refetch: noop,
    };
  }

  const finishDataLoading = data => {
    setData(data);
    setIsFetching(false);
    setIsFetchingMore(false);
  };

  const fetch = async ({
    refetch = false,
    fetchMore = false,
    id = null,
    orderBy = [],
    search = '',
  } = {}) => {
    setError(null);
    setIsFetching(!fetchMore);
    setIsFetchingMore(!!fetchMore);

    if (id) {
      const doc = await firebase.firestore().collection(collectionKey).doc(id).get();

      if (doc.exists) {
        const adaptedItems = data.map(x => (x.id === id ? adapter(doc.data()) : x));
        const adaptedData = dataAdapter(adaptedItems);
        if (typeof adaptedData.then === 'function') {
          adaptedData.then(resolvedData => finishDataLoading(resolvedData));
        } else {
          finishDataLoading(adaptedData);
        }
      } else {
        finishDataLoading(data.filter(x => x.id !== id));
      }
    } else {
      const queryWithOrderBy = setOrderByToQuery(configuredQuery, orderBy);

      const queryWithSearch = setSearchToQuery(queryWithOrderBy, search, config.isAdditionalFields);

      const queryWithPagination =
        !refetch && last ? queryWithSearch.startAfter(last) : queryWithSearch;

      return (
        queryWithPagination
          .get()
          .then(({ docs, ...rest }) => {
            setLast(docs[docs.length - 1]);

            return docs.map(doc => doc.data());
          })
          // .then(data => data.map(adapter))
          .then(data => {
            const adaptedItems = data.map(adapter);
            return dataAdapter(adaptedItems);
          })
          .then(queryData => {
            setCanFetchMore(!!queryData.length);
            setIsFetching(false);
            setIsFetchingMore(false);

            if (refetch) {
              setData(queryData);
            } else {
              setData([...data, ...queryData]);
            }
          })
          .catch(error => {
            console.log(error);
            setError(error);
            setIsFetching(false);
            setIsFetchingMore(false);
          })
      );
    }
  };

  const refetch = useCallback(({ id } = {}) => {
    fetch({ refetch: true, id, orderBy, search });
  });

  const fetchMore = useCallback(() => {
    fetch({ fetchMore: true, orderBy, search });
  });

  useEffect(() => {
    if (!isMount) {
      setIsMount(true);
      fetch({ orderBy, search });
    }
  });

  // When the 'orderBy' arg is changed then save the new one and refetch data
  useEffect(() => {
    if (isMount && !isEqual(firebaseOrderBy, orderBy)) {
      setFirebaseOrderBy(orderBy);
      setLast(null);
      refetch();
    }
  }, [orderBy]);

  // When the 'search' arg is changed then save the new one and refetch data
  useEffect(() => {
    if (isMount && !isEqual(firebaseSearch, search)) {
      setFirebaseSearch(search);
      setLast(null);
      refetch();
    }
  }, [search]);

  return {
    data,
    refetch,
    fetchMore,
    isFetching,
    isFetchingMore,
    canFetchMore,
    error,
  };
};

export default useInfiniteFirebaseQuery;
