import React from 'react';

import FlexView from 'react-flexview/lib';
import Ionicon from 'react-ionicons';

var deleteButtonStyle = {
  height: 24,
  width: 100,
  border: '1px solid #DBA3A3',
  borderRadius: 12,
  color: '#DBA3A3',
  backgroundColor: 'transparent',
  fontSize: 12,
  cursor: 'pointer',
};

export default function ConfirmFileUpload({ filename, onDelete }) {
  return (
    <FlexView column grow style={{ backgroundColor: 'white' }}>
      <FlexView grow hAlignContent={'center'} vAlignContent={'center'}>
        <FlexView column hAlignContent="center" height={200} width={400}>
          <Ionicon
            style={{ paddingBottom: 40 }}
            icon="md-document"
            fontSize="100px"
            color="rgba(0,0,0,0.3)"
          />

          <FlexView vAlignContent="center">
            <FlexView style={{ paddingRight: 100 }}>{filename}</FlexView>
            <button onClick={onDelete} style={deleteButtonStyle}>
              Delete + Retry
            </button>
          </FlexView>
        </FlexView>
      </FlexView>
    </FlexView>
  );
}
