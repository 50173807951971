export function cardFieldsReducer(state, action) {
  switch (action.type) {
    case 'add':
      return [...state, action.payload];

    case 'addMoreThenOne':
      return [...state, ...action.payload];

    case 'replace':
      return state.map(field => (field.id === action.payload.id ? action.payload : field));

    case 'merge':
      return state.map(field =>
        field.id === action.payload.id ? { ...field, ...action.payload } : field
      );

    case 'removeByFieldAndValue':
      return state.filter(field => field[action.payload.field] !== action.payload.value);

    case 'removeAll':
      return [];

    default:
      [];
  }
}
