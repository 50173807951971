import React, { Component } from 'react';

import FlexView from 'react-flexview/lib';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import DropFileUpload from '../Common/DropFileUpload';

import { ModalDialog } from '../Common/ModalDialog';
import Button from '../Common/Button';

class ImportPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploaded: false,
      src: null,
      filename: null,
      crop: {
        x: 25,
        y: 25,
        aspect: 1,
        width: 50,
        height: 50,
      },
    };

    if (this.props.isBusinessCard) {
      this.state.crop = {
        x: 0,
        y: 20,
        aspect: 10 / 6,
        width: 100,
        height: 60,
      };
    }

    this.clickSave = this.clickSave.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.dismiss = this.dismiss.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.croppedImageUrl = null;
  }

  onImageLoaded(image) {
    this.imageRef = image;
    this.makeClientCrop(this.pixelCrop);
  }

  onCropComplete(crop, pixelCrop) {
    this.makeClientCrop(pixelCrop);
  }

  onCropChange(crop, pixelCrop) {
    // For performance optimization and avoiding "Maximum update depth exceeded..." error
    this.crop = crop; // store original crop region with max prcession
    this.pixelCrop = pixelCrop;

    // and unpdate it only if it changes significantly
    if (
      Object.keys(this.state.crop).some(
        key => Math.abs(this.crop[key] - this.state.crop[key]) > 0.5
      )
    ) {
      this.setState({ crop });
    }
  }

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(blob);
      }, 'image/jpeg');
    });
  }

  makeClientCrop(pixelCrop) {
    try {
      if (this.imageRef && pixelCrop?.width && pixelCrop?.height) {
        this.croppedImageUrl = this.getCroppedImg(this.imageRef, pixelCrop, 'newFile.jpeg');
      }
    } catch (e) {
      console.log(e);
    }
  }

  dismiss() {
    this.props.accountStore.loadingState.completionText = '';
  }

  clickSave() {
    this.props.onComplete(this.croppedImageUrl);
  }

  clickCancel() {
    this.props.onComplete(null);
  }

  onDelete() {
    this.setState({ file: '' });
  }

  render() {
    return (
      <ModalDialog
        title={this.props.titleText}
        onClose={this.clickCancel}
        footer={
          <Button
            caption="Save Photo"
            className="button blue"
            icon="md-checkmark-circle-outline"
            disabled={!this.state.src}
            onClick={this.clickSave}
          />
        }
      >
        {this.state.src ? (
          <ReactCrop
            src={this.state.src}
            crop={this.state.crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        ) : (
          <DropFileUpload
            filename={file => {
              const reader = new FileReader();
              reader.addEventListener('load', () => {
                this.setState({ src: reader.result, fileUploaded: true });
              });
              reader.readAsDataURL(file);
            }}
            fileType="image"
            text={
              <FlexView hAlignContent="center" style={{ padding: 60 }}>
                <p>
                  Drag and drop an image or{' '}
                  <span style={{ color: 'rgba(42,121,255,1)' }}>click here</span> to select files to
                  upload
                </p>
              </FlexView>
            }
          />
        )}
      </ModalDialog>
    );
  }
}

export default ImportPhoto;
