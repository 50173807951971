import React, { memo, useCallback } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import cn from 'classnames';

import { FILE_TYPES } from 'utils/constants';
import { getFileMimeType } from 'utils/helpers';
import Button from '../../../Common/Button';
import FileUploadButton from '../../../Common/FileUploadButton';
import styles from './CardActionButtons.module.css';

const CardActionButtons = ({
  cardSide,
  isGridVisible,
  onUploadImageFile,
  onChangeCardSide,
  onRemoveCardSide,
  onToggleGridVisibility,
}) => {
  const handleFileInputChange = useCallback(
    ({ target }) => {
      const uploadedFile = target.files?.[0];
      const fileMimeType = getFileMimeType(uploadedFile);

      if (FILE_TYPES.IMAGE.includes(fileMimeType)) {
        onUploadImageFile(uploadedFile);
      } else {
        target.value = null;
        alert(`Uploaded file is not a valid image. Only ${FILE_TYPES.IMAGE} files are allowed.`);
      }
    },
    [onUploadImageFile]
  );

  return (
    <div className={styles.root}>
      <Tooltip title={`Only ${FILE_TYPES.IMAGE} files are allowed`} placement="top">
        <div>
          <FileUploadButton
            id="uploadImage"
            caption="Upload Image"
            icon="md-add"
            theme="blueText"
            accept={FILE_TYPES.IMAGE}
            className={styles.uploadBtn}
            onChange={handleFileInputChange}
          />
        </div>
      </Tooltip>

      <Button
        caption="Flip over"
        className={cn('button white', styles.flipOverBtn)}
        onClick={onChangeCardSide}
      />

      <Tooltip title={isGridVisible ? 'Hide card grid' : 'Show card grid'} placement="top">
        <Button
          icon="md-grid"
          className={cn(`button ${isGridVisible ? 'blue' : 'white'}`, styles.gridBtn)}
          onClick={onToggleGridVisibility}
        />
      </Tooltip>

      <Tooltip title={`Clear the ${cardSide} of the card`} placement="top">
        <Button
          icon="ios-trash-outline"
          className={cn('button warn', styles.removeBtn)}
          onClick={onRemoveCardSide}
        />
      </Tooltip>
    </div>
  );
};

export default memo(CardActionButtons);
