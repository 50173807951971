import React, { useState, useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';

import firebaseService from 'services/firebase';
import { useInfiniteFirebaseQuery } from 'global/hooks';
import { ContactType } from '../../Models/Contacts';
import { CardType } from '../../Models/Cards';
import OfficeType from 'Models/OfficeType';
import {
  ORGANIZATION_ATTRIBUTES,
  ORGANIZATION_HUBSPOT_ATTRIBUTES,
} from '../../Models/Organizations.config';
import validationSchema from './EditOrgSettings.validation';

import Loader from '../../global/components/Loader';
import ValidableTextField from '../YourAccount/ValidableTextField';
import Button from '../Common/Button';
import themes from '../Common/themes';
import OrgHubspotEditSettings from './OrgHubspotEditSettings';

import diskette from 'assets/icons/diskette.svg';

import './OrgSettings.css';

function getAssignedItems(Model, collectionKey, organizationId) {
  const { data, error } = useInfiniteFirebaseQuery(collectionKey, {
    adapter: card => new Model().fromFirestoreDoc(card),
    queryAdapter: query => query.where('organizationId', '==', organizationId || null),
  });
  return [data, error];
}

const EditOrgSettings = inject(
  'organizationStore',
  'yourContactStore'
)(({ organizationStore, history, yourContactStore }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [employees, employeesError] = getAssignedItems(
    ContactType,
    'contacts',
    organizationStore.organization.id
  );
  const filteredEmployees = employees.filter(el => el.id !== yourContactStore.contactInfo.id);
  const [offices, officesError] = getAssignedItems(
    OfficeType,
    'offices',
    organizationStore.organization.id
  );
  const [cards, cardsError] = getAssignedItems(
    CardType,
    'cards',
    organizationStore.organization.id
  );
  const isEmployeesAssigned = Boolean(filteredEmployees.length);
  const isOfficesAssigned = Boolean(offices.length);
  const isCardsAssigned = Boolean(cards.length);

  const clickSave = async values => {
    const { organization } = organizationStore;

    if (organization.orgData.name !== values.name) {
      firebaseService.updateOrganizationNameInContacts({
        organizationId: organization.orgData.id,
        organizationName: values.name,
      });
    }

    Object.assign(organization.orgData, values);

    setIsLoading(true);
    await organization.save();
    setIsLoading(false);
    history.push('/main/OrganizationSettings');
  };

  const deleteOrganization = async () => {
    if (isEmployeesAssigned || isOfficesAssigned || isCardsAssigned) {
      window.alert(
        'The organization cannot be deleted. Please delete all items under the organization first.'
      );
      return;
    }

    if (!window.confirm('Are you sure you want to delete the organization?')) {
      return;
    }

    try {
      await organizationStore.deleteOrganization();
      await firebaseService.saveContact({
        ...yourContactStore.contactInfo.hcInfo,
        organizationId: null,
      });
      history.push('/main');
    } catch (e) {
      // alert('The organization cannot be deleted. Please delete all items under the organization first.');
    }
  };

  useEffect(() => {
    if (employeesError || officesError || cardsError) {
      history.push('/main');
    }
  }, []);

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnChange={false}
      initialValues={organizationStore.organization.orgData}
      onSubmit={clickSave}
    >
      {({ values, errors, handleSubmit }) => (
        <Form className="panel details details-edit">
          <div className="header">
            <p>Organization Settings</p>
            <div className="header-buttons">
              {isLoading ? (
                <Loader className="smallGrey" />
              ) : (
                <>
                  <Button
                    type="submit"
                    caption="Save"
                    customIcon={
                      <img
                        src={diskette}
                        style={{ marginRight: '10px' }}
                        width="12px"
                        height="12px"
                        alt="diskette"
                      />
                    }
                    style={{ backgroundColor: '#2DD955', color: '#fff' }}
                  />
                  <Button
                    type="button"
                    caption="Delete"
                    style={{ marginLeft: '8px' }}
                    onClick={deleteOrganization}
                    icon="ios-trash"
                  />
                </>
              )}
            </div>
          </div>
          <div className="attributes">
            <MuiThemeProvider theme={themes.black}>
              {ORGANIZATION_ATTRIBUTES.map((attribute, index) => (
                <div key={index} className={`attribute attribute-${attribute.id}`}>
                  <ValidableTextField
                    label={attribute.name}
                    errorMessage={`Please enter valid ${attribute.name.toLowerCase()}`}
                    name={attribute.id}
                    required={attribute.isRequired}
                  />
                </div>
              ))}
            </MuiThemeProvider>
          </div>
          <div className="hubspot-settings">
            <p className="hubspot-settings-label">HubSpot Configuration</p>
            <div className="attributes">
              {ORGANIZATION_HUBSPOT_ATTRIBUTES.map(attribute => (
                <div key={attribute.id} className={`attribute attribute-${attribute.id}`}>
                  <OrgHubspotEditSettings name={attribute.id} label={attribute.name} />
                </div>
              ))}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
});

export default withRouter(EditOrgSettings);
