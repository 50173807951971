import React, { Component } from 'react';
import { inject } from 'mobx-react';

import Ionicon from 'react-ionicons';
import FlexView from 'react-flexview/lib';
import { withRouter } from 'react-router-dom';

import SelectionBox from './SelectionBox';
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';
import LinkedInSelection from './LinkedInSelection';
import firebaseService from 'services/firebase';
import Loader from 'global/components/Loader';
import AppleIdSignInButton from './AppleIdSignInButton';

@inject('accountStore')
class EntryBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEmailSent: false,
      isPasswordMode: false,
      email: '',
      password: '',
      errorMessage: '',
      isLoading: false,
    };

    this.onLinkedInLogin = this.onLinkedInLogin.bind(this);
    this.onAppleLogIn = this.onAppleLogIn.bind(this);
  }

  onLinkedInLogin(user) {
    if (this.props.mode === 'login') {
      this.props.history.push('/main/YourContacts');
    } else {
      this.props.accountStore.createLinkedInAccount(user);
      this.props.history.push('/main/YourAccount/edit');
    }
  }

  onAppleLogIn(user) {
    if (this.props.mode === 'login') {
      this.props.history.push('/main/YourContacts');
    } else {
      this.props.accountStore.createAccountFromAppleId(user);
      this.props.history.push('/main/YourAccount/edit');
    }
  }

  async signIn(email, password) {
    try {
      this.setState({ isLoading: true });
      await this.props.accountStore.signIn(email, password);
      this.setState({ isLoading: false });
      this.props.history.push('/main/YourContacts');
    } catch (e) {
      console.log(e.message);
      this.setState({ errorMessage: e.message });
    }
  }

  async isEmailInUse(value) {
    try {
      return await this.props.accountStore.isEmailInUse(value);
    } catch (e) {
      console.log(e.message);
      this.setState({ errorMessage: e.message });
    }
  }

  async createAccount(email, password) {
    try {
      this.setState({ isLoading: true });
      await this.props.accountStore.createAccount(email, password);
      firebaseService.sendWelcomeEmail(email);
      this.setState({ isLoading: false });
      this.props.history.push('/main/YourAccount/edit');
    } catch (e) {
      console.log(e.message);
      this.setState({ errorMessage: e.message });
    }
  }

  async resetPassword(email) {
    try {
      await firebaseService.resetPassword(email);
      this.setState({ isEmailSent: true });
    } catch (e) {
      console.log(e);
      this.setState({ errorMessage: e.message });
    }
  }

  getButtomText() {
    const { isPasswordMode } = this.state;

    if (isPasswordMode) {
      return 'Must be 8 characters long, with at least one letter, one number and one special character';
    }
    if (this.props.mode === 'login') {
      return 'This is most likely your work email address.';
    }
    if (this.props.mode === 'create-account') {
      return 'We recommend your work email address.';
    }
    return '';
  }

  render() {
    const { isPasswordMode, errorMessage, email, isEmailSent } = this.state;
    const isResetPasswordMode = this.props.mode === 'reset-password';

    if (this.state.isLoading) {
      return (
        <div className="loader-wrapper">
          <Loader />
        </div>
      );
    }

    return (
      <FlexView row="true" height="480px">
        <FlexView hAlignContent="center" vAlignContent="center" width="50px">
          {(isPasswordMode || isResetPasswordMode) && (
            <FlexView
              onClick={() => {
                if (isResetPasswordMode) {
                  return this.props.onSelectionChanged('login');
                }

                this.setState({ isPasswordMode: false, errorMessage: '' });
              }}
              hAlignContent="center"
              vAlignContent="center"
              width="30px"
              height="30px"
              style={{ backgroundColor: 'white', borderRadius: 15 }}
            >
              <Ionicon icon="md-arrow-round-back" fontSize="15px" color="black" />
            </FlexView>
          )}
        </FlexView>

        <FlexView column hAlignContent="center">
          <SelectionBox
            mode={
              this.props.mode === 'login' || this.props.mode === 'reset-password'
                ? 'login'
                : 'create'
            }
            onChangedMode={mode => {
              this.props.onSelectionChanged(mode);
              this.setState({ errorMessage: '' });
            }}
          />

          {this.props.mode === 'reset-password' ? (
            <ResetPasswordForm
              isEmailSent={isEmailSent}
              email={email}
              errorMessage={errorMessage}
              isEmailInUse={value => {
                return this.isEmailInUse(value);
              }}
              onError={err => this.setState({ errorMessage: err })}
              onResetEmail={value => this.resetPassword(value)}
              onComplete={() => {
                this.props.onSelectionChanged('login');
                this.setState({
                  isEmailSent: false,
                  isPasswordMode: false,
                  errorMessage: '',
                });
              }}
            />
          ) : (
            <LoginForm
              mode={this.props.mode}
              title={
                this.props.mode === 'login' ? 'Login to Cardivore' : 'Create Cardivore Account'
              }
              email={this.state.email}
              password={this.state.password}
              bottomText={this.getButtomText()}
              errorMessage={errorMessage}
              isPasswordMode={isPasswordMode}
              setPasswordMode={() => {
                this.setState({ isPasswordMode: true });
              }}
              setEmail={value => {
                this.setState({ email: value });
              }}
              setPassword={value => {
                this.setState({ password: value });
              }}
              isEmailInUse={value => {
                return this.isEmailInUse(value);
              }}
              onComplete={(email, password) => {
                if (this.props.mode === 'login') {
                  this.signIn(email, password);
                } else {
                  this.createAccount(email, password);
                }
              }}
              onError={err => {
                this.setState({ errorMessage: err, isLoading: false });
              }}
            />
          )}

          <FlexView
            row="true"
            vAlignContent="center"
            height="70px"
            width="480px"
            style={{
              justifyContent: 'space-between',
              backgroundColor: 'white',
              fontSize: 12,
              paddingLeft: 16,
              paddingRight: 10,
            }}
          >
            <FlexView style={{ color: 'rgba(0,0,0,0.3)' }}>OR</FlexView>

            <FlexView>
              <AppleIdSignInButton
                isLoginSelected={this.props.loginSelected}
                buttonText={
                  this.props.mode === 'login' || isResetPasswordMode
                    ? 'Login with Apple'
                    : 'Create Account with Apple'
                }
                onLogin={this.onAppleLogIn}
              />

              <LinkedInSelection
                isLoginSelected={this.props.loginSelected}
                buttonText={
                  this.props.mode === 'login' || isResetPasswordMode
                    ? 'Login with LinkedIn'
                    : 'Create Account with LinkedIn'
                }
                onLogin={this.onLinkedInLogin}
              />
            </FlexView>
          </FlexView>
        </FlexView>
      </FlexView>
    );
  }
}

export default withRouter(EntryBox);
