import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import firebase from 'services/firebase';

export default function AuthRoute({ component: Component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (!firebase.isAuthenticated()) {
          return <Redirect to="/" />;
        }

        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
}
