import React, { Component } from 'react';

import HCTextSelector from '../Common/HCTextSelector';
import Ionicon from 'react-ionicons';

import ValidableTextField from './ValidableTextField';

export default class EditContactAttribute extends Component {
  render() {
    const { label, valueTypes, item, onDelete, name } = this.props;

    return (
      <div className="edit-contact-attribute">
        <div className="edit-contact-attribute-fields">
          <HCTextSelector
            style={{ flex: 1, marginRight: 8 }}
            label="Type"
            options={valueTypes}
            value={item.type}
            onChange={text => (item.type = valueTypes[text])}
            margin="none"
            disabled={item.disabled}
          />

          <ValidableTextField
            name={`${name}.value`}
            autoFocus={this.props.autoFocus}
            style={{ flex: 1 }}
            label={label}
            errorMessage={`Please enter valid ${label.toLowerCase()}`}
            margin="none"
            disabled={item.disabled}
          />
        </div>

        {item.disabled ? null : (
          <Ionicon
            onClick={() => onDelete(item)}
            style={{ paddingRight: 2, cursor: 'pointer', width: 50 }}
            icon="ios-trash"
            fontSize="30px"
            color="#FF8686"
          />
        )}
      </div>
    );
  }
}
