import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import FlexView from 'react-flexview/lib';

import firebase from 'services/firebase';

import EntryBox from './EntryBox';

export default function LoginScreen({ history }) {
  const onSelectionChanged = mode => {
    history.push(mode);
  };

  return firebase.isAuthenticated() ? (
    <Redirect to="/main" />
  ) : (
    <FlexView column width="100%" height="100%" hAlignContent="center" vAlignContent="center">
      <Switch>
        <Redirect exact from="/entry" to="/entry/create-account" />
        <Route
          path="/entry/:mode"
          render={({ match }) => (
            <EntryBox mode={match.params.mode} onSelectionChanged={onSelectionChanged} />
          )}
        />
      </Switch>
    </FlexView>
  );
}
