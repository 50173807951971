import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import LoginScreen from './Screens/AccountManagement/LoginScreen';
import CreateAccountLinkedInScreen from './Screens/AccountManagement/CreateAccountLinkedIn';
// import StoreFrontScreen from './Screens/Storefront'
// import CreateAccountScreen from './Screens/AccountManagement/CreateAccount'
// import CreateCompanyAccountScreen from './Screens/AccountManagement/CreateCompanyAccount'
import MainScreen from './Screens/Main';
import ResetPassword from './ResetPassword/ResetPassword';
import ResetPasswordSuccess from './ResetPassword/ResetPasswordSuccess';
import './App.css';
import { Provider, inject } from 'mobx-react';

import Loader from 'global/components/Loader';
import AuthRoute from 'global/components/AuthRoute';

import firebase from 'services/firebase';

import ContactStore from './Models/Contact.store';
import OrganizationStore from './Models/Organization.store';
import AccountStore from './Models/Account';

import './Screens/Common/common.css';
import { Auth0Provider } from '@auth0/auth0-react';

class Logout extends Component {
  state = {
    isLoading: true,
  };

  async componentDidMount() {
    try {
      await firebase.signOut();
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }
    return <Redirect to="/" />;
  }
}

@inject('accountStore')
class Root extends Component {
  render() {
    return (
      <Switch>
        {firebase.isAuthenticated() ? (
          <Redirect exact from="/" to="/main" />
        ) : (
          <Redirect exact from="/" to="/entry" />
        )}
        <Route path="/logout" component={Logout} />
        <Route path="/entry" component={LoginScreen} />
        {/* <Route patsh="/CreateAccount" component={CreateAccountScreen} /> */}
        {/* <Route path="/CreateCompanyAccount" component={CreateCompanyAccountScreen} /> */}
        <AuthRoute
          path="/main"
          render={props => (
            <Provider
              yourContactStore={ContactStore.getYourContactStore()}
              organizationStore={OrganizationStore.getInstance()}
            >
              <MainScreen {...props} />
            </Provider>
          )}
        />
        <Route path="/ResetPassword" component={ResetPassword} />
        <Route path="/ResetPasswordSuccess" component={ResetPasswordSuccess} />
        <Route path="/CreateAccountLinkedIn" component={CreateAccountLinkedInScreen} />
        {/* <Route component={StoreFrontScreen} /> */}
      </Switch>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);

    /**
     * Firebase provide auth state after page loading
     * so show preloader while wating for auth state
     */
    this.state = {
      isWatingAuthState: true,
    };
  }

  componentDidMount() {
    firebase.addAuthStateListener(() => {
      if (this.state.isWatingAuthState) {
        this.setState({ isWatingAuthState: false });
      }
    });
  }

  render() {
    return (
      <div style={{ height: '100vh', backgroundColor: '#2A79FF' }}>
        {this.state.isWatingAuthState ? (
          <div className="loader-wrapper">
            <Loader />
          </div>
        ) : (
          <Auth0Provider
            domain="cardivore.auth0.com"
            clientId="hf0DK1K7C1PlmjFQQea9bma2Wf16fELO"
            sec
            redirectUri={window.location.origin}
          >
            <Provider accountStore={new AccountStore()}>
              <BrowserRouter>
                <Route component={Root} />
              </BrowserRouter>
            </Provider>
          </Auth0Provider>
        )}
      </div>
    );
  }
}

export default App;
