export const PHONE_NUMBER_PATTERN = /^[\d+\-() ]+$/;

export function validateEmail(text) {
  const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return reg.test(text);
}

export function validatePassword(text) {
  const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  return reg.test(text);
}
