import React from 'react';

import HCProfile from '../Common/HCProfile';
import Card from 'global/components/Card';
import { getContactCardImages } from 'utils/helpers';
import { CARD_SIDE } from 'utils/constants';

const ViewContactImages = ({ cardVersion, contact, cardObjects, disabled, cardContainer }) => {
  const { frontUrl, backUrl } = cardVersion || {}; // organization or office card attached to user by admin
  const { cardFrontUrl = '', cardBackUrl = '' } = contact.hcInfo; // cards uploaded by user
  const { frontOfTheCard, backOfTheCard, isCardUploadedByUser } = getContactCardImages({
    cardFrontUrl,
    cardBackUrl,
    frontUrl,
    backUrl,
    hasAssignedCard: !!cardVersion,
  });
  const card = cardObjects.length > 0 ? { ...cardVersion, cardObjects } : cardVersion;

  return (
    <div className="pictures">
      <HCProfile
        url={contact.hcInfo.imageUrl}
        initials={contact.initials}
        disabled={disabled}
        placeholder="Profile Image"
      />

      <Card
        card={card}
        side={CARD_SIDE.FRONT}
        url={frontOfTheCard}
        containerSize={cardContainer}
        isCardUploadedByUser={isCardUploadedByUser}
        placeholder="No Card Front"
        className="mr-15"
      />

      <Card
        card={card}
        side={CARD_SIDE.BACK}
        url={backOfTheCard}
        containerSize={cardContainer}
        isCardUploadedByUser={isCardUploadedByUser}
        placeholder="No Card Back"
      />
    </div>
  );
};

export default ViewContactImages;
