import { PublicClientApplication } from '@azure/msal-browser';

import { callMSGraph } from 'global/components/ADImport/helpers';
import { MS_GRAPH_AUTH_CONFIG } from 'adConfig.js';

import {
  MS_GRAPH_SCOPES,
  QUERY_SELECTORS,
  LOGIN_REQUEST_SCOPES,
  ENDPOINTS,
} from 'global/components/ADImport/configs';

const msalApplication = new PublicClientApplication(MS_GRAPH_AUTH_CONFIG);

msalApplication
  .handleRedirectPromise()
  .then(resp => {
    if (resp) {
      sessionStorage.setItem('isAuthorized', 'true');
    }
  })
  .catch(error => {
    throw error;
  });

const getTokenRedirect = async (endpoint, options = { method: 'GET' }) =>
  msalApplication
    .acquireTokenSilent({ ...MS_GRAPH_SCOPES, account: msalApplication.getAllAccounts()[0] })
    .catch(() => {
      return msalApplication.acquireTokenRedirect(MS_GRAPH_SCOPES);
    })
    .then(async response => {
      if (response) {
        const { accessToken } = response;
        if (accessToken) {
          try {
            const result = await callMSGraph(endpoint, accessToken, options);
            return result;
          } catch (error) {
            throw error;
          }
        }
      }
    });

const msGraphService = {
  isAuthorized() {
    return sessionStorage.getItem('isAuthorized') === 'true';
  },

  async signIn() {
    return msalApplication.loginRedirect(LOGIN_REQUEST_SCOPES);
  },

  signOut() {
    if (this.isAuthorized()) {
      sessionStorage.setItem('isAuthorized', 'false');
      msalApplication.logout({ account: msalApplication.getAllAccounts()[0] });
    }
  },

  getUsers() {
    const options = {
      method: 'GET',
      params: {
        $select: QUERY_SELECTORS.join(),
      },
    };
    return getTokenRedirect(ENDPOINTS.users, options);
  },

  getUsersDelta() {
    const options = {
      method: 'GET',
      params: {
        $select: QUERY_SELECTORS.join(),
      },
    };
    return getTokenRedirect(ENDPOINTS.usersDelta, options);
  },

  callUrl(url) {
    return getTokenRedirect(url);
  },
};

export default msGraphService;
