import React, { useState, useRef, memo, useCallback, useEffect } from 'react';

import { getCardFieldPercentPosition } from '../helpers';
import { getPercentFromNumber, getNumberFromPercent } from 'utils/helpers';
import { DEFAULT_ASPECT_RATIO, CARD_SIDE } from 'utils/constants';
import DraggableItem from './DraggableItem';
import ResizableCardField from './ResizableCardField';
import FieldConfigBar from './FieldConfigBar';
import FieldListBar from './FieldListBar';
import { INITIAL_FIELD_STYLES, INITIAL_CARD_FIELD_SIZE } from '../constants';
import CardActionButtons from './CardActionButtons';
import styles from './AddDataFields.module.css';

const AddDataFields = ({
  isGridVisible,
  cardFields,
  cardSide,
  croppedImages,
  cardSize,
  fontSizeCoefficient,
  cardBorderRadiusProportion,
  onDispatchCardFields,
  onUploadImageFile,
  onChangeCardSide,
  onRemoveImageAndFields,
  onToggleGridVisibility,
  onAspectRatioChange,
  onCardSizeChange,
}) => {
  const [preField, setPreField] = useState(null);
  const [editableField, setEditableField] = useState(null);
  const cardRef = useRef(null);
  const cardCoordinates = {
    left: cardRef.current?.getBoundingClientRect()?.left,
    top: cardRef.current?.getBoundingClientRect()?.top,
  };
  const { url: cardImageUrl = '' } = croppedImages[cardSide] || {};

  useEffect(() => {
    window.addEventListener('resize', onCardSizeChange);

    return () => {
      window.removeEventListener('resize', onCardSizeChange);
    };
  }, [onCardSizeChange]);

  useEffect(() => {
    setEditableField(null);
  }, [cardSide]);

  const handleClearEditableField = useCallback(() => {
    setEditableField(null);
  }, []);

  const handleDraggableItemMouseDown = useCallback(
    (e, option = {}) => {
      const removeFieldId = +e.target.getAttribute('data-remove-field-id');

      // user clicked on remove button
      if (removeFieldId) {
        if (editableField?.id === removeFieldId) {
          handleClearEditableField();
        }

        onDispatchCardFields({
          type: 'removeByFieldAndValue',
          payload: { field: 'id', value: removeFieldId },
        });
      }

      // user clicked on field
      if (!removeFieldId) {
        if (option.id) {
          onDispatchCardFields({
            type: 'removeByFieldAndValue',
            payload: { field: 'id', value: option.id },
          });
        }

        const percentWidth =
          option?.width || getPercentFromNumber(INITIAL_CARD_FIELD_SIZE.WIDTH, cardSize.width);
        setPreField({
          id: Date.now(),
          ...INITIAL_FIELD_STYLES,
          ...getCardFieldPercentPosition({
            pageX: e.pageX,
            pageY: e.pageY,
            cardCoordinates,
            cardSize,
          }),
          ...option,
          side: cardSide,
          width: percentWidth,
        });
      }
    },
    [cardCoordinates, cardSize, editableField, cardSide]
  );

  const handleMouseMove = ({ pageX, pageY }) => {
    if (preField) {
      setPreField({
        ...preField,
        ...getCardFieldPercentPosition({ pageX, pageY, cardCoordinates, cardSize }),
      });
    }
  };

  const handleMouseUp = () => {
    if (preField) {
      onDispatchCardFields({ type: 'add', payload: preField });
      setEditableField(preField);
      setPreField(null);
    }
  };

  const handleAddNewField = useCallback(
    (e, option = {}) => {
      const newField = {
        id: Date.now(),
        ...INITIAL_FIELD_STYLES,
        ...option,
        x: 6, // 6 - x percent position on card
        y: 6, // 6 - y percent position on card
        side: cardSide,
        width: getPercentFromNumber(INITIAL_CARD_FIELD_SIZE.WIDTH, cardSize.width),
      };

      onDispatchCardFields({ type: 'add', payload: newField });
      setEditableField(newField);
    },
    [cardSize, cardSide]
  );

  const handleRemoveCardSide = useCallback(() => {
    const removeCardSideData = () => {
      onRemoveImageAndFields();
      handleClearEditableField();

      if (cardSide === CARD_SIDE.FRONT) {
        onAspectRatioChange(DEFAULT_ASPECT_RATIO);
      }
    };

    if (
      (croppedImages[CARD_SIDE.BACK] || cardFields.some(({ side }) => side === CARD_SIDE.BACK)) &&
      cardSide === CARD_SIDE.FRONT
    ) {
      const isConfirm = window.confirm(
        'If you delete the front of card, the default settings will be applied to the back of card'
      );
      if (isConfirm) {
        removeCardSideData();
      }
    } else {
      removeCardSideData();
    }
  }, [onRemoveImageAndFields, cardSide, onAspectRatioChange]);

  return (
    <div className={styles.root} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
      <FieldListBar onAddNewField={handleAddNewField} />

      <div className={styles.cardWrapper}>
        <CardActionButtons
          cardSide={cardSide}
          isGridVisible={isGridVisible}
          onUploadImageFile={onUploadImageFile}
          onChangeCardSide={onChangeCardSide}
          onToggleGridVisibility={onToggleGridVisibility}
          onRemoveCardSide={handleRemoveCardSide}
        />

        <div
          ref={cardRef}
          className={styles.card}
          style={{
            borderRadius: `${cardBorderRadiusProportion.x}%/${cardBorderRadiusProportion.y}%`,
            backgroundImage: cardImageUrl ? `url(${cardImageUrl})` : 'none',
            ...cardSize,
          }}
        >
          {isGridVisible && (
            <div
              style={{
                borderRadius: `${cardBorderRadiusProportion.x}%/${cardBorderRadiusProportion.y}%`,
              }}
              className={styles.activeGrid}
            />
          )}

          {preField && (
            <DraggableItem
              style={{
                left: getNumberFromPercent(preField.x, cardSize.width),
                top: getNumberFromPercent(preField.y, cardSize.height),
                width: getNumberFromPercent(preField.width, cardSize.width),
              }}
              className={styles.activeDraggableField}
              field={preField}
            />
          )}

          {cardFields?.length > 0 &&
            cardSize &&
            cardFields.map(field => {
              if (field.side !== cardSide) {
                return null;
              }

              return (
                <ResizableCardField
                  key={field.id}
                  isActive={field.id === editableField?.id}
                  field={field}
                  cardSize={cardSize}
                  fontSizeCoefficient={fontSizeCoefficient}
                  cardFields={cardFields}
                  onDispatchCardFields={onDispatchCardFields}
                  onDraggableItemMouseDown={handleDraggableItemMouseDown}
                />
              );
            })}
        </div>
      </div>

      {editableField && (
        <FieldConfigBar
          cardHeight={cardSize.height}
          editableField={editableField}
          cardFields={cardFields}
          onDispatchCardFields={onDispatchCardFields}
          onClearEditableField={handleClearEditableField}
        />
      )}
    </div>
  );
};

export default memo(AddDataFields);
