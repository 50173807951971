import React, { useRef } from 'react';
import Ionicon from 'react-ionicons';
import { useInfiniteScroll } from '../../hooks';
import Loader from 'global/components/Loader';
import styles from './List.module.css';

const DefaultComponent = ({ data }) => <span>{data}</span>;

const ItemActions = ({ item, actions }) => (
  <div className={styles.actions}>
    {actions
      .filter(x => !x.hide)
      .map((action, i) => (
        <div
          className={`${styles.action} ${action.primary ? styles.primary : ''}`}
          key={i}
          onClick={e => {
            e.stopPropagation();
            action.onClick(item);
          }}
        >
          <Ionicon
            icon={action.icon}
            fontSize="14px"
            color={action.primary ? '#EB4444' : 'black'}
          />
          <span>{action.caption}</span>
        </div>
      ))}
  </div>
);

const List = ({
  data = [],
  actions = [],
  onClick,
  isFetching,
  isFetchingMore,
  canFetchMore,
  onFetchMore,
  emptyMessage = '',
  activeId,
  isEmpty = !data?.length,
  renderItem = DefaultComponent,
}) => {
  const listRef = useRef(null);

  useInfiniteScroll({
    ref: listRef,
    fetchMore: onFetchMore,
    canFetchMore,
    isFetching: isFetching || isFetchingMore,
  });

  return (
    <div ref={listRef}>
      {isFetching ? (
        <div className={styles.listLoader}>
          <Loader className="list" />
        </div>
      ) : null}
      {data.map(item => (
        <div
          className={`${styles.item} ${item.id === activeId && styles.active}`}
          key={item.id}
          onClick={() => onClick(item)}
        >
          {renderItem(item)}
          <ItemActions item={item} actions={actions} />
        </div>
      ))}

      <div className={styles.listFooter}>
        {isFetchingMore ? (
          <Loader className="smallGrey" />
        ) : (
          <>
            {canFetchMore ? (
              <span className={styles.fetchMore} onClick={onFetchMore}>
                Fetch more...
              </span>
            ) : isEmpty ? (
              emptyMessage
            ) : (
              <span>No more data</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default List;
