import React, { memo } from 'react';
import cn from 'classnames';

import { DEFAULT_ALIGNMENT, DEFAULT_COLOR } from '../../constants';
import { isColor, getFieldFontStyles } from 'utils/helpers';
import { ALIGNMENT } from 'utils/constants';
import { noop } from 'global/utils';
import styles from './DraggableItem.module.css';

const DraggableItem = ({
  className,
  field = {},
  showOriginType = false,
  onDraggableItemMouseDown = noop,
  onAddNewField = noop,
  ...rest
}) => {
  const { title, originType, color, alignment = DEFAULT_ALIGNMENT } = field;
  const fontStyles = getFieldFontStyles(field);

  const handleMouseDown = e => {
    onDraggableItemMouseDown(e, field);
  };

  const handleOnClick = e => {
    onAddNewField(e, field);
  };

  return (
    <div
      {...rest}
      className={cn(styles.root, className)}
      onMouseDown={handleMouseDown}
      onClick={handleOnClick}
    >
      <div
        className={styles.textBlock}
        style={{ justifyContent: showOriginType ? 'space-between' : ALIGNMENT[alignment] }}
      >
        <span
          className={styles.title}
          style={{ font: fontStyles, color: isColor(color) ? color : DEFAULT_COLOR }}
        >
          {title}
        </span>
        {showOriginType && (
          <span
            className={styles.originType}
            style={{ font: fontStyles, color: isColor(color) ? color : DEFAULT_COLOR }}
          >
            {originType}
          </span>
        )}
      </div>

      <div className={styles.closeIconWrapper} data-remove-field-id={field.id}>
        x
      </div>
    </div>
  );
};

export default memo(DraggableItem);
