import React, { Component } from 'react';
import FlexView from 'react-flexview/lib';
import firebase from 'firebase/app';

import './ResetPassword.css';

const defaultDescriptionText =
  'Should be at least 8 letters long, should contain at least one letter, one number, and one special character.';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeValid: true,
      value: '',
      passwordValid: true,
      passwordResetSuccess: false,
      errorMessage: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    const splitparams = decodeURIComponent(props.location.search)
      .split('&')
      .map(x => x.split('='));
    const codearr = splitparams.filter(x => x[0] === 'oobCode');
    this.code = codearr[0][1];
    this.verifyCode();
  }

  verifyCode() {
    firebase
      .auth()
      .verifyPasswordResetCode(this.code)
      .then(
        function (email) {
          this.setState({ codeValid: true, email: email });
        }.bind(this)
      )
      .catch(function (error) {
        console.log(error);
      });
  }

  validatePassword(text) {
    var reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return reg.test(text);
  }

  handleChange(event) {
    const input = event.target.value;
    const passwordValid = this.validatePassword(input);
    this.setState({ value: input, passwordValid: passwordValid });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.state.passwordValid) {
      try {
        await firebase.auth().confirmPasswordReset(this.code, this.state.value);
        this.props.history.push('/ResetPasswordSuccess');
      } catch (e) {
        this.setState({ errorMessage: e.message, codeValid: false });
      }
    }
    return false;
  }

  render() {
    return (
      <div>
        {this.state.passwordResetSuccess ? (
          <FlexView
            style={{ padding: 30 }}
            column
            marginLeft="auto"
            marginRight="auto"
            marginBottom="auto"
            className="codeInvalidContainer"
          >
            <FlexView
              style={{ fontWeight: 'bold', marginBottom: 20 }}
              hAlignContent="center"
              vAlignContent="center"
            >
              {' '}
              Password reset succesful
            </FlexView>
          </FlexView>
        ) : this.state.codeValid ? (
          <FlexView
            hAlignContent="center"
            vAlignContent="center"
            style={{ width: 320, height: 180, padding: 30 }}
            column
            marginLeft="auto"
            marginRight="auto"
            marginBottom="auto"
            className="codeInvalidContainer"
          >
            <FlexView style={{ fontWeight: 'bold', marginBottom: 20 }}>
              Reset your password
            </FlexView>
            <FlexView style={{ fontWeight: 'bold', marginBottom: 20 }}>
              for {this.state.email}
            </FlexView>
            <FlexView style={{ height: 60 }}>
              <form onSubmit={this.handleSubmit} placeholder="New Password">
                <input
                  style={{ marginRight: 10 }}
                  type="password"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
                <input type="submit" value="Reset Password" />
              </form>
            </FlexView>
            <FlexView
              style={{
                height: 60,
                visibility: this.state.passwordValid ? 'hidden' : '',
                color: 'red',
                fontSize: 8,
              }}
            >
              {defaultDescriptionText}
            </FlexView>
          </FlexView>
        ) : (
          <FlexView
            style={{ padding: 30 }}
            column
            marginLeft="auto"
            marginRight="auto"
            marginBottom="auto"
            className="codeInvalidContainer"
          >
            <FlexView
              style={{ fontWeight: 'bold', marginBottom: 20 }}
              hAlignContent="center"
              vAlignContent="center"
            >
              {' '}
              Try resetting your password again
            </FlexView>
            <FlexView style={{ fontSize: 14 }} grow hAlignContent="center" vAlignContent="center">
              {this.state.errorMessage.length > 0
                ? this.state.errorMessage
                : 'Your request to reset your password has expired or the link has already been used'}
            </FlexView>
          </FlexView>
        )}
      </div>
    );
  }
}

export default ResetPassword;
