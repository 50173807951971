import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import { CARD_FIELD_STYLE_NAME } from '../../constants';
import { ALIGNMENT, FONT_STYLE, DEFAULT_FONTS } from 'utils/constants';
import {
  normalizeCardFieldStylesOnChange,
  normalizeCardFieldStylesOnBlur,
  validateCardFieldStyles,
} from '../../helpers';
import themes from '../../../Common/themes';
import HCTextSelector from '../../../../Screens/Common/HCTextSelector';
import HCTextEditBlack from '../../../../Screens/Common/HCTextEditBlack';
import styles from './FieldConfigBar.module.css';

const FieldConfigBar = ({
  editableField,
  cardFields,
  cardHeight,
  organizationStore,
  onDispatchCardFields,
  onClearEditableField,
}) => {
  const field = cardFields.find(({ id }) => id === editableField.id) || editableField;
  const orgFonts = toJS(organizationStore.organization.fonts).reduce((acc, current) => {
    if (current.name) {
      acc[current.name] = current.name;
    }
    return acc;
  }, {});

  const handleOnChange = (_, { target: { value, name } }) => {
    const normalizedFieldValue = normalizeCardFieldStylesOnChange[name]({ value, orgFonts });

    onDispatchCardFields({
      type: 'merge',
      payload: { id: field.id, [name]: normalizedFieldValue },
    });
  };

  const handleOnBlur = ({ target: { value, name } }) => {
    const normalizedFieldValue = normalizeCardFieldStylesOnBlur[name]({ value });

    onDispatchCardFields({
      type: 'merge',
      payload: { id: field.id, [name]: normalizedFieldValue },
    });
  };

  return (
    <aside className={styles.root}>
      <button className={styles.closeBtn} onClick={onClearEditableField}>
        x
      </button>

      <h3 className={styles.title}>
        {field.title} - {field.originType}
      </h3>

      <form>
        <MuiThemeProvider theme={themes.black}>
          <section className={styles.topSection}>
            <HCTextEditBlack
              label="X Position"
              type="number"
              name={CARD_FIELD_STYLE_NAME.X}
              value={field[CARD_FIELD_STYLE_NAME.X]}
              className={styles.input}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              {...validateCardFieldStyles[CARD_FIELD_STYLE_NAME.X]({
                value: field[CARD_FIELD_STYLE_NAME.X],
                width: field.width,
              })}
            />
            <HCTextEditBlack
              label="Y Position"
              type="number"
              name={CARD_FIELD_STYLE_NAME.Y}
              value={field[CARD_FIELD_STYLE_NAME.Y]}
              className={styles.input}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              {...validateCardFieldStyles[CARD_FIELD_STYLE_NAME.Y]({
                value: field[CARD_FIELD_STYLE_NAME.Y],
                fontSize: field.fontSize,
                cardHeight,
              })}
            />
          </section>

          <section className={styles.bottomSection}>
            <HCTextSelector
              label="Font"
              name={CARD_FIELD_STYLE_NAME.FONT}
              className={styles.select}
              options={Object.keys({ ...DEFAULT_FONTS, ...orgFonts })}
              value={field[CARD_FIELD_STYLE_NAME.FONT]}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            <HCTextEditBlack
              label="Font color"
              name={CARD_FIELD_STYLE_NAME.COLOR}
              value={field[CARD_FIELD_STYLE_NAME.COLOR]}
              className={styles.input}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              {...validateCardFieldStyles[CARD_FIELD_STYLE_NAME.COLOR]({
                value: field[CARD_FIELD_STYLE_NAME.COLOR],
              })}
            />
            <HCTextEditBlack
              label="Font size"
              type="number"
              name={CARD_FIELD_STYLE_NAME.FONT_SIZE}
              value={field[CARD_FIELD_STYLE_NAME.FONT_SIZE]}
              className={styles.input}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              {...validateCardFieldStyles[CARD_FIELD_STYLE_NAME.FONT_SIZE]({
                value: field[CARD_FIELD_STYLE_NAME.FONT_SIZE],
              })}
            />
            <HCTextSelector
              label="Font style"
              name={CARD_FIELD_STYLE_NAME.FONT_STYLE}
              className={styles.select}
              options={Object.keys(FONT_STYLE)}
              value={field[CARD_FIELD_STYLE_NAME.FONT_STYLE]}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            <HCTextSelector
              label="Alignment"
              name={CARD_FIELD_STYLE_NAME.ALIGNMENT}
              className={styles.select}
              options={Object.keys(ALIGNMENT)}
              value={field[CARD_FIELD_STYLE_NAME.ALIGNMENT]}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
          </section>
        </MuiThemeProvider>
      </form>
    </aside>
  );
};

export default inject('organizationStore')(observer(FieldConfigBar));
