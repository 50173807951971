import React, { Component } from 'react';
import FlexView from 'react-flexview/lib';

import './ResetPassword.css';

class ResetPasswordSuccess extends Component {
  render() {
    return (
      <div>
        <FlexView
          style={{ padding: 30 }}
          column
          marginLeft="auto"
          marginRight="auto"
          marginBottom="auto"
          className="codeInvalidContainer"
        >
          <FlexView
            style={{ fontWeight: 'bold', marginBottom: 20 }}
            hAlignContent="center"
            vAlignContent="center"
          >
            {' '}
            Password reset succesful
          </FlexView>
        </FlexView>
      </div>
    );
  }
}

export default ResetPasswordSuccess;
