import React from 'react';
import Ionicon from 'react-ionicons';

export default function Button({ caption, icon, customIcon, onClick, ...rest }) {
  return (
    <button onClick={onClick} className="button white" {...rest}>
      {customIcon ||
        (icon && (
          <Ionicon style={{ paddingRight: caption ? 10 : 0 }} icon={icon} fontSize="14px" />
        ))}
      {caption && <span>{caption}</span>}
    </button>
  );
}
