import { useState, useCallback } from 'react';

export const useToasts = () => {
  const [toasts, setToasts] = useState([]);

  const setToast = useCallback(
    toast => {
      setToasts([...toasts, { text: toast }]);
    },
    [toasts]
  );
  const dismissToast = useCallback(() => {
    const [, ...remain] = toasts;
    setToasts(remain);
  }, [toasts]);

  return [toasts, setToast, dismissToast];
};
