export const ORGANIZATION_ATTRIBUTES = [
  {
    id: 'name',
    name: 'Organization Name',
    isRequired: true,
  },
  {
    id: 'emailAddress',
    name: 'Default Mailing Address',
    isRequired: false,
  },
  {
    id: 'phoneNumber',
    name: 'Default Phone Number',
    isRequired: false,
  },
  {
    id: 'website',
    name: 'Default Website',
    isRequired: false,
  },
  {
    id: 'faxNumber',
    name: 'Default Fax Number',
    isRequired: false,
  },
];

export const ORGANIZATION_HUBSPOT_ATTRIBUTES = [
  {
    id: 'lifecycleStage',
    name: 'Lifecycle Stage',
    isRequired: false,
  },
  {
    id: 'leadStatus',
    name: 'Lead Status',
    isRequired: false,
  },
];
