import React, { Component } from 'react';

import Ionicon from 'react-ionicons';
import FlexView from 'react-flexview/lib';
import firebaseService from '../../services/firebase';

class AppleIdSignInButton extends Component {
  constructor(props) {
    super(props);

    this.handleAuthorization = this.handleAuthorization.bind(this);
  }

  handleFailure = error => {
    console.log('error', error);
  };

  async handleAuthorization() {
    const result = await firebaseService.signInWithApple();
    this.props.onLogin(result);
  }

  render() {
    return (
      <button
        style={{
          backgroundColor: '#0077B5',
          borderRadius: 15,
          paddingLeft: 16,
          paddingRight: 16,
          margin: 0,
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          marginRight: 12,
        }}
        onClick={this.handleAuthorization}
      >
        <FlexView hAlignContent="center" vAlignContent="center" height="30px">
          <Ionicon icon="logo-apple" fontSize="15px" color="white" />
          <FlexView style={{ marginLeft: 10, color: 'white' }}>{this.props.buttonText}</FlexView>
        </FlexView>
      </button>
    );
  }
}

export default AppleIdSignInButton;
